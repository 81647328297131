import {TextOnBackground} from '@hconnect/uikit/src/lib2'
import {Box, alpha, Typography} from '@mui/material'
import React from 'react'

type Props = {
  value?: number
  unit?: string
  kpiName: string
}

export const KpiBox: React.FC<Props> = ({value, unit, kpiName}) => {
  if (!value) return null
  return (
    <Box
      p={1.5}
      width="fit-content"
      sx={(theme) => ({
        border: `1px solid ${alpha(theme.palette.common.white, 0.3)}`,
        borderRadius: 1
      })}
      data-test-id={`kpi-box-${kpiName}`}
    >
      <Box display="flex" alignItems="end">
        <Typography variant="h3" color="primary.contrastText">
          {value}
        </Typography>
        {unit && (
          <Typography variant="body1" color="primary.contrastText" ml={1}>
            {unit}
          </Typography>
        )}
      </Box>
      <TextOnBackground variant="body1" color="primary.contrastText" contrast="low">
        {kpiName}
      </TextOnBackground>
    </Box>
  )
}
