import {checkFeatureFlag} from '../../shared/helpers/featureFlagHelpers'

import {useTimeRange} from './useTimeRange'

export const useAreYTDFilterKPIsAcVsPlAllowed = () => {
  const areYTDFiltersAllowed = checkFeatureFlag('YTDFilter')
  const {quickSelectionId} = useTimeRange()

  return !(
    !areYTDFiltersAllowed &&
    quickSelectionId &&
    ['yearToDateYTD'].includes(quickSelectionId)
  )
}
