import React from 'react'

import {HROC_PREFIX, TASKS_PREFIX} from '../consts'

type TranslationPrefix = {
  performancePrefix: string
  hrocPrefix: string
  tasksPrefix: string
}

const TranslationPrefixContext = React.createContext<TranslationPrefix | undefined>(undefined)

export const TranslationPrefixProvider = ({children}: {children: React.ReactNode}) => {
  const translationPrefix = {
    performancePrefix: 'performanceDashboard',
    hrocPrefix: HROC_PREFIX,
    tasksPrefix: TASKS_PREFIX
  }
  return (
    <TranslationPrefixContext.Provider value={translationPrefix}>
      {children}
    </TranslationPrefixContext.Provider>
  )
}

// used by consumers
export function useTranslationPrefix() {
  const ctx = React.useContext(TranslationPrefixContext)
  if (ctx === undefined) {
    throw new Error(
      'useTranslationPrefix used outside of TranslationPrefixProvider or translationPrefix is not available'
    )
  } else {
    return ctx
  }
}
