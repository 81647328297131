import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'

import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'

export const GlobalPageRedirectWrapper: React.FC<{children: React.ReactNode}> = ({children}) => {
  const navigate = useNavigate()
  const {allowMultipleLocations, getRedirectStatusURL} = useNavAccessContext()

  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!allowMultipleLocations()) {
      const redirectURL = getRedirectStatusURL()
      redirectURL && navigate(redirectURL)
    }
    setIsLoading(false)
  }, [allowMultipleLocations, getRedirectStatusURL, navigate])

  if (isLoading) {
    return null
  }

  return <>{children}</>
}
