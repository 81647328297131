import {EventAttachmentUpload} from '@hconnect/common/components/attachments'
import {MAX_ALLOWED_ATTACHMENTS} from '@hconnect/common/consts'
import {AttachmentFile, StoppageDto as Stoppage} from '@hconnect/common/types'
import {
  attachmentToFile,
  getNewlyAttachedFiles,
  getUniqueAttachments,
  getUrlsForRemovedImages
} from '@hconnect/common/utils'
import {LoadingSwitcher} from '@hconnect/uikit/src/lib2'
import React, {useEffect, useState} from 'react'

import {checkFeatureFlag} from '../../shared/helpers/featureFlagHelpers'
import {useHasPermission} from '../hooks/usePermission'
import {useStoppageUpdate} from '../hooks/useStoppageUpdate'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {StoppageEdit as StoppageEditDto, StoppageEvent} from '../types'

import {StoppageFormContainer} from './StoppageFormContainer'

type StoppageEditProps = {
  plantId: string
  'data-test-id'?: string
  stoppage: StoppageEvent
  doClose(original?: Stoppage, newVersion?: Stoppage): void
  onSubmit?(item: Stoppage): void
  onSuccess?(data: Stoppage): void
}

export const StoppageEdit: React.FC<StoppageEditProps> = ({
  doClose,
  plantId,
  onSubmit,
  onSuccess,
  stoppage,
  ...props
}) => {
  const hasPermission = useHasPermission(plantId)
  const {performancePrefix} = useTranslationPrefix()
  const isMovieAttachmentEnabled = checkFeatureFlag('movieAttachment')
  const [uniqueAttachments, setUniqueAttachments] = useState(
    getUniqueAttachments(stoppage?.attachments, MAX_ALLOWED_ATTACHMENTS)
  )
  const [files, setFiles] = useState<Array<AttachmentFile>>(
    uniqueAttachments ? uniqueAttachments.map((attachment) => attachmentToFile(attachment)) : []
  )

  useEffect(() => {
    setUniqueAttachments(getUniqueAttachments(stoppage?.attachments, MAX_ALLOWED_ATTACHMENTS))
  }, [stoppage?.attachments])

  const updateAction = useStoppageUpdate(plantId, {
    onSuccess
  })
  const {data, isLoading} = updateAction

  useEffect(() => {
    if (data) {
      onSubmit?.(data)
    }
  }, [onSubmit, data])

  const doSubmit = React.useCallback(
    (item: StoppageEditDto) => {
      const originalAttachments = stoppage.attachments ?? []
      updateAction.mutate({
        ...item,
        attachmentsToRemove: getUrlsForRemovedImages(originalAttachments, files),
        newAttachments: getNewlyAttachedFiles(originalAttachments, files)
      })
    },
    [stoppage.attachments, updateAction, files]
  )

  return (
    <LoadingSwitcher isLoading={isLoading}>
      <StoppageFormContainer
        data-test-id={props['data-test-id']}
        isCreateMode={true}
        item={stoppage}
        doClose={() => doClose()}
        doSubmit={doSubmit}
        plantId={plantId}
        /** Note: Stoppage start/end edit is not allowed as it might not stay
         * mapped to the running time duration */
        durationEditOptions={{
          disableStart: true,
          disableEnd: true
        }}
      >
        <EventAttachmentUpload
          hasPermission={hasPermission}
          setAttachments={setFiles}
          attachments={files}
          isMovieAttachmentEnabled={isMovieAttachmentEnabled}
          translationPrefix={performancePrefix}
        />
      </StoppageFormContainer>
    </LoadingSwitcher>
  )
}
