import {ViewMode} from '@hconnect/common/components/eventsList/types'
import {useSearchParams, SetURLSearchParams} from 'react-router-dom'

import {HProduceTask} from '../types'

function urlParamsToViewMode(params: URLSearchParams): ViewMode<HProduceTask> {
  const eventId = params.has('eventId') ? params.get('eventId') : undefined
  const mode = params.has('mode') ? params.get('mode') : undefined

  switch (mode) {
    case undefined:
      if (eventId) return {mode: 'detailsMode', itemId: eventId} as ViewMode<HProduceTask>
      else return {mode: 'tableOnlyMode'} as ViewMode<HProduceTask>

    case 'editMode':
    case 'detailsMode':
      if (!eventId) throw new Error('Event Id must be given with mode')
      return {mode, itemId: eventId}
    case 'createMode':
      return {mode}
    default:
      throw new Error('Unknown mode given in URL')
  }
}

export function viewModeToUrlParams(viewMode: ViewMode<HProduceTask>) {
  const params = new URLSearchParams()

  switch (viewMode.mode) {
    case 'tableOnlyMode':
      return params
    case 'editMode':
    case 'detailsMode':
      params.append('mode', viewMode.mode)
      params.append('eventId', viewMode.itemId.toString())
      return params
    case 'createMode':
      params.append('mode', 'createMode')
      return params
    default:
      throw new Error('Unknown mode')
  }
}

export const useQueryParameterForViewMode = (): ViewMode<HProduceTask> => {
  const [params]: [URLSearchParams, SetURLSearchParams] = useSearchParams()
  return urlParamsToViewMode(params)
}

export function combineSearchParamsToQueryString(...searchParams: URLSearchParams[]) {
  const allParams = new URLSearchParams()
  searchParams.forEach((searchParam) => {
    for (const [key, val] of searchParam.entries()) {
      allParams.append(key, val)
    }
  })
  return allParams.toString()
}
