import {formatNumber} from '@hconnect/common/utils'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box, styled, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

type Props = {
  title: string
  volume?: number
  unit?: string
  icon?: JSX.Element
  'data-test-id'?: string
}

const StyledContainer = styled('div')({
  display: 'flex',
  alignItems: 'center'
})

export const ProductionBox: React.FC<Props> = ({
  title,
  volume,
  icon,
  unit = 't',
  'data-test-id': dataTestId
}) => {
  const {
    i18n: {language}
  } = useTranslation()

  return (
    <CardBox>
      <Box display="flex" flexDirection="column" data-test-id={dataTestId}>
        <StyledContainer>
          {icon} <Typography variant="h3">{title}</Typography>
        </StyledContainer>
        <Typography variant="h1" mt={1}>
          {volume ? `${formatNumber(volume, language)} ${unit}` : '-'}
        </Typography>
      </Box>
    </CardBox>
  )
}
