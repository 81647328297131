import {CollapsableRow, Column} from '@hconnect/uikit/src/lib2'
import {TableCell} from '@mui/material'
import React, {useCallback, useMemo} from 'react'
import {generatePath, useNavigate, useLocation, useParams} from 'react-router-dom'

import {PERFORMANCE_TAB} from '../consts'
import {AREA_KPI, LOCATION_KPI, PLANT_PERFORMANCE} from '../routing'
import {PerformanceDescendantWithId} from '../types'

const AcVsPlTableCell = ({children, 'data-test-id': dataTestId}) => {
  return (
    <TableCell
      component="div"
      sx={{
        borderBottom: 'none',
        pl: 0
      }}
      data-test-id={dataTestId}
    >
      {children}
    </TableCell>
  )
}

type PathParameter = {
  locationId?: string
}

const getPath = (path: string, params: Record<string, string | undefined>, search) =>
  generatePath(path, params) + search

type AcVsPlTableRowProps<T> = {
  item: PerformanceDescendantWithId<T>
  columns: Column<T>[]
  'data-test-id': string
}

export function AcVsPlTableRow<T>({
  item,
  columns,
  'data-test-id': dataTestId
}: AcVsPlTableRowProps<T>) {
  const navigate = useNavigate()
  const {search} = useLocation()
  const {locationId} = useParams<PathParameter>()

  const getItemProps = useCallback(
    (item: PerformanceDescendantWithId<T>) => {
      return item.locationData?.type === 'region'
        ? {}
        : {
            onClick: (item: PerformanceDescendantWithId<T>) => {
              if (item.locationData?.type === 'area') {
                navigate(getPath(AREA_KPI, {locationId: item.locationData.id}, search))
              }
              if (item.locationData?.type === 'country') {
                navigate(getPath(LOCATION_KPI, {locationId: item.locationData.id}, search))
              }
              if ((!item.locationData?.type && locationId) || item.locationData?.type === 'plant') {
                const urlParams = new URLSearchParams(search)
                urlParams.delete(PERFORMANCE_TAB)
                const updatedSearch = `?${urlParams.toString()}`
                navigate(
                  getPath(PLANT_PERFORMANCE, {plantId: item.locationData?.id}, updatedSearch)
                )
              }
            }
          }
    },
    [locationId, navigate, search]
  )

  const filteredItem = useMemo(() => {
    const filteredItem = {...item}

    if (item.locationData?.type === 'area') {
      filteredItem.subItems = []
    } else if (item.locationData?.type === 'region') {
      filteredItem.subItems?.forEach((leaf) => {
        leaf.subItems = []
      })
    } else if (item.locationData?.type === 'country') {
      filteredItem.subItems = []
    }
    return filteredItem
  }, [item])

  const renderCell = useCallback(
    (itm: PerformanceDescendantWithId<T>, _: number, columnKey: string) => {
      const columnConfig = columns.find((column) => column.key === columnKey)
      return (
        <AcVsPlTableCell data-test-id={`${dataTestId}-${columnKey}`}>
          {columnConfig?.customTemplate?.(itm) || columnConfig?.key}
        </AcVsPlTableCell>
      )
    },
    [columns, dataTestId]
  )

  return (
    <CollapsableRow<PerformanceDescendantWithId<T>>
      key={filteredItem.id}
      item={filteredItem}
      columnKeys={columns.map((column) => column.key)}
      renderCell={renderCell}
      itemPropsFn={getItemProps}
      collapseToggleTestIdGenerator={(id) => `${dataTestId}-collapse-button-${id}`}
    />
  )
}
