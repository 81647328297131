import {
  DOWNTIME,
  PERFORMANCE,
  STATUS,
  LOCATION_KPI,
  PLANT_KPI,
  LOCATION_KPI_STATUS,
  PLANT_KPI_STATUS
} from '../../shared/routes/routes'

export {
  DOWNTIME,
  PERFORMANCE,
  STATUS,
  LOCATION_KPI,
  PLANT_KPI,
  LOCATION_KPI_STATUS,
  PLANT_KPI_STATUS
}

// Extended routes - Status
export const GLOBAL_KILN_STATUS = STATUS
export const AREA_KPI_STATUS = `${STATUS}/area/:locationId`
export const STOPPAGE_ANALYSIS_STATUS = `${STATUS}/plant/:plantId/equipment/:equipmentId`

// Extended routes - Performance
export const GLOBAL_KILN_PERFORMANCE = PERFORMANCE
export const AREA_KPI = `${PERFORMANCE}/area/:locationId`
export const PLANT_PERFORMANCE = `${PERFORMANCE}/plant/:plantId`
export const STOPPAGE_ANALYSIS = `${PERFORMANCE}/plant/:plantId/equipment/:equipmentId` // TODO: clean this up, why do we need 2 plant page urls?

// Extended routes - Downtime
export const PLANT_DOWNTIME = `${DOWNTIME}/:plantId`
export const PLANT_DOWNTIME_STOPPAGES = `${DOWNTIME}/:plantId/stoppages`
export const PLANT_DOWNTIME_STOPPAGES_DETAILS = `${DOWNTIME}/:plantId/stoppages/:groupType/:groupId`
export const PLANT_DOWNTIME_STOPPAGES_DETAILS_FOR_CODE = `${DOWNTIME}/:plantId/stoppages/:groupType/:groupId/stoppageCode/:stoppageCode`

// Error pages
export const UNAUTHORIZED = '/unauthorized'
export const ERROR_403 = '/error403'
export const ERROR_500 = '/error500'
