import {PageContainer} from '@hconnect/uikit/src/lib2'
import {Masonry} from '@mui/lab'
import {Grid, Typography} from '@mui/material'
import {first} from 'lodash'
import React, {Fragment, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate, useParams} from 'react-router'

import {checkFeatureFlag} from '../../shared/helpers/featureFlagHelpers'
import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'
import {CementIcon} from '../assets/CementIcon'
import {ClinkerIcon} from '../assets/ClinkerIcon'
import {DataContentWrapper} from '../components/DataContentWrapper'
import {DataInfo} from '../components/DataInfo'
import {DateTimeRangePicker} from '../components/DateTimeRangePicker'
import {PlantSummaryCard} from '../components/PlantSummaryCard'
import {ProductionBox} from '../components/ProductionBox'
import {StatusPageHeader} from '../containers/StatusPageHeader'
import {getProductionValue} from '../helpers/actualVsTotal.helpers'
import {checkIsArea, getLocationParent} from '../helpers/location.helpers'
import {useActualOrTotalValue} from '../hooks/useActualValue'
import {useHierarchyData} from '../hooks/useHierarchy'
import {useLocationStatus} from '../hooks/useLocationStatus'
import {useStatusInitialTimeRange} from '../hooks/useStatusInitialTimeRange'
import {useTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {AREA_KPI_STATUS, GLOBAL_KILN_STATUS, STATUS} from '../routing'
import {LocationStatus as LocationStatusData, PlantStatus, TimeRange} from '../types'

type PathParameter = {
  locationId: string
}

export const LocationStatus: React.FC = () => {
  const {locationId} = useParams<PathParameter>()
  if (!locationId) throw new Error('Missing locationId prop')

  const {isLocationAllowed, allowMultipleLocations} = useNavAccessContext()

  const navigate = useNavigate()

  useEffect(() => {
    if (!isLocationAllowed(locationId)) {
      navigate(GLOBAL_KILN_STATUS)
    }
  }, [isLocationAllowed, locationId, navigate])

  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const {from, to} = useTimeRange()
  const hierarchy = useHierarchyData()

  const dateRange: TimeRange = {startDate: from.toJSON(), endDate: to.toJSON()}
  const {data, isLoading, error, refetch} = useLocationStatus(locationId, dateRange)
  const flags = useActualOrTotalValue()
  const timeRangeLoaded = useStatusInitialTimeRange()

  const clinkerProductionValue = getProductionValue({
    productionValueFlags: flags,
    actualValue: data?.actualClinkerProduction,
    totalValue: data?.totalClinker
  })
  const cementProductionValue = getProductionValue({
    productionValueFlags: flags,
    actualValue: data?.actualCementProduction,
    totalValue: data?.totalCement
  })

  const filteredPlants = data?.plantStatuses

  const hasNonGrindingUnitPlant = filteredPlants?.some(
    (p) => !p.plant.pxTrendMetaData?.isGrindingUnit
  )

  const showGlobalKilnMillStatus = checkFeatureFlag('enableKilnMillGlobalStatus')

  const backButtonProps = useMemo(() => {
    const parent = hierarchy && getLocationParent(hierarchy, locationId)
    if (parent && isLocationAllowed(parent.id) && checkIsArea(parent.id)) {
      return {
        targetName: t(`${performancePrefix}.goBackLabels.backToArea`),
        target: generatePath(AREA_KPI_STATUS, {locationId: parent.id})
      }
    }

    if (showGlobalKilnMillStatus && allowMultipleLocations()) {
      return {
        targetName: t(`${performancePrefix}.goBackLabels.backToGlobal`),
        target: STATUS
      }
    }

    return undefined
  }, [
    hierarchy,
    showGlobalKilnMillStatus,
    allowMultipleLocations,
    isLocationAllowed,
    locationId,
    t,
    performancePrefix
  ])

  const renderContent = () => (
    <>
      <StatusPageHeader
        countryCode={first(filteredPlants)?.plant.countryCode}
        title={data?.locationName}
        filterComponent={<DateTimeRangePicker type="status" disableCustomSelect />}
        backButtonProps={backButtonProps}
        afterTitle={<DataInfo />}
      />

      {data && (
        <Grid container xs={12} spacing={2} sm={6} item sx={{marginBottom: 2}}>
          {hasNonGrindingUnitPlant && (
            <Grid item xs={12} sm={6}>
              <ProductionBox
                icon={<ClinkerIcon fontSize="small" sx={{marginRight: 1}} />}
                title={t(`${performancePrefix}.production.label.clinker`)}
                volume={clinkerProductionValue}
                unit={data.unitClinker}
                data-test-id="location-status-total-clinker"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <ProductionBox
              icon={<CementIcon fontSize="small" sx={{marginRight: 1}} />}
              title={t(`${performancePrefix}.production.label.cement`)}
              volume={cementProductionValue}
              unit={data.unitCement}
              data-test-id="location-status-total-cement"
            />
          </Grid>
        </Grid>
      )}

      <Typography variant="h2" sx={{fontSize: 24, mb: 2, mt: 3, ml: 3}}>
        {t(`${performancePrefix}.locationStatus.label.plants`)}
      </Typography>

      <Masonry columns={{xs: 1, sm: 2, lg: 3}} spacing={2} sx={{width: 'auto'}}>
        {(filteredPlants || []).map((plantStatus: PlantStatus) => {
          return (
            <PlantSummaryCard
              key={plantStatus.plant.plantId}
              plantStatus={plantStatus}
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              data-test-id={`plant-status-card-${plantStatus.plant.plantId}`}
            />
          )
        })}
      </Masonry>
    </>
  )

  if (!timeRangeLoaded) {
    return <Fragment />
  }
  return (
    <PageContainer>
      <DataContentWrapper<LocationStatusData>
        isLoading={isLoading}
        data={data}
        retryFunction={() => void refetch()}
        error={error}
        renderContent={renderContent}
      />
    </PageContainer>
  )
}
