import {CardBox} from '@hconnect/uikit/src/lib2'
import moment from 'moment-timezone'
import React, {useEffect, useState} from 'react'

import {getSystemTimezone} from '../../shared/hooks/useTimezone'
import {DataContentWrapper} from '../components/DataContentWrapper'
import {NewStoppagesBanner} from '../components/downtime'
import {AmendStoppagesNavigator} from '../components/downtime/AmendStoppagesNavigator'
import {StoppagesListNavigator} from '../components/downtime/StoppagesListNavigator'
import {useRunningTimesOffPeriodsWithoutDetail} from '../hooks/useRunningTimesOffPeriodsWithoutDetail'
import {RunningTimesOffPeriodsWithoutDetail} from '../types'

export type StoppagesNavigatorView = 'list' | 'navigator' | null

const StoppagesNavigator: React.FC<{
  plantId: string
  view: StoppagesNavigatorView
  setView: (view: StoppagesNavigatorView) => void
}> = ({plantId, view, setView}) => {
  const timezone = getSystemTimezone()
  const startOfToday = moment.utc(new Date()).tz(timezone).startOf('day')
  const stoppagesWithoutDetailTimeRange = {
    from: startOfToday.clone().subtract(3, 'month').toDate(),
    to: startOfToday.clone().endOf('day').toDate()
  }

  const [stoppages, setStoppages] = useState<RunningTimesOffPeriodsWithoutDetail[]>([])

  const {
    data: newStoppages,
    isLoading,
    isSuccess,
    error,
    refetch
  } = useRunningTimesOffPeriodsWithoutDetail(plantId, {
    from: stoppagesWithoutDetailTimeRange.from.toJSON(),
    to: stoppagesWithoutDetailTimeRange.to.toJSON()
  })

  useEffect(() => {
    if (newStoppages && newStoppages?.length > 0) {
      /**
       * This api is prefetched in the DowntimeDashboard component, but react-query doesn't execute onSuccess callback for a cache hit.
       * hence, we need to set data in the state with useEffect
       */
      setStoppages(newStoppages)
    }
  }, [newStoppages])

  const removeStoppage = (stoppage: RunningTimesOffPeriodsWithoutDetail) => {
    setStoppages((prev) =>
      prev.filter(
        (_stoppage) =>
          !(
            _stoppage.equipment.id === stoppage.equipment.id &&
            _stoppage.runningTime.id === stoppage.runningTime.id
          )
      )
    )
  }

  const renderContent = () => {
    if (view === 'list') {
      return (
        <StoppagesListNavigator
          stoppages={stoppages}
          onViewChange={setView}
          removeStoppage={removeStoppage}
          isLoading={!isSuccess}
        />
      )
    }

    if (view === 'navigator') {
      return (
        <AmendStoppagesNavigator
          stoppages={stoppages}
          onViewChange={setView}
          removeStoppage={removeStoppage}
        />
      )
    }
  }

  return (
    <DataContentWrapper
      isLoading={isLoading}
      error={error}
      data={newStoppages}
      retryFunction={() => void refetch()}
      renderContent={renderContent}
      progressSx={{color: 'primary.main'}}
    />
  )
}

type Props = {
  plantId: string
  newStoppagesCount: number
}

export const NewStoppagesBannerContainer: React.FC<Props> = ({plantId, newStoppagesCount}) => {
  const [view, setView] = useState<StoppagesNavigatorView>(null)

  return (
    <CardBox
      data-test-id="new-stoppages-banner"
      sx={{
        height: 'calc(100vh - 166px)',
        maxHeight: '925px',
        overflow: 'hidden'
      }}
    >
      {view !== null ? (
        <StoppagesNavigator plantId={plantId} view={view} setView={setView} />
      ) : (
        <NewStoppagesBanner newStoppagesCount={newStoppagesCount} setView={setView} />
      )}
    </CardBox>
  )
}
