import {TextOnBackground} from '@hconnect/uikit/src/lib2'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {Box, IconButton} from '@mui/material'
import {isString} from 'lodash'
import React, {FC, ReactNode} from 'react'

type CollapseContentContainerProps = {
  onClick: () => void
  isOpen: boolean
  title: string | React.ReactNode
  action?: ReactNode
  'data-test-id': string
  children?: React.ReactNode
}

export const CollapseContentContainer: FC<CollapseContentContainerProps> = ({
  onClick,
  isOpen,
  title,
  action,
  'data-test-id': dataTestId,
  children
}) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            marginLeft: 2,
            cursor: 'pointer'
          }}
          onClick={onClick}
        >
          <IconButton
            sx={(theme) => ({color: theme.palette.common.white, mr: 1})}
            data-test-id={dataTestId}
          >
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          {isString(title) ? <TextOnBackground>{title}</TextOnBackground> : title}
        </Box>
        {action}
      </Box>
      {children}
    </>
  )
}
