export enum StoppageAnalisysPageReferer {
  STATUS = 'status',
  PERFORMANCE = 'performance',
  RELIABILITY_ENGINEER_DASHBOARD = 'reliability-engineer-dashboard',
  RELIABILITY_ENGINEER_PLANT_STATUS = 'reliability-engineer-plant-status'
}

export enum PlantStatusPageReferer {
  STATUS = 'status',
  PERFORMANCE = 'performance',
  RELIABILITY_ENGINEER_DASHBOARD = 'reliability-engineer-dashboard'
}
