import React from 'react'

import {Log} from '../lib/Log'
import {SimpleErrorPage} from '../pages/SimpleErrorPage'

// learn more about ErrorBoundary here
// https://reactjs.org/docs/error-boundaries.html
// TODO (this is a copy of the ErrorBoundary described in the documentation) adjust it to our needs
type Props = {
  children: React.ReactNode
}

type State = {
  hasError: boolean
}
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Log.extras({errorInfo}).error(error)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <SimpleErrorPage>Something went wrong.</SimpleErrorPage>
    }

    return this.props.children
  }
}
