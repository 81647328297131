export const getProductionValue = ({productionValueFlags, actualValue, totalValue}): number => {
  const {showActualValueOnly, showActualOrTotalValue} = productionValueFlags
  const productionValue = showActualValueOnly
    ? actualValue
    : showActualOrTotalValue && actualValue
      ? actualValue
      : totalValue

  return productionValue
}
