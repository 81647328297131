import React from 'react'

import {PlantEquipmentProductionVolumes, PlantEquipmentStatus} from '../types'

const plantEquipmentDataContext = React.createContext<
  | {
      plantEquipmentStatuses: Record<string, PlantEquipmentStatus>
      plantEquipmentProductionVolumes: Record<string, PlantEquipmentProductionVolumes>
    }
  | undefined
>(undefined)

export const PlantEquipmentDataProvider = ({
  children,
  plantEquipmentStatuses,
  plantEquipmentProductionVolumes
}: {
  children: React.ReactNode
  plantEquipmentStatuses: Record<string, PlantEquipmentStatus>
  plantEquipmentProductionVolumes: Record<string, PlantEquipmentProductionVolumes>
}) => {
  return (
    <plantEquipmentDataContext.Provider
      value={{
        plantEquipmentStatuses,
        plantEquipmentProductionVolumes
      }}
    >
      {children}
    </plantEquipmentDataContext.Provider>
  )
}

// used by consumers
export function usePlantEquipmentData() {
  const context = React.useContext(plantEquipmentDataContext)
  if (context === undefined) {
    throw new Error('usePlantEquipmentData must be used within a PlantEquipmentDataProvider')
  }
  return context
}
