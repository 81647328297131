import {type Permission} from '@hconnect/apiclient'
import {NotificationProvider} from '@hconnect/uikit'
import React from 'react'
import {Route} from 'react-router-dom'

import {PermissionsContextProvider} from '../shared/hooks/usePermission'
import {TranslationPrefixProvider} from '../shared/hooks/useTranslationPrefix'
import {DefaultShell} from '../shared/layouts'
import {Context} from '../shared/types/temp.types'

import {Tasks} from './pages'
import {TASKS} from './routing'

const Wrapper: React.FC<{
  context: Context
  permissions: Permission[]
  children: React.ReactNode
}> = ({children, context, permissions}) => (
  <PermissionsContextProvider initialState={permissions}>
    <TranslationPrefixProvider>
      <NotificationProvider anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}>
        <DefaultShell context={context}>{children}</DefaultShell>
      </NotificationProvider>
    </TranslationPrefixProvider>
  </PermissionsContextProvider>
)

export const getTasksRoutes = ({
  context,
  permissions
}: {
  context: Context
  permissions: Permission[]
}) => {
  return [
    <Route
      key="tasks"
      path={TASKS}
      element={
        <Wrapper context={context} permissions={permissions}>
          <Tasks />
        </Wrapper>
      }
    />
  ]
}
