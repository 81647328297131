import {usePriorities} from '@hconnect/common/hooks/usePriorities'
import {Priority} from '@hconnect/common/types'

import {FilterMultiSelect, FilterMultiSelectProps} from '../../../shared/components'
import {FILTER_VALUE_SEPARATOR} from '../../../shared/consts'

export const TaskPriorityFilter = <TName extends string>(
  props: Omit<FilterMultiSelectProps<Priority, TName>, 'options'>
) => {
  const priorities = usePriorities().data
  const options = priorities ? Array.from(priorities).map(([value, label]) => ({value, label})) : []

  return (
    <FilterMultiSelect
      {...props}
      options={options}
      renderValue={(value) => {
        return value
          .map((priority) => (priorities ? priorities.get(priority) : ''))
          .join(FILTER_VALUE_SEPARATOR)
      }}
    />
  )
}
