import {getTranslationKey} from '@hconnect/common/utils/translation.utils'
import {Chip, Box} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {ChipsFilterOption} from '../types/filters.types'

type ChipsFilterProps<TOptionValue extends string> = {
  options: ChipsFilterOption<TOptionValue>[]
  selectedOptions: TOptionValue[]
  onChange: (selected: TOptionValue[]) => void
  translationPrefix?: string
}

export const ChipsFilter = <TOptionValue extends string>({
  options,
  selectedOptions,
  onChange,
  translationPrefix
}: ChipsFilterProps<TOptionValue>) => {
  const {t} = useTranslation()

  const handleChipClick = (optionId: TOptionValue) => {
    const isSelected = selectedOptions.includes(optionId)
    const newSelectedOptions = isSelected
      ? selectedOptions.filter((id) => id !== optionId)
      : [...selectedOptions, optionId]

    onChange(newSelectedOptions)
  }
  const handleAllClick = () => onChange([])

  const isAllSelected = selectedOptions.length === 0

  return (
    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1.5}}>
      <Chip
        key="all"
        label={t(getTranslationKey('chipsFilter.all', translationPrefix))}
        clickable
        color={isAllSelected ? 'primary' : 'default'}
        onClick={handleAllClick}
      />
      {options.map((option) => (
        <Chip
          key={option.value}
          label={option.label}
          clickable
          color={selectedOptions.includes(option.value) ? 'primary' : 'default'}
          onClick={() => handleChipClick(option.value)}
        />
      ))}
    </Box>
  )
}
