import {DateFormat} from '@hconnect/uikit'
import moment from 'moment-timezone'
import React, {memo} from 'react'

import {getSystemTimezone} from '../../shared/hooks/useTimezone'

import {TimeFormat} from './charts'

type DateTimePeriodProps = {
  start: string
  end?: string
  timezone?: string
}

export const DateTimePeriod = memo<DateTimePeriodProps>(
  ({start, end, timezone = getSystemTimezone()}) => {
    const starDate = moment.utc(start).tz(timezone)
    const endDate = end ? moment.utc(end).tz(timezone) : moment.utc().tz(timezone)
    const isDateSame = starDate.isSame(endDate, 'day')

    return (
      <>
        <DateFormat date={start} timezone={timezone} />{' '}
        <TimeFormat date={start} timezone={timezone} />
        {end && (
          <>
            {' - '}
            {!isDateSame && (
              <>
                <DateFormat date={end} timezone={timezone} />{' '}
              </>
            )}
            <TimeFormat date={end} timezone={timezone} />
          </>
        )}
      </>
    )
  }
)
