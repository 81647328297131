import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {TaskSource, ChipsFilterOption} from '../types'

import {useTasksPageQueryParams} from './useTasksPageQueryParams'

type UseTasksTableFiltersOptions = {
  translationPrefix?: string
  onFiltersChanged?: () => void
}

export type UseTasksTableFiltersReturn = {
  sourceFilterOptions: ChipsFilterOption<TaskSource>[]
  sourceFilters: TaskSource[]
  onSourceFilterChange: (filters: TaskSource[]) => void
  assignedToMe: boolean
  toggleAssignedToMe: () => void
  showDescriptions: boolean
  toggleShowDescriptions: () => void
}

export const useTasksTableFilters = ({
  translationPrefix,
  onFiltersChanged
}: UseTasksTableFiltersOptions): UseTasksTableFiltersReturn => {
  const [params, {updateParams}] = useTasksPageQueryParams()

  const [sourceFilters, setSourceFilters] = useState<TaskSource[]>(params.sources ?? [])
  const handleSourceFilterChange = (sources: TaskSource[]) => {
    setSourceFilters(sources)
    updateParams({sources})
    onFiltersChanged?.()
  }
  const [assignedToMe, setAssignedToMe] = useState(params.assignedToMe ?? false)
  const toggleAssignedToMe = () => {
    setAssignedToMe((prev) => {
      const newAssignedToMe = !prev
      updateParams({assignedToMe: newAssignedToMe})
      return newAssignedToMe
    })
    onFiltersChanged?.()
  }
  const [showDescriptions, setShowDescriptions] = useState(false)
  const toggleShowDescriptions = () => setShowDescriptions((prev) => !prev)

  const {t} = useTranslation()
  const sourceFilterOptions: ChipsFilterOption<TaskSource>[] = [
    {
      label: t(`${translationPrefix}.tasksTable.filters.label.shift`),
      value: TaskSource.COCKPIT
    },
    {
      label: t(`${translationPrefix}.tasksTable.filters.label.pom`),
      value: TaskSource.EASY_POM
    }
  ]

  return {
    sourceFilterOptions,
    sourceFilters,
    onSourceFilterChange: handleSourceFilterChange,
    assignedToMe,
    toggleAssignedToMe,
    showDescriptions,
    toggleShowDescriptions
  }
}
