import {Box, Stack, Typography} from '@mui/material'
import React, {ReactNode, FC, useMemo} from 'react'

export type BarChartData = Array<{
  value: number
  name: string
  label: string | ReactNode
  backgroundColor?: string
  color?: string
}>

type BarChartProps = {
  data: BarChartData
}

const LABEL_LINE_HEIGHT_IN_PX = 24
const LABEL_CHART_BETWEEN_GAP = 2

export const BarChart: FC<BarChartProps> = ({data}) => {
  const maxValue = useMemo(() => Math.max(...data.map((item) => item.value)), [data])

  return (
    <Box
      display="flex"
      gap={LABEL_CHART_BETWEEN_GAP}
      mt={{xs: 0, md: 3}}
      mb={{xs: 0, md: 1}}
      mr={{xs: 0, md: 10}}
      ml={{xs: 0, md: 1}}
      data-test-id="ac-vs-pl-bar-chart-container"
    >
      <Stack flex={1} gap={1.5}>
        {data.map((item) => (
          <Box
            key={item.name}
            display="flex"
            flexDirection="row"
            gap={1}
            data-test-id={`bar-chart-bar-${item.name}`}
          >
            <Box
              minWidth={{
                xs: `${(item.value * 60) / maxValue}%`,
                md: `${(item.value * 100) / maxValue}%`
              }}
              sx={{
                backgroundColor: item.backgroundColor || 'grey.300',
                height: `${LABEL_LINE_HEIGHT_IN_PX}px`
              }}
              pl={1}
              pr={1}
              borderRadius={0.25}
              display="flex"
              alignItems="center"
            >
              <Typography
                variant="caption"
                py={1}
                color={item.color || 'secondary.main'}
                fontWeight={600}
                whiteSpace="nowrap"
              >
                {item.name}
              </Typography>
            </Box>
            <Box>
              <Typography key={item.name} whiteSpace="nowrap" component="div">
                {item.label}
              </Typography>
            </Box>
          </Box>
        ))}
      </Stack>
    </Box>
  )
}
