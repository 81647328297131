import {AxiosError} from 'axios'
import {NavigateFunction} from 'react-router-dom'

import {ERROR_403, ERROR_500} from '../../performanceDashboard/routing'

export const handleError = (err: AxiosError, navigate: NavigateFunction): void => {
  const errorCode = err.response?.status
  if (errorCode === 403) {
    navigate(ERROR_403)
  }
  if (errorCode && errorCode >= 500) {
    navigate(ERROR_500, {state: JSON.stringify(err)})
  }
}
