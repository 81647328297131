import {Comments, CommonComment} from '@hconnect/common/comments'
import {formatNumber} from '@hconnect/common/utils'
import {formatFloat, formatTimeZoneDate} from '@hconnect/uikit'
import {KpiScore} from '@hconnect/uikit/src/lib2'
import CloseIcon from '@mui/icons-material/Close'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import {Box, IconButton, Typography} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import moment from 'moment-timezone'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {getSystemTimezone} from '../../shared/hooks/useTimezone'
import {getComparisonDate} from '../helpers'
import {getLocationEntryWithParentsLabel} from '../helpers/location.helpers'
import {useCreateComment} from '../hooks/useCreateComment'
import {useTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {Comment, KpisList, CommentLocationData, HierarchyNode} from '../types'

type CommentDialogProps = {
  handleClose: () => void
  topic: KpisList
  comments: Comment[]
  commentLocationData: CommentLocationData
  hierarchy?: HierarchyNode
}

const DATE_FORMAT = 'DD.MM.YYYY'

type CommentItem = CommonComment & {
  userSelectedTimestampFrom: string
  userSelectedTimestampTo: string
  delta: number
}

export const CommentDialog = ({
  handleClose,
  commentLocationData,
  topic,
  hierarchy,
  comments
}: CommentDialogProps) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const timezone = getSystemTimezone()
  const {to, from} = useTimeRange()

  const {mutate} = useCreateComment(commentLocationData.locationData.id)

  const data = useMemo<CommentItem[]>(
    () =>
      comments.map((comment) => ({
        id: comment.id,
        text: comment.text,
        createdOn: moment.utc(comment.createdAt),
        createdBy: comment.creatorName || '',
        userSelectedTimestampFrom: comment.userSelectedTimestampFrom,
        userSelectedTimestampTo: comment.userSelectedTimestampTo,
        delta: comment.delta
      })),
    [comments]
  )

  const getCommentPath = useCallback(
    (commentId: string) => {
      const comment = comments.find(({id}) => id === commentId)

      if (comment && hierarchy) {
        const commentLocation = getLocationEntryWithParentsLabel(
          commentLocationData.locationData.id,
          comment.locationId,
          hierarchy
        )
        return (
          commentLocation && (
            <Typography
              variant="caption"
              display="inline-flex"
              data-test-id={`comment-${commentId}-path`}
            >
              <LocationOnOutlinedIcon sx={{fontSize: 16}} />
              {commentLocation}
            </Typography>
          )
        )
      }
    },
    [commentLocationData.locationData.id, comments, hierarchy]
  )

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="sm" data-test-id="comment-dialog">
      <Box display="flex" alignItems="center" justifyContent="space-between" pr={3} gap={1}>
        <DialogTitle variant="h3" data-test-id="comments-dialog-title">
          {commentLocationData.locationData.name} -{' '}
          {t(`${performancePrefix}.actualVsPlanned.label.${topic}`)}
        </DialogTitle>
        <IconButton aria-label="close" onClick={handleClose} data-test-id="comments-dialog-close">
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Box display="flex" justifyContent="center" gap={2}>
          <KpiScore
            value={formatNumber(commentLocationData.plannedCurrentValue, language)}
            unit={commentLocationData.unit}
            label={t(`${performancePrefix}.actualVsPlanned.label.plannedDate`, {
              date: getComparisonDate({to, from}, language)
            })}
            type="boxed"
            data-test-id="comment-dialog-planned-current-value"
          />
          <KpiScore
            value={formatNumber(commentLocationData.actualCurrentValue, language)}
            unit={commentLocationData.unit}
            label={t(`${performancePrefix}.actualVsPlanned.label.actualDate`, {
              date: getComparisonDate({to, from}, language)
            })}
            type="boxed"
            data-test-id="comment-dialog-actual-current-value"
          />
          <KpiScore
            value={formatFloat(commentLocationData.delta, 1)}
            unit="%"
            label={t(`${performancePrefix}.actualVsPlanned.label.delta`)}
            type="boxed"
            data-test-id="comment-dialog-delta"
          />
        </Box>
        <Comments
          data={data}
          isLoading={false}
          onAddComment={(text) => {
            mutate({
              text,
              topic,
              delta: commentLocationData.delta,
              userSelectedTimestampFrom: from.toJSON(),
              userSelectedTimestampTo: to.toJSON()
            })
          }}
          renderPrefix={getCommentPath}
          timeZone={timezone}
          hideDivider={true}
          showCounter={true}
          translationPrefix={performancePrefix}
          customCommentPostfix={(comment) => (
            <Typography variant="caption">
              {t(`${performancePrefix}.comments.description`, {
                by: comment.updatedBy ?? comment.createdBy,
                on: formatTimeZoneDate(
                  comment.updatedOn?.toISOString() ?? comment.createdOn.toISOString(),
                  timezone,
                  DATE_FORMAT,
                  language
                ),
                timePeriod: `${formatTimeZoneDate(
                  comment.userSelectedTimestampFrom,
                  timezone,
                  DATE_FORMAT,
                  language
                )} - ${formatTimeZoneDate(
                  comment.userSelectedTimestampTo,
                  timezone,
                  DATE_FORMAT,
                  language
                )}`,
                delta: `${formatFloat(comment.delta, 1, language)}%`
              })}
            </Typography>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}
