import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {mapLocationStatusFromDto} from '../mappers'
import {LocationStatus, LocationStatusResponse, TimeRange} from '../types'

import {useActualOrTotalValue} from './useActualValue'

const QueryKey = 'location-status'
const INTERVAL_PERIOD_IN_MS = 300000

export const useLocationStatus = (
  locationId: string,
  dateRange: TimeRange,
  options?: UseQueryOptions<LocationStatusResponse, AxiosError, LocationStatus>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const {showActualValueOnly, showActualOrTotalValue} = useActualOrTotalValue()

  return useQuery<LocationStatusResponse, AxiosError, LocationStatus>(
    [QueryKey, locationId, dateRange],
    async () => {
      const response = await axiosInstance.post<LocationStatusResponse>(
        `/kpi/dashboard/locations/${locationId}`,
        dateRange,

        {
          params: {
            // Note: We show actual production values based on quickselect filters
            // to fill these values on BE we need this flag
            includeActualProductionValues: showActualValueOnly || showActualOrTotalValue,
            // Note: enables running times on country/area pages
            includeRunningTimes: true
          }
        }
      )

      return response.data
    },
    {
      refetchInterval: INTERVAL_PERIOD_IN_MS,
      onError: (err) => handleError(err, navigate),
      select: (data) => mapLocationStatusFromDto(data),
      ...options
    }
  )
}
