import {AxiosError, AxiosInstance} from 'axios'
import {isEmpty} from 'lodash'
import {useCallback, useMemo} from 'react'
import {useQueries, UseQueryResult} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {PERFORMANCE_KPI_REQUEST_STALE_TIME} from '../../consts'
import {mapAlternativeFuelRatesDto} from '../../mappers'
import {
  AlternativeFuelRatesResponse,
  MtmYtmRequestPayload,
  KpiPfyPayload,
  PlannedAlternativeFuelRatesHierarchy
} from '../../types'

import {KpiValue} from './commonKpiApiTypes'
import {
  getAlternativeFuelRates,
  getAlternativeFuelRatesMtmYtmQueryKeys
} from './useAlternativeFuelRates'

export const getAlternativeFuelRatesPfy = async (
  axiosInstance: AxiosInstance,
  payload?: KpiPfyPayload
) => {
  const url = '/kpi/dashboard/alternative-fuel-rates-pfy'
  const response = await axiosInstance.get<PlannedAlternativeFuelRatesHierarchy>(url, {
    params: {...payload}
  })

  return response.data
}

export const useAlternativeFuelRatesData = (
  payload: MtmYtmRequestPayload,
  enabled = true
): KpiValue => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  const queries = useQueries<
    [
      UseQueryResult<PlannedAlternativeFuelRatesHierarchy, AxiosError>,
      UseQueryResult<AlternativeFuelRatesResponse, AxiosError>
    ]
  >([
    {
      queryKey: [
        'alternative-fuel-rates-pfy',
        {locationId: payload?.locationId, year: payload?.year}
      ],
      queryFn: () =>
        getAlternativeFuelRatesPfy(axiosInstance, {
          locationId: payload?.locationId,
          year: payload?.year
        }),
      enabled,
      staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
      onError: (err: AxiosError) => handleError(err, navigate)
    },
    {
      queryKey: getAlternativeFuelRatesMtmYtmQueryKeys(payload),
      queryFn: () => getAlternativeFuelRates(axiosInstance, payload),
      enabled,
      staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
      onError: (err: AxiosError) => handleError(err, navigate)
    }
  ])

  const refetch = useCallback(() => {
    queries.forEach(({error, refetch: queryRefetch}) => {
      if (error) {
        void queryRefetch()
      }
    })
  }, [queries])

  const [pfyQuery, dataQuery] = queries
  const pfyData = pfyQuery.data
  const data = dataQuery.data

  const alternativeFuelRates = useMemo(() => {
    return !isEmpty(pfyData) && !isEmpty(data)
      ? mapAlternativeFuelRatesDto(data, pfyData)
      : undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(pfyData), JSON.stringify(data)])

  return {
    data: alternativeFuelRates,
    isLoading: pfyQuery.isLoading || dataQuery.isLoading,
    error: pfyQuery.error || dataQuery.error,
    refetch
  }
}
