import {ShellFooter} from '@hconnect/uikit/src/lib2'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'

import {checkFeatureFlag} from '../helpers/featureFlagHelpers'
import {PRIVACY_POLICY, TERMS_AND_CONDITIONS} from '../routes'

export const Footer = ({plantId}: {plantId?: string}) => {
  const {t} = useTranslation()

  const navigate = useNavigate()

  const areTermsAndConditionsEnabled = checkFeatureFlag('enableTermsAndConditions')

  const termsAndConditionsLinkProps = areTermsAndConditionsEnabled
    ? {
        footerLinks: [
          {
            label: t('legal.termsAndConditions'),
            link: generatePath(TERMS_AND_CONDITIONS, {plantId: plantId as string})
          },
          {
            label: t('legal.dataPrivacy'),
            link: generatePath(PRIVACY_POLICY, {plantId: plantId as string})
          }
        ],
        navigate: (url: string) => navigate(url)
      }
    : {}

  return (
    <ShellFooter
      label={t('footer.poweredBy')}
      copyrightNote={t('footer.copyrightNote')}
      {...termsAndConditionsLinkProps}
    />
  )
}
