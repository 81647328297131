export const filters = [
  'failures',
  'mainEquipmentNumbers',
  'stoppageCodes',
  'equipmentNumbers',
  'stoppageDuration'
]
const filtersWithDateRange = [...filters, 'dateRange']
export type Filters = typeof filters
export type FiltersWithDateRangeKeys = (typeof filtersWithDateRange)[number]
export type FilterKeys = (typeof filters)[number]
