import {AxiosError, AxiosInstance} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {PERFORMANCE_KPI_REQUEST_STALE_TIME} from '../../consts'
import {formatDateUtcToTimezoneDateOnly} from '../../helpers/dateFormat.helpers'
import {MeanTimeBetweenFailuresResponse, MtmYtmRequestPayload} from '../../types'

const getMeanTimeBetweenFailuresMtmYtmUrlSuffix = (showYTM?: boolean) =>
  showYTM ? 'mean-time-between-failures-ytm' : 'mean-time-between-failures-mtm'

export const getMeanTimeBetweenFailuresMtmYtmQueryKeys = (requestPayload: MtmYtmRequestPayload) => [
  getMeanTimeBetweenFailuresMtmYtmUrlSuffix(requestPayload.showYTM),
  formatDateUtcToTimezoneDateOnly(requestPayload.timestamp, requestPayload.timezone),
  requestPayload.locationId
]

export const getMeanTimeBetweenFailures = async (
  axiosInstance: AxiosInstance,
  {timestamp, timezone, locationId, showYTM}: MtmYtmRequestPayload
) => {
  const referenceDate = formatDateUtcToTimezoneDateOnly(timestamp, timezone)
  const url = `/kpi/dashboard/${getMeanTimeBetweenFailuresMtmYtmUrlSuffix(showYTM)}`
  const response = await axiosInstance.get<MeanTimeBetweenFailuresResponse>(url, {
    params: {locationId, date: referenceDate}
  })

  return response.data
}

export const useMeanTimeBetweenFailures = <T>(
  payload: MtmYtmRequestPayload,
  options?: UseQueryOptions<MeanTimeBetweenFailuresResponse, AxiosError, T>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()
  const QueryKeys = getMeanTimeBetweenFailuresMtmYtmQueryKeys(payload)

  return useQuery<MeanTimeBetweenFailuresResponse, AxiosError, T>(
    QueryKeys,
    async () => {
      return getMeanTimeBetweenFailures(axiosInstance, payload)
    },
    {
      onError: (err) => handleError(err, navigate),
      staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
      ...options
    }
  )
}
