import {mergeSx} from '@hconnect/uikit/src/lib2'
import {CircularProgress, SxProps, Theme} from '@mui/material'
import {isArray, isEmpty} from 'lodash'
import React, {ReactNode} from 'react'

import {PlaceholderWrapper} from '../../shared/components/PlaceholderWrapper'

import {EmptyPlaceholder} from './EmptyPlaceholder'
import {ErrorPlaceholder} from './ErrorPlaceholder'

type DataContentWrapperProps<T> = {
  isLoading: boolean
  isEmpty?: boolean
  error: Error | null
  data?: T[] | T
  renderContent: () => ReactNode
  retryFunction?: () => void
  isRefetching?: boolean
  progressSx?: SxProps<Theme>
}

export function DataContentWrapper<T>({
  isLoading,
  isEmpty: isDataEmpty,
  error,
  data,
  renderContent,
  retryFunction,
  isRefetching,
  progressSx
}: DataContentWrapperProps<T>) {
  const renderSpinner = () => (
    <PlaceholderWrapper noBackground>
      <CircularProgress sx={mergeSx({color: 'common.white'}, progressSx)} />
    </PlaceholderWrapper>
  )

  const renderErrorComponent = () => {
    return (
      <PlaceholderWrapper>
        <ErrorPlaceholder retryFunction={retryFunction} isRefetching={isRefetching} />
      </PlaceholderWrapper>
    )
  }

  const renderEmpty = () => (
    <PlaceholderWrapper>
      <EmptyPlaceholder />
    </PlaceholderWrapper>
  )

  const renderDataContent = () => {
    if (isLoading) {
      return renderSpinner()
    }

    if (error) {
      return renderErrorComponent()
    }

    if (isDataEmpty || (isArray(data) && isEmpty(data)) || !data) {
      return renderEmpty()
    }

    return renderContent()
  }

  return <>{renderDataContent()}</>
}
