import {waitForDOMElementToAppear} from '@hconnect/uikit/src'
import {GuidedToursProvider} from '@hconnect/uikit/src/lib2'
import type {GuidedTour} from '@hconnect/uikit/src/lib2/components/guidedTour/types'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'
import {generatePath, useNavigate} from 'react-router-dom'

import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'
import {PLANT_DOWNTIME} from '../routing'

const getStepTranslationKey = (index: number, key: 'title' | 'description') =>
  `guidedTours.downtimeGrouping.steps.${index}.${key}`

type PathParameter = {
  plantId?: string
}

export const useHProduceTours = (): Record<string, GuidedTour> => {
  const {plantId} = useParams<PathParameter>()
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {allowedPlants} = useNavAccessContext()

  return useMemo(
    (): Record<string, GuidedTour> => ({
      downtimeGrouping: {
        title: t('guidedTours.downtimeGrouping.title'),
        description: t('guidedTours.downtimeGrouping.description'),
        image: <></>,
        onTourStarted: async () => {
          navigate(generatePath(PLANT_DOWNTIME, {plantId: plantId || allowedPlants[0]}))
          await waitForDOMElementToAppear('#group-by-drop-down')
        },
        steps: [
          {
            target: '#group-by-drop-down',
            title: t(getStepTranslationKey(0, 'title')),
            content: null,
            placement: 'bottom',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear('#group-by-drop-down')
            }
          },
          {
            target: '#sort-by-drop-down',
            title: t(getStepTranslationKey(1, 'title')),
            content: t(getStepTranslationKey(1, 'description')),
            placement: 'bottom'
          }
        ]
      }
    }),
    [allowedPlants, navigate, plantId, t]
  )
}

export const HProduceToursProvider = ({children}: {children: React.ReactNode}) => {
  const tours = useHProduceTours()

  const enabledTours = {downtimeGrouping: true}

  return (
    <GuidedToursProvider tours={tours} enabledTours={enabledTours}>
      {children}
    </GuidedToursProvider>
  )
}
