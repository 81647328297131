import {Typography} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../hooks/useTranslationPrefix'

export const EmptyPlaceholder: FC = () => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()

  return <Typography>{t(`${performancePrefix}.plantStatus.label.noData`)}</Typography>
}
