import {useTranslation} from '@hconnect/uikit'
import {useMemo} from 'react'

export const useSupportedLanguages = () => {
  const {i18n} = useTranslation()

  const supportedLanguages = useMemo(() => {
    const languagesRaw = i18n.options?.supportedLngs || [i18n.language]
    return languagesRaw.filter((code) => code !== 'cimode')
  }, [i18n.options, i18n.language])

  return supportedLanguages
}
