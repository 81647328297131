import {StoppageKind, DowntimeStoppageCode, StoppageType} from '@hconnect/common/types'
import {
  allOfTypeSelected,
  someOfTypeSelected,
  toggleAllFromType,
  DropDownOption,
  DropDownOptionWithPriority,
  stoppageCodesToDropDownOption
} from '@hconnect/common/utils/stoppageCodeFilterUtils'
import {mergeSxs} from '@hconnect/uikit/src/lib2'
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  IndeterminateCheckBoxOutlined,
  KeyboardArrowDown,
  KeyboardArrowRight
} from '@mui/icons-material'
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  TextField,
  useTheme,
  Paper,
  Box,
  IconButton,
  Collapse,
  MenuItem
} from '@mui/material'
import {isEmpty} from 'lodash'
import React, {useMemo, useState, useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {textFieldOnBlueSx} from '../../../shared/components'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'

export const toValueList = (
  selectedOptions: DropDownOption[] | DropDownOptionWithPriority[]
): string[] | undefined => {
  const newSelection = selectedOptions.map((option) => option.stoppageCode.code)
  return isEmpty(newSelection) ? undefined : newSelection
}

const getStoppageTranslationKeys = function (prefix: string): {[key in StoppageType]: string} {
  return {
    kiln: `${prefix}.stoppageTypeKiln`,
    cementMill: `${prefix}.stoppageTypeCementMill`,
    rawMill: `${prefix}.stoppageTypeRawMill`,
    coalMill: `${prefix}.stoppageTypeCoalMill`,
    crusher: `${prefix}.stoppageTypeCrusher`,
    rollerPress: `${prefix}.stoppageTypeRollerPress`,
    verticalMill: `${prefix}.stoppageTypeVerticalMill`,
    ballMill: `${prefix}.stoppageTypeBallMill`,
    dosingEquipment: `${prefix}.stoppageTypeDosingEquipment`,
    alternativeFuelInstallation: `${prefix}.stoppageTypeAlternativeFuelInstallation`,
    bypass: `${prefix}.stoppageTypeBypass`,
    other: `${prefix}.stoppageTypeOther`
  }
}

interface StoppageCodeFilterDropDownProps {
  'data-test-id'?: string
  onChange: (newValue: string[] | undefined) => void
  value?: string[]
  stoppageKinds: StoppageKind[]
  stoppageCodesMap: Partial<Record<StoppageType, DowntimeStoppageCode[]>>
  isLoading: boolean
  showOthers?: boolean
}

export const StoppageCodeFilterDropDown: React.FC<StoppageCodeFilterDropDownProps> = ({
  value = [],
  onChange,
  stoppageKinds,
  'data-test-id': dataTestId,
  stoppageCodesMap,
  isLoading
}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const theme = useTheme()
  const [expandedTypes, setExpandedTypes] = useState<StoppageType[]>([])
  const dropDownOptions = useMemo(
    () => stoppageCodesToDropDownOption(stoppageCodesMap, stoppageKinds, t),
    [stoppageCodesMap, stoppageKinds, t]
  )

  const [searchTerm, setSearchTerm] = useState('')
  const onToggleExpand = useCallback((type: StoppageType) => {
    setExpandedTypes((types) =>
      types.includes(type) ? types.filter((t) => t !== type) : [...types, type]
    )
  }, [])
  const selectedOptions = useMemo(
    () => dropDownOptions.filter((option) => value.includes(option.stoppageCode.code)),
    [dropDownOptions, value]
  )

  const stoppageTranslationKeys = useMemo(
    () => getStoppageTranslationKeys(`${performancePrefix}.shiftEvent.stoppage`),
    [performancePrefix]
  )

  const renderGroup = (option: AutocompleteRenderGroupParams): React.ReactNode => {
    const optStoppageType = option.group as StoppageType
    const onGroupSelected = (stoppageType: StoppageType) => {
      onChange(toValueList(toggleAllFromType(dropDownOptions, selectedOptions, stoppageType)))
    }

    return (
      <Box display={'flex'} my={1} key={option.key}>
        <Box pl={1}>
          {option.group !== 'other' && (
            <IconButton
              onClick={() => {
                onToggleExpand(optStoppageType)
              }}
              sx={{
                boxShadow: 'none',
                border: 0,
                '&:hover': {
                  boxShadow: 'none',
                  border: 0,
                  backgroundColor: theme.palette.grey[100]
                },
                '&:active': {
                  backgroundColor: theme.palette.grey[200]
                }
              }}
              data-test-id={`events-filter-bar-stoppageCode-expand-btn-${option.group}`}
            >
              {expandedTypes.includes(optStoppageType) ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowRight />
              )}
            </IconButton>
          )}
        </Box>
        <Box flex={1} data-test-id={`events-filter-bar-stoppageCode-group-${option.group}`}>
          <Box
            display={'flex'}
            px={1}
            py={1}
            sx={(theme) => ({
              cursor: 'pointer',
              fontWeight: theme.typography.fontWeightMedium,
              '&:hover': {
                backgroundColor: theme.palette.grey[100]
              }
            })}
            onClick={() => {
              onGroupSelected(optStoppageType)
            }}
          >
            {allOfTypeSelected(stoppageCodesMap, selectedOptions, optStoppageType) ? (
              <CheckBoxIcon style={{marginRight: theme.spacing(1), verticalAlign: 'text-bottom'}} />
            ) : someOfTypeSelected(selectedOptions, option.group as StoppageType) ? (
              <IndeterminateCheckBoxOutlined
                style={{marginRight: theme.spacing(1), verticalAlign: 'text-bottom'}}
              />
            ) : (
              <CheckBoxOutlineBlankIcon
                style={{marginRight: theme.spacing(1), verticalAlign: 'text-bottom'}}
              />
            )}
            {t(stoppageTranslationKeys[optStoppageType])}
          </Box>
          <Collapse in={expandedTypes.includes(optStoppageType)}>{option.children}</Collapse>
        </Box>
      </Box>
    )
  }

  return (
    <Autocomplete
      multiple
      loading={isLoading}
      data-test-id={dataTestId}
      id={'stoppage-code-filter-autocomplete-id'}
      options={dropDownOptions}
      value={selectedOptions}
      disableCloseOnSelect
      onChange={(event, next) => {
        onChange(toValueList(next))
      }}
      inputValue={searchTerm}
      onInputChange={(event, value, reason) => {
        if (reason !== 'reset') {
          setSearchTerm(value)
        }
      }}
      sx={{
        marginRight: {xs: 2, md: 0}
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={false}
          data-test-id={'events-filter-bar-stoppageCode-search-field'}
          sx={mergeSxs(
            {
              '& label': {
                width: 'calc(100% - 32px)',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }
            },
            textFieldOnBlueSx
          )}
          variant="filled"
          placeholder={t(`${performancePrefix}.pleaseStartTyping`)}
          label={
            isEmpty(selectedOptions)
              ? t(`${performancePrefix}.shiftEvent.label.stoppageCode`)
              : t(`${performancePrefix}.shiftEvent.label.stoppageCodes`, {
                  count: selectedOptions.length,
                  codes: selectedOptions.map((opt) => opt.stoppageCode.code).join(', ')
                })
          }
        />
      )}
      renderOption={(props, option) => (
        <MenuItem {...props}>
          <Box
            display={'flex'}
            pl={2.5}
            key={option.stoppageCode.code}
            data-test-id={`events-filter-bar-stoppage-code-${option.id}`}
          >
            {value.includes(option.stoppageCode.code) ? (
              <CheckBoxIcon style={{marginRight: theme.spacing(1), verticalAlign: 'text-bottom'}} />
            ) : (
              <CheckBoxOutlineBlankIcon
                style={{marginRight: theme.spacing(1), verticalAlign: 'text-bottom'}}
              />
            )}
            <Box flex={1}>{option.label}</Box>
          </Box>
        </MenuItem>
      )}
      getOptionLabel={(option) => option.label}
      groupBy={(option) => option.stoppageType}
      forcePopupIcon={false}
      renderGroup={renderGroup}
      PaperComponent={(props) => <Paper elevation={8} style={{minWidth: '350px'}} {...props} />}
      renderTags={() => ''}
    />
  )
}
