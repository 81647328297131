import {Filters} from '../components/downtime/filters/filters.models'
import {IssueGroupingKey} from '../types'

export const DOWNTIME_DETAILS_FILTER_OPTIONS: Record<IssueGroupingKey, Filters> = {
  [IssueGroupingKey.STOPPAGE_CODE]: ['failures', 'mainEquipmentNumbers', 'equipmentNumbers'],
  [IssueGroupingKey.TIME_INTERVAL]: [
    'failures',
    'mainEquipmentNumbers',
    'equipmentNumbers',
    'stoppageCodes'
  ],
  [IssueGroupingKey.MAIN_EQUIPMENT]: ['failures', 'equipmentNumbers', 'stoppageCodes'],
  [IssueGroupingKey.EQUIPMENT_NUMBER]: ['failures', 'mainEquipmentNumbers', 'stoppageCodes']
}

export const DOWNTIME_DETAILS_FOR_MAIN_EQUIPMENT_AND_CODE_FILTER_OPTIONS: Record<
  IssueGroupingKey.MAIN_EQUIPMENT | IssueGroupingKey.EQUIPMENT_NUMBER,
  Filters
> = {
  [IssueGroupingKey.MAIN_EQUIPMENT]: ['failures', 'equipmentNumbers'],
  [IssueGroupingKey.EQUIPMENT_NUMBER]: ['failures']
}
