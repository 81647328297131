import {mapRunningTime, mapRunningTimeDto} from '@hconnect/common/mappers'
import {Stoppage, StoppageDto} from '@hconnect/common/types'
import moment from 'moment-timezone'

import {getSystemTimezone} from '../../shared/hooks/useTimezone'

export const mapStoppage = (
  data: StoppageDto,
  timezone: string = getSystemTimezone()
): Stoppage => ({
  ...data,
  stoppageStart: moment.utc(data.stoppageStart).tz(timezone),
  stoppageEnd: data.stoppageEnd ? moment.utc(data.stoppageEnd).tz(timezone) : undefined,
  pxTrendStoppage: data.pxTrendStoppage && mapRunningTime(data.pxTrendStoppage, timezone)
})

export const mapPartialStoppage = (
  data: Partial<StoppageDto>,
  timezone = getSystemTimezone()
): Partial<Stoppage> =>
  ({
    ...data,
    ...('stoppageStart' in data
      ? {stoppageStart: data.stoppageStart && moment.utc(data.stoppageStart).tz(timezone)}
      : {}),
    ...('stoppageEnd' in data
      ? {stoppageEnd: data.stoppageEnd && moment.utc(data.stoppageEnd).tz(timezone)}
      : {}),
    ...('pxTrendStoppage' in data
      ? {
          pxTrendStoppage: data.pxTrendStoppage && mapRunningTime(data.pxTrendStoppage, timezone)
        }
      : {})
  }) as Partial<Stoppage>

export const mapStoppageDto = (data: Partial<Stoppage>): Partial<StoppageDto> =>
  ({
    ...data,
    ...('stoppageStart' in data ? {stoppageStart: data.stoppageStart?.toISOString()} : {}),
    ...('stoppageEnd' in data ? {stoppageEnd: data.stoppageEnd?.toISOString()} : {}),
    ...('pxTrendStoppage' in data
      ? {pxTrendStoppage: data.pxTrendStoppage && mapRunningTimeDto(data.pxTrendStoppage)}
      : {})
  }) as Partial<StoppageDto>
