/**
 * @description Use DateRange for BE requests
 */
export type TimeRange = {
  startDate: string
  endDate: string
}

/**
 * @description Date format agreement with BE is 'YYYY-MM-DD'
 * @example '2021-01-31'
 */
export type DateRange = {
  startDate: string
  endDate: string
}

export type DateRangeWithOffset = {
  dateRange: DateRange
  yearOffset?: number
}
