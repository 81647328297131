import {AppInsightsAdapter} from '@hconnect/common/logging'
import {hpTheme} from '@hconnect/uikit/src/lib2'
import {CssBaseline, ThemeProvider} from '@mui/material'
import React, {Suspense} from 'react'
import {createRoot} from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'

import {mockStore} from './__mock__/mockStoreSingleton'
import {AppWrapper} from './App'
import {createApi} from './shared/provider/createApi'
import {ErrorBoundary} from './shared/provider/ErrorBoundary'

const api = mockStore.isEnabled()
  ? createApi({throwOnNoMatch: true, mockEverything: true})
  : createApi({throwOnNoMatch: false, mockEverything: false})

// activate the userTracking of Azure
AppInsightsAdapter.init()
AppInsightsAdapter.allowTracking()

const rootElement = document.getElementById('root')
const root = createRoot(rootElement as HTMLElement)

root.render(
  <Suspense fallback={<div />}>
    <ThemeProvider theme={hpTheme}>
      <CssBaseline />
      <BrowserRouter>
        <ErrorBoundary>
          <AppWrapper api={api} />
        </ErrorBoundary>
      </BrowserRouter>
    </ThemeProvider>
  </Suspense>
)
