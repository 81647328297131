import {AppName} from '@hconnect/common/hproduce'
import {Shell, CommonHeader, ShellFooter} from '@hconnect/uikit/src/lib2'
import {Box, Button, CircularProgress, Typography} from '@mui/material'
import React, {Suspense} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation} from 'react-router-dom'

export type SimpleErrorPageProps = {
  appName?: AppName
  logout?: () => void
  children: React.ReactNode
}

export const SimpleErrorPage: React.FC<SimpleErrorPageProps> = ({appName, children, logout}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Suspense fallback={<CircularProgress />}>
      <Shell
        isResponsive
        header={
          <CommonHeader
            appName={appName || 'HProduce'}
            disableAppSelect
            supportedLanguages={[]}
            navigate={(url) => navigate(url)}
            locationPath={location.pathname}
            closeDropdownOnLanguageSwitch
          />
        }
        footer={
          <ShellFooter label={t('footer.poweredBy')} copyrightNote={t('footer.copyrightNote')} />
        }
      >
        <Box alignItems="center" display="flex" flexDirection="column" padding={2}>
          <Typography my={2} variant="h2">
            {t('errorPage.label.error')}
          </Typography>
          <Box>{children}</Box>
          {logout && (
            <Button color="primary" onClick={logout}>
              {t('errorPage.action.logout')}
            </Button>
          )}
        </Box>
      </Shell>
    </Suspense>
  )
}
