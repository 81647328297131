import {OperationHoursLegendTooltip} from '@hconnect/common/components/runningTimes'
import {EquipmentData} from '@hconnect/common/types'
import {getHoursMinutes} from '@hconnect/common/utils'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {FiberManualRecord as Circle} from '@mui/icons-material'
import {Box, Grid, Typography, Theme} from '@mui/material'
import {last, get} from 'lodash'
import moment from 'moment-timezone'
import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

import {useConfig} from '../../shared/hooks/configService'
import {isKilnRunning} from '../helpers'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {EquipmentRunningTimesResult} from '../types'

import {StackedBarChart} from './charts'

const statusStyle = {display: 'flex', alignItems: 'center'}

export type Props = {
  title?: string
  icon?: ReactNode
  equipmentRunningTimes?: EquipmentRunningTimesResult
  startDate: string
  endDate: string
  plantId: string
  noCard?: boolean
  noChartsSeparator?: boolean
  onClick?: (equipmentId: string) => void
  'data-test-id'?: string
  hideLegend?: boolean
}

export const OperationHoursTable: React.FC<Props> = (props) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const {data: config} = useConfig(props.plantId)

  const operationData = props.equipmentRunningTimes?.equipmentsWithRunningTimes ?? []

  const operationHoursTable = (
    <>
      <Box display="flex" alignItems="center">
        {props.title && (
          <Typography variant="h3" display="flex" alignItems="center">
            {props.icon}
            {props.title}
          </Typography>
        )}
        {!props.hideLegend && <OperationHoursLegendTooltip translationPrefix={performancePrefix} />}
      </Box>

      {operationData.map(({equipment, runningTimes, totalStopped}, index) => {
        // Note: Last element of the running times gives the currently active status
        const activeStatus = get(last(runningTimes), 'runningTimeType')

        return (
          <Box
            key={equipment?.id}
            sx={(theme: Theme) => ({
              '&:not(:last-of-type)': {
                borderBottom: props.noChartsSeparator
                  ? 'none'
                  : `1px solid ${theme.palette.grey.A200}`,
                padding: theme.spacing(2, 0)
              },
              '&:last-of-type': {
                paddingTop: theme.spacing(2)
              },
              cursor: props.onClick ? 'pointer' : 'unset'
            })}
            onClick={equipment?.id ? () => props.onClick?.(equipment.id) : undefined}
            data-test-id={`operation-hour-row-${index}`}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Box display="flex">
                <Typography mr={2}>{equipment?.text}</Typography>

                {activeStatus && isKilnRunning(activeStatus) && (
                  <Typography
                    mr={2}
                    variant="caption"
                    sx={(theme: Theme) => ({
                      ...statusStyle,
                      color: theme.palette.success.light
                    })}
                  >
                    <Circle
                      sx={(theme: Theme) => ({
                        color: theme.palette.success.light,
                        verticalAlign: 'text-bottom'
                      })}
                    />
                    {t(`${performancePrefix}.runningTime.label.running`)}
                  </Typography>
                )}
                {activeStatus && !isKilnRunning(activeStatus) && (
                  <Typography
                    mr={2}
                    variant="caption"
                    sx={(theme: Theme) => ({
                      ...statusStyle,
                      color: theme.palette.error.light
                    })}
                  >
                    <Circle
                      sx={(theme: Theme) => ({
                        color: theme.palette.error.light,
                        verticalAlign: 'text-bottom'
                      })}
                    />
                    {t(`${performancePrefix}.runningTime.label.off`)}
                  </Typography>
                )}
              </Box>
              {totalStopped && (
                <Box display="flex">
                  <Typography fontSize={12} color="text.secondary" mr={1}>
                    {t(`${performancePrefix}.runningTime.label.stoppedFor`)}
                  </Typography>
                  {/* show only hours and minutes */}
                  {`${getHoursMinutes(totalStopped)} h`}
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'stretch',
                flexWrap: 'wrap'
              }}
            >
              {!runningTimes ? (
                <Box>{t(`${performancePrefix}.plantStatus.label.noData`)}</Box>
              ) : (
                <StackedBarChart
                  timeRange={{
                    startDate: moment(props.startDate),
                    endDate: moment(props.endDate)
                  }}
                  data={runningTimes.filter((rt) => rt.begin !== rt.end)}
                  config={config}
                  equipment={
                    {
                      text: equipment?.text,
                      id: equipment?.id,
                      equipmentType: equipment?.type
                    } as EquipmentData
                  }
                />
              )}
            </Box>
          </Box>
        )
      })}
    </>
  )

  if (props.noCard) {
    return operationHoursTable
  }

  return (
    <Grid data-test-id={props['data-test-id']}>
      <CardBox>{operationHoursTable}</CardBox>
    </Grid>
  )
}
