import {StoppageDto as Stoppage} from '@hconnect/common/types'
import {AxiosError, AxiosInstance} from 'axios'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useMutation, UseMutationOptions, useQueryClient} from 'react-query'

import {useAxios} from '../../shared/hooks/useApi'
import {prepareStoppagePayload} from '../helpers'
import {StoppageCreate} from '../types'

import {useTranslationPrefix} from './useTranslationPrefix'

const createStoppage = async (
  axiosInstance: AxiosInstance,
  plantId: string,
  event: StoppageCreate
) => {
  const formData = await prepareStoppagePayload(event)
  const path = `/shifthandover/${plantId}/stoppages`

  const response = await axiosInstance.post<Stoppage>(path, formData)
  return response.data
}

export const useStoppageCreate = (
  plantId: string,
  options?: UseMutationOptions<Stoppage, AxiosError, StoppageCreate>
) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const queryClient = useQueryClient()
  const axiosInstance = useAxios()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation(
    (event: StoppageCreate) => {
      return createStoppage(axiosInstance, plantId, event)
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries()
        await options?.onSuccess?.(data, variables, context)
        enqueueSnackbar(t(`${performancePrefix}.newStoppagesBanner.created.success`), {
          variant: 'success'
        })
      },
      onError: (error, ...args) => {
        const errorMessage =
          error.response?.status === 409
            ? t(`${performancePrefix}.newStoppagesBanner.created.errorConflict`)
            : t(`${performancePrefix}.newStoppagesBanner.created.error`)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        options?.onError?.(error, ...args)
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        })
      }
    }
  )
}
