import {LoadingButton} from '@mui/lab'
import {Box, Typography} from '@mui/material'
import {isFunction} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

type ErrorPlaceholderProps = {
  translationPrefix: string
  retryFunction?: () => void
  isRefetching?: boolean
}

export const ErrorPlaceholder: React.FC<ErrorPlaceholderProps> = ({
  retryFunction,
  isRefetching,
  translationPrefix
}) => {
  const {t} = useTranslation()

  return (
    <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
      <Typography>{t(`${translationPrefix}.error.label.fetchingError`)}</Typography>
      {isFunction(retryFunction) && (
        <LoadingButton
          onClick={retryFunction}
          variant="outlined"
          color="primary"
          data-test-id="error-placeholder-retry-button"
          disabled={isRefetching}
          loading={isRefetching}
        >
          {t(`${translationPrefix}.error.action.retry`)}
        </LoadingButton>
      )}
    </Box>
  )
}
