import {Box} from '@mui/material'
import {Chart as ChartJS, ArcElement, Plugin, ChartOptions} from 'chart.js'
import React, {FC, useMemo} from 'react'
import {Doughnut} from 'react-chartjs-2'

type DoughnutChartProps = {
  data: number[]
  backgroundList: string[]
  pluginsList?: Plugin<'doughnut'>[]
  options?: ChartOptions<'doughnut'>
}

ChartJS.register(ArcElement)

export const DoughnutChart: FC<DoughnutChartProps> = ({
  data,
  backgroundList,
  pluginsList,
  options
}) => {
  const chartData = useMemo(() => {
    return {
      datasets: [
        {
          data: data,
          backgroundColor: backgroundList
        }
      ]
    }
  }, [data, backgroundList])

  return (
    <Box height="inherit" overflow="hidden">
      <Doughnut data={chartData} options={options} plugins={pluginsList} />
    </Box>
  )
}
