import {RunningTimesOffPeriodsWithoutDetail, ThinEquipmentRunningTimes} from '../types'

export const mapRunningTimesOffPeriodsWithoutDetailFlatList = (
  equipmentRunningTimes: ThinEquipmentRunningTimes[]
): RunningTimesOffPeriodsWithoutDetail[] =>
  equipmentRunningTimes.map(mapEquipmentRunningTimesToStoppages).flat()

const mapEquipmentRunningTimesToStoppages = (
  equipmentRunningTimes: ThinEquipmentRunningTimes
): RunningTimesOffPeriodsWithoutDetail[] => {
  const equipmentId = equipmentRunningTimes.equipment?.id
  if (!equipmentId) return []
  if (!equipmentRunningTimes.runningTimes) return []

  return (
    equipmentRunningTimes.runningTimes?.map((runningTime) => {
      const runningTimePeriod: RunningTimesOffPeriodsWithoutDetail = {
        runningTime,
        equipment: {
          ...equipmentRunningTimes.equipment,
          equipmentCategory: equipmentRunningTimes.equipment.category,
          equipmentType: equipmentRunningTimes.equipment.type
        }
      }
      return runningTimePeriod
    }) ?? []
  )
}
