// todo use the uikit component after react-router migration to version 6
import {buttonOnBlueSx} from '@hconnect/uikit/src/lib2'
import {BaseErrorPage} from '@hconnect/uikit/src/lib2/pages/BaseErrorPage'
import {ReactComponent as Illustration} from '@hconnect/uikit/src/lib2/pages/illustration500.svg'
import {ContentCopy} from '@mui/icons-material'
import {Button} from '@mui/material'
import {toString} from 'lodash'
import React from 'react'
import {useLocation} from 'react-router-dom'

interface Error500PageProps {
  caption: string
  description: string
  copyLogCaption?: string
}

export const Error500Page: React.FC<Error500PageProps> = ({
  caption,
  description,
  copyLogCaption
}) => {
  const location = useLocation()
  const onCopy = () => {
    void navigator.clipboard.writeText(toString(location.state))
  }

  return (
    <BaseErrorPage caption={caption} description={description} illustration={<Illustration />}>
      {copyLogCaption && (
        <Button onClick={onCopy} sx={buttonOnBlueSx} startIcon={<ContentCopy />}>
          {copyLogCaption}
        </Button>
      )}
    </BaseErrorPage>
  )
}
