import {RemoveFilterButton, SelectAllFilterButton} from '@hconnect/common/components'
import {Box, Checkbox, MenuItem, TextField} from '@mui/material'

import {FILTER_VALUE_SEPARATOR} from '../../consts'

import {selectFieldOnBlueSx} from './filterStyles'

export type FilterMultiSelectOption<T extends string | number> = {
  value: T
  label: string
}

export type FilterMultiSelectProps<TOptionValue extends string | number, TName extends string> = {
  name: TName
  label: string
  options: FilterMultiSelectOption<TOptionValue>[]
  value?: TOptionValue[]
  onChange: (name: TName, value?: TOptionValue[]) => void
  clearFilter: (keyName: TName) => void
  enableSelectAll?: boolean
  getOptionLabel?: (option: FilterMultiSelectOption<TOptionValue>) => React.ReactNode
  renderValue?: (args: TOptionValue[]) => React.ReactNode
  translationPrefix: string
  'data-test-id'?: string
}

export const FilterMultiSelect = <TOptionValue extends string | number, TName extends string>({
  name,
  label,
  options,
  value,
  onChange,
  clearFilter,
  enableSelectAll = true,
  getOptionLabel = ({label}) => label,
  renderValue = (args) => args.join(FILTER_VALUE_SEPARATOR),
  translationPrefix,
  'data-test-id': dataTestId
}: FilterMultiSelectProps<TOptionValue, TName>) => {
  const fieldDataTestId = dataTestId ?? `${name}-filter`

  return (
    <TextField
      variant="filled"
      sx={selectFieldOnBlueSx}
      data-test-id={fieldDataTestId}
      fullWidth
      label={label}
      required={false}
      onChange={(event) => {
        const val = event.target.value as unknown as TOptionValue[] | undefined
        if (val?.length === 0) onChange(name, undefined)
        else onChange(name, val)
      }}
      value={value ?? []}
      SelectProps={{
        multiple: true,
        renderValue: (args) => renderValue(args as TOptionValue[])
      }}
      select
    >
      {options.map((option) => (
        <MenuItem
          data-test-id={`${fieldDataTestId}-${option.value}`}
          key={option.value}
          value={option.value}
        >
          <Checkbox checked={!!value?.includes(option.value)} sx={{mr: 1}} />
          {getOptionLabel(option)}
        </MenuItem>
      ))}
      <Box>
        {enableSelectAll && (
          <SelectAllFilterButton
            keyName={name}
            onClick={() => {
              const allOptions = options.map(({value}) => value)

              onChange(name, allOptions)
            }}
            translationPrefix={translationPrefix}
          />
        )}
        <RemoveFilterButton
          keyName={name}
          removeFilter={(key) => clearFilter(key as TName)}
          translationPrefix={translationPrefix}
        />
      </Box>
    </TextField>
  )
}
