import {Localization} from '@hconnect/uikit'

const isDev = process.env.NODE_ENV === 'development'
async function loader(filename: string) {
  isDev && console.info(`Load Translation [${filename}]`)
  try {
    return (await import(`./${filename}`)).default
  } catch (err) {
    isDev && console.error(`Could not load Translation [${filename}]`, err)
    return {err}
  }
}

export async function initLocalization() {
  return Localization({
    fallbackLng: 'en-US',
    supportedLngs: [
      'de-DE',
      'en-US',
      'fr-FR',
      'hu-HU',
      'it-IT',
      'kk-KZ',
      'no-NO',
      'nl-NL',
      'pl-PL',
      'ro-RO',
      'ru-RU',
      'sv-SE',
      'th-TH'
    ],
    detection: {
      order: ['localStorage', 'cookie', 'navigator']
    },
    // when a translation is empty string "" this will result in using the fallback language translation
    returnEmptyString: false,
    backend: {
      loader
    }
  })
}
