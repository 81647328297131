import {Box, Typography} from '@mui/material'
import {DataProps, PieSvgProps, ResponsivePie} from '@nivo/pie'
import React, {ReactNode} from 'react'

type PieChartProps<T> = DataProps<T> &
  Partial<Omit<PieSvgProps<T>, 'width' | 'height'>> & {
    legend: string
    subLegend?: string | ReactNode
    small?: boolean
  }

const SIZE_BIG_IN_PX = 240
const SIZE_SMALL_IN_PX = 72

export function PieChart<T>({data, legend, subLegend, small, ...rest}: PieChartProps<T>) {
  const size = small ? SIZE_SMALL_IN_PX : SIZE_BIG_IN_PX

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Box height={size} width={size} position="relative">
        <Box
          position="absolute"
          left="50%"
          top="50%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{transform: 'translate(-50%, -50%)'}}
        >
          <Typography variant={small ? 'body1' : 'h1'} fontWeight={small ? 400 : 300}>
            {legend}
          </Typography>
          {!small && subLegend}
        </Box>
        <ResponsivePie
          data={data}
          margin={{top: 0, right: 0, bottom: 0, left: 0}}
          innerRadius={0.9}
          activeOuterRadiusOffset={8}
          enableArcLinkLabels={false}
          enableArcLabels={false}
          isInteractive={false}
          animate={false}
          legends={[]}
          {...rest}
        />
      </Box>

      {small && subLegend}
    </Box>
  )
}
