import React from 'react'

import {Shell} from '../../shared/components/Shell'
import {usePermissionsContext} from '../../shared/hooks/usePermission'
import {useGetLinks} from '../../shared/routes/navLinks'
import {Context} from '../../shared/types/temp.types'

type Props = {
  context: Context
  children: React.ReactNode
}

export const DefaultShell = ({context, children}: Props) => {
  const permissions = usePermissionsContext()
  const navItems = useGetLinks({permissions})

  return (
    <Shell context={context} enableNotifications navItems={navItems}>
      {children}
    </Shell>
  )
}
