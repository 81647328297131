import {TasksFilterKey, TasksFilterKeyName} from '../consts'
import {CockpitCategory, TaskSource, TaskStatus} from '../types'

export const isTaskSource = (value: string | undefined): value is TaskSource =>
  Object.values(TaskSource).includes(value as TaskSource)

export const isCockpitCategory = (value: string | undefined): value is CockpitCategory =>
  Object.values(CockpitCategory).includes(value as CockpitCategory)

export const isTaskStatus = (value: string | undefined): value is TaskStatus =>
  Object.values(TaskStatus).includes(value as TaskStatus)

export const isTasksFilterKey = (value: string | undefined): value is TasksFilterKeyName =>
  Object.values(TasksFilterKey).includes(value as TasksFilterKeyName)
