/**
 * AccessStatus permission definition
 * 1 - no permission
 * 2 - no access - no PxTrend (valid for all three pages) - redirect to app selector page
 * 3 - redirect to country page (requested access to kiln status, but has only access to a single country)
 * 4 - redirect to plant page (requested access to kiln status or location page, but has only access to a single plant)
 */
export enum AccessStatus {
  NoPermission = 'noPermission',
  NoAccessNoKpi = 'noAccessNoKpi',
  SingleCountryAccess = 'singleCountryAccess',
  SinglePlantAccess = 'singlePlantAccess',
  MultiLocationsAccess = 'multiLocationsAccess'
}

export type AccessiblePlantIds = string[]

export type AccessibleLocations = string[]

export type NavigationAccess = {
  status: AccessStatus
  accessiblePlantIds?: AccessiblePlantIds
  accessibleLocations?: AccessibleLocations
}
