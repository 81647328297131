import EditIcon from '@material-ui/icons/Edit'
import ListIcon from '@material-ui/icons/List'
import CloseIcon from '@mui/icons-material/Close'
import {IconButton, Box, Button, ButtonGroup} from '@mui/material'
import React from 'react'

export type StoppageSwitchVariant = 'navigator' | 'list' | null

type Props = {
  value: StoppageSwitchVariant
  onChange: (variant: StoppageSwitchVariant) => void
}

export const AmendStoppagesVariantSwitch = ({value, onChange}: Props) => {
  return (
    <Box display="flex" alignItems="center">
      <ButtonGroup>
        <Button
          variant={value === 'navigator' ? 'contained' : 'outlined'}
          onClick={() => onChange('navigator')}
          data-test-id="amend-stoppages-button"
        >
          <EditIcon fontSize="small" />
        </Button>
        <Button
          variant={value === 'list' ? 'contained' : 'outlined'}
          onClick={() => onChange('list')}
          data-test-id="view-as-list-button"
        >
          <ListIcon fontSize="small" />
        </Button>
      </ButtonGroup>

      <IconButton
        onClick={() => onChange(null)}
        color="primary"
        data-test-id="stoppage-navigator-close"
        size="medium"
        sx={{
          margin: 1
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  )
}
