import {useSearchParams} from 'react-router-dom'

import {TasksPageQueryParams} from '../types'
import {tasksPageQueryParamsToUrlParams, urlParamsToTasksPageQueryParams} from '../utils'

type UseTasksPageQueryParamsReturn = [
  TasksPageQueryParams,
  {
    setParams: (newParams: TasksPageQueryParams) => void
    updateParams: (newParams: Partial<TasksPageQueryParams>) => void
  }
]

export const useTasksPageQueryParams = (): UseTasksPageQueryParamsReturn => {
  const [params, setSearchParams] = useSearchParams()
  const searchParams = urlParamsToTasksPageQueryParams(params)

  const setParams = (newParams: TasksPageQueryParams) => {
    setSearchParams(tasksPageQueryParamsToUrlParams(newParams))
  }

  const updateParams = (newParams: Partial<TasksPageQueryParams>) => {
    setParams({...searchParams, ...newParams})
  }

  return [searchParams, {setParams, updateParams}]
}
