import {isNil} from 'lodash'

import {LocationStatus, LocationStatusResponse, PlantStatus, PlantStatusResponse} from '../types'

export const mapPlantStatusFromDto = (data: PlantStatusResponse): PlantStatus => ({
  plant: data.plant,
  plannedCementProduction:
    data.actualProductionValue?.plannedCementProduction === undefined ||
    data.actualProductionValue?.plannedCementProduction === null
      ? data.totalCement
      : data.actualProductionValue.plannedCementProduction,
  plannedClinkerProduction:
    data.actualProductionValue?.plannedClinkerProduction === undefined ||
    data.actualProductionValue?.plannedClinkerProduction === null
      ? data.totalClinker
      : data.actualProductionValue.plannedClinkerProduction,
  actualCementProduction: data.actualProductionValue?.actualCementProduction,
  actualClinkerProduction: data.actualProductionValue?.actualClinkerProduction,
  totalCement: data.totalCement,
  totalClinker: data.totalClinker,
  unitCement: data.unitCement,
  unitClinker: data.unitClinker,
  equipmentRunningTimes: data.equipmentRunningTimes,
  equipments: data.equipments || [],
  plannedYearlyProductionVolume: data.plannedYearlyProductionVolume,
  clinkerDelta: data.actualProductionValue?.clinkerDelta || 0,
  cementDelta: data.actualProductionValue?.cementDelta || 0
})

export const mapLocationStatusFromDto = (data: LocationStatusResponse): LocationStatus => ({
  locationId: data.locationId,
  locationName: data.locationName,
  locationType: data.locationType,
  countryCode: data.countryCode,
  plannedCementProduction: isNil(data.plannedCementProduction)
    ? data.totalCement
    : data.plannedCementProduction,
  plannedClinkerProduction: isNil(data.plannedClinkerProduction)
    ? data.totalClinker
    : data.plannedClinkerProduction,
  actualCementProduction: data.actualCementProduction,
  actualClinkerProduction: data.actualClinkerProduction,
  totalCement: data.totalCement,
  totalClinker: data.totalClinker,
  clinkerDelta: data.clinkerDelta,
  cementDelta: data.cementDelta,
  unitCement: data.unitCement,
  unitClinker: data.unitClinker,
  descendants: data.descendants?.map(mapLocationStatusFromDto),
  plantStatuses: data.plantStatuses.map(mapPlantStatusFromDto)
})
