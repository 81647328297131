import {Paper, Stack} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {AcVsPlChartPie} from '../components/AcVsPlChartPie'
import {BarChart, BarChartData} from '../components/charts'
import {MobileLocationCards} from '../components/MobileLocationCards'
import {generateBarChartData} from '../helpers'
import {useTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {
  AlternativeFuelRates,
  Comment,
  CommentLocationData,
  AggregatedKpiData,
  KpisList
} from '../types'

type Props = {
  data: AggregatedKpiData
  hasQuarterlyData: boolean
  currentTab: KpisList
  hideCharts: boolean
  setCommentLocationData: (id: CommentLocationData) => void
  comments: Comment[]
}

export const MobileAcVsPlContainer = ({
  data,
  hasQuarterlyData,
  currentTab,
  hideCharts = false,
  setCommentLocationData,
  comments
}: Props) => {
  const {t, i18n} = useTranslation()
  const dateRange = useTimeRange()
  const {performancePrefix} = useTranslationPrefix()

  const barChartData: BarChartData = useMemo(
    () =>
      generateBarChartData({
        data,
        dateRange,
        currentTab,
        translationFn: (key: string, inputParams: {[key: string]: string} = {}) =>
          t(`${performancePrefix}.${key}`, inputParams),
        locale: i18n.language
      }),
    [data, dateRange, i18n.language, t, currentTab, performancePrefix]
  )

  return (
    <Stack gap={2}>
      {!hideCharts && (
        <Paper sx={{padding: 2}}>
          {currentTab !== KpisList.AlternativeFuelRate && <BarChart data={barChartData} />}

          {currentTab === KpisList.AlternativeFuelRate && (
            // Note: this is shown only for AlternativeFuelRates and hence types are mapped
            <AcVsPlChartPie data={data as unknown as AlternativeFuelRates} />
          )}
        </Paper>
      )}
      <MobileLocationCards
        data={data}
        hasQuarterlyData={hasQuarterlyData}
        currentTab={currentTab}
        dateRange={dateRange}
        setCommentLocationData={setCommentLocationData}
        comments={comments}
      />
    </Stack>
  )
}
