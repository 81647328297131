import {AddFilter} from '@hconnect/common/components/shiftEventFormFields/AddFilter'
import {
  DowntimeStoppageCode,
  EquipmentData,
  StoppageKind,
  allDowntimeStoppages,
  defaultDowntimeStoppages
} from '@hconnect/common/types'
import {StoppageCodesMap} from '@hconnect/common/utils/stoppageCodeFilterUtils'
import {Box} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useNavigate, useParams} from 'react-router'

import {useConfigData} from '../../../../shared/hooks/useConfig'
import {
  createUrl,
  getEquipmentIds,
  useGetEventNamesByRoutePath,
  useTrackDowntimeAnalyticsEvent
} from '../../../helpers'
import {useEquipments} from '../../../hooks/useEquipments'
import {useMainEquipments} from '../../../hooks/useMainEquipments'
import {useTimeRangeParam} from '../../../hooks/useTimeRangeParam'
import {useTranslationPrefix} from '../../../hooks/useTranslationPrefix'
import {DurationType} from '../../../types'
import {DateTimeRangePicker} from '../../DateTimeRangePicker'
import {EquipmentSearchField} from '../EquipmentFilter'
import {FailureFilter} from '../FailureFilter'
import {StoppageCodeFilterDropDown} from '../StoppageCodeFilterDropdown'
import {StoppageDurationFilter} from '../StoppageDurationFilter'

import type {FilterKeys, Filters} from './filters.models'
import {filters} from './filters.models'

type PathParameter = {
  plantId: string
}

const STORAGE_FAILURE_KEY = 'failureKey'

type Props = {
  path: string
  failureState: {
    failureStoppage?: StoppageKind[]
    setFailureStoppage: (failures: StoppageKind[] | undefined) => void
  }
  mainEquipmentState: {
    mainEquipments?: EquipmentData[]
    setMainEquipments: (mainEquipmentNumbers: EquipmentData[] | undefined) => void
  }
  equipmentNumbersState: {
    equipmentNumbers?: EquipmentData[]
    setEquipmentNumbers: (equipmentNumbers: EquipmentData[] | undefined) => void
  }
  stoppageCodesState: {
    stoppageCodes?: string[]
    setStoppageCodes?: (stoppageCode: string[] | undefined) => void
  }
  stoppageDurationState?: {
    stoppageDuration?: DurationType[]
    setStoppageDuration: (failures: DurationType[] | undefined) => void
  }
  options?: Filters
}

type FirstLoadFiltersData = {
  mainEquipments?: EquipmentData[]
  stoppageCodes?: string[]
  equipmentNumbers?: EquipmentData[]
  enabledFilters: Filters
  failuresOptions: {
    failureStoppage?: StoppageKind[]
    onFailureChange: (stoppage?: StoppageKind[]) => void
  }
  stoppageDuration?: DurationType[]
}
const getFirstLoadFilters = ({
  mainEquipments,
  stoppageCodes,
  equipmentNumbers,
  enabledFilters,
  failuresOptions: {failureStoppage, onFailureChange},
  stoppageDuration
}: FirstLoadFiltersData) => {
  let firstLoadFailureStoppage = failureStoppage
  const initFilters = sessionStorage.getItem(STORAGE_FAILURE_KEY)

  if (!initFilters && !firstLoadFailureStoppage) {
    firstLoadFailureStoppage = defaultDowntimeStoppages
    onFailureChange(defaultDowntimeStoppages)
  }
  sessionStorage.setItem(STORAGE_FAILURE_KEY, defaultDowntimeStoppages.join(','))

  const filters: Filters = []
  if (firstLoadFailureStoppage && enabledFilters.includes('failures')) filters.push('failures')
  if (mainEquipments && enabledFilters.includes('mainEquipmentNumbers'))
    filters.push('mainEquipmentNumbers')
  if (stoppageCodes && enabledFilters.includes('stoppageCodes')) filters.push('stoppageCodes')
  if (stoppageDuration && enabledFilters.includes('stoppageDuration'))
    filters.push('stoppageDuration')
  if (equipmentNumbers && enabledFilters.includes('equipmentNumbers'))
    filters.push('equipmentNumbers')
  return filters
}

export const DowntimeFilters: React.FC<Props> = (props) => {
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('Missing plantId prop')
  const navigate = useNavigate()
  const [timeRange] = useTimeRangeParam()
  const config = useConfigData()
  const {performancePrefix} = useTranslationPrefix()
  const trackAnalyticsEvent = useTrackDowntimeAnalyticsEvent()
  const matchingEventNames = useGetEventNamesByRoutePath()

  const {
    path,
    failureState: {failureStoppage, setFailureStoppage},
    mainEquipmentState: {mainEquipments, setMainEquipments},
    stoppageCodesState: {stoppageCodes, setStoppageCodes},
    equipmentNumbersState: {equipmentNumbers, setEquipmentNumbers},
    stoppageDurationState,
    options
  } = props

  const enabledFilters = options ?? filters

  const getSelectedFilters = useCallback(
    (type: FilterKeys, value?: StoppageKind[] | EquipmentData[] | string[] | DurationType) => {
      return {
        timeRange,
        failures: type === 'failures' ? value : failureStoppage,
        stoppageCode: type === 'stoppageCodes' ? (value as string[]) : stoppageCodes,
        stoppageDuration:
          type === 'stoppageDuration' ? value : stoppageDurationState?.stoppageDuration,
        mainEquipmentNumbers:
          type === 'mainEquipmentNumbers'
            ? getEquipmentIds(value as EquipmentData[])
            : getEquipmentIds(mainEquipments as EquipmentData[]),
        equipmentNumbers:
          type === 'equipmentNumbers'
            ? getEquipmentIds(value as EquipmentData[])
            : getEquipmentIds(equipmentNumbers as EquipmentData[])
      }
    },
    [
      timeRange,
      failureStoppage,
      stoppageCodes,
      stoppageDurationState?.stoppageDuration,
      mainEquipments,
      equipmentNumbers
    ]
  )

  const [visibleFilters, setFilter] = useState<Filters>(
    getFirstLoadFilters({
      failuresOptions: {
        failureStoppage,
        onFailureChange: setFailureStoppage
      },
      mainEquipments,
      stoppageCodes,
      equipmentNumbers,
      enabledFilters,
      stoppageDuration: stoppageDurationState?.stoppageDuration
    })
  )

  const onFailureChange = useCallback(
    (failures?: StoppageKind[]) => {
      setFailureStoppage(failures)
      if (matchingEventNames?.filteringEventName) {
        trackAnalyticsEvent(matchingEventNames?.filteringEventName, {
          filterName: 'failures'
        })
      }
    },
    [matchingEventNames?.filteringEventName, setFailureStoppage, trackAnalyticsEvent]
  )

  const onDateTimeRangeChange = useCallback(() => {
    if (matchingEventNames?.filteringEventName) {
      trackAnalyticsEvent(matchingEventNames?.filteringEventName, {
        filterName: 'dateRange'
      })
    }
  }, [matchingEventNames?.filteringEventName, trackAnalyticsEvent])

  const onEquipmentChange = useCallback(
    (mainEquipments?: EquipmentData[]) => {
      setMainEquipments(mainEquipments)
      if (matchingEventNames?.filteringEventName) {
        trackAnalyticsEvent(matchingEventNames?.filteringEventName, {
          filterName: 'mainEquipments'
        })
      }
      navigate(createUrl(getSelectedFilters('mainEquipmentNumbers', mainEquipments), path))
    },
    [
      setMainEquipments,
      matchingEventNames?.filteringEventName,
      navigate,
      getSelectedFilters,
      path,
      trackAnalyticsEvent
    ]
  )

  const onStoppageCodesChange = useCallback(
    (stoppageCodes?: string[]) => {
      setStoppageCodes && setStoppageCodes(stoppageCodes)
      if (matchingEventNames?.filteringEventName) {
        trackAnalyticsEvent(matchingEventNames?.filteringEventName, {
          filterName: 'stoppageCodes'
        })
      }
      navigate(createUrl(getSelectedFilters('stoppageCodes', stoppageCodes), path))
    },
    [
      setStoppageCodes,
      matchingEventNames?.filteringEventName,
      navigate,
      getSelectedFilters,
      path,
      trackAnalyticsEvent
    ]
  )

  const onEquipmentNumbersChange = useCallback(
    (equipmentNumbers?: EquipmentData[]) => {
      setEquipmentNumbers(equipmentNumbers)
      if (matchingEventNames?.filteringEventName) {
        trackAnalyticsEvent(matchingEventNames?.filteringEventName, {
          filterName: 'equipmentNumbers'
        })
      }
      navigate(createUrl(getSelectedFilters('equipmentNumbers', equipmentNumbers), path))
    },
    [
      setEquipmentNumbers,
      matchingEventNames?.filteringEventName,
      navigate,
      getSelectedFilters,
      path,
      trackAnalyticsEvent
    ]
  )

  const clearFilterValue = useCallback(
    (filterName: FilterKeys) => {
      switch (filterName) {
        case 'mainEquipmentNumbers':
          onEquipmentChange(undefined)
          break
        case 'failures':
          setFailureStoppage(undefined)
          break
        case 'stoppageCodes':
          onStoppageCodesChange(undefined)
          break
        case 'equipmentNumbers':
          onEquipmentNumbersChange(undefined)
          break
        case 'stoppageDuration':
          props.stoppageDurationState?.setStoppageDuration(undefined)
          break
      }
    },
    [
      onEquipmentChange,
      onEquipmentNumbersChange,
      setFailureStoppage,
      onStoppageCodesChange,
      props.stoppageDurationState
    ]
  )

  const removeFilter = useCallback(
    (filterName: FilterKeys) => {
      setFilter((prevFilters) => prevFilters.filter((pf) => pf !== filterName))
      clearFilterValue(filterName)
    },
    [clearFilterValue]
  )

  const stoppageCodesMap: StoppageCodesMap<DowntimeStoppageCode> = {
    kiln: config?.kilnStoppageCodes,
    rawMill: config?.rawMillStoppageCodes,
    cementMill: config?.finishMillStoppageCodes,
    other: [{code: 'not_available', type: StoppageKind.Other, description: 'other', priority: -1}]
  }

  return (
    <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
      <DateTimeRangePicker
        onDateRangeChange={onDateTimeRangeChange}
        type="all"
        timezone={config.timezone}
      />
      {visibleFilters.includes('failures') && (
        <FailureFilter
          value={failureStoppage}
          onChange={onFailureChange}
          clearFilter={() => removeFilter('failures')}
        />
      )}
      {visibleFilters.includes('mainEquipmentNumbers') && (
        <EquipmentSearchField
          plantId={plantId}
          value={mainEquipments}
          onChange={onEquipmentChange}
          clearFilter={() => removeFilter('mainEquipmentNumbers')}
          useApiHook={useMainEquipments}
          noEquipmentsSelectedLabelKey={`${performancePrefix}.shiftEvent.label.mainEquipment`}
          selectedEquipmentsLabelKey={`${performancePrefix}.shiftEvent.label.mainEquipmentNumbers`}
        />
      )}
      {visibleFilters.includes('equipmentNumbers') && (
        <EquipmentSearchField
          plantId={plantId}
          value={equipmentNumbers}
          onChange={onEquipmentNumbersChange}
          clearFilter={() => removeFilter('equipmentNumbers')}
          noEquipmentsSelectedLabelKey={`${performancePrefix}.shiftEvent.label.equipment`}
          selectedEquipmentsLabelKey={`${performancePrefix}.shiftEvent.label.equipment`}
          dataTestId="filter-equipment"
          useApiHook={useEquipments}
        />
      )}
      {visibleFilters.includes('stoppageCodes') && (
        <StoppageCodeFilterDropDown
          data-test-id={'events-filter-bar-stoppageCode'}
          onChange={onStoppageCodesChange}
          value={stoppageCodes}
          stoppageKinds={allDowntimeStoppages}
          stoppageCodesMap={stoppageCodesMap}
          isLoading={false}
        />
      )}
      {visibleFilters.includes('stoppageDuration') && stoppageDurationState && (
        <StoppageDurationFilter
          value={stoppageDurationState.stoppageDuration}
          onChange={stoppageDurationState.setStoppageDuration}
          clearFilter={() => removeFilter('stoppageDuration')}
        />
      )}
      <Box sx={{textWrap: 'nowrap'}}>
        <AddFilter<FilterKeys>
          data-test-id={'stoppage-add-filter'}
          options={enabledFilters}
          onChange={(value: string[] = []) => {
            return setFilter((prevFilters) => {
              const intersection = prevFilters.filter((x: string) => !value.includes(x))
              const filterName = intersection[0]
              clearFilterValue(filterName)
              return value
            })
          }}
          values={visibleFilters}
          translationPrefix={performancePrefix}
        />
      </Box>
    </Box>
  )
}
