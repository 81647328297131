import {AllRunningTimeTypes, OtherRunningTimeTypes, RunningTimeType} from '@hconnect/common/types'

export const isKilnRunning = (status: RunningTimeType) => status === RunningTimeType.RUNNING

export const isEquipmentStatusKnown = (status: AllRunningTimeTypes) =>
  status === OtherRunningTimeTypes.UNKNOWN

export const getEquipmentState = (status: AllRunningTimeTypes) => {
  if (isEquipmentStatusKnown(status)) {
    return 'unknown'
  }

  if (isKilnRunning(status as RunningTimeType)) {
    return 'running'
  }

  return 'stopped'
}
