import {
  fetchMainEquipmentByPxTrendTagSuggestion,
  getQueryKeyMainEquipmentByPxTrendTagSuggestion
} from '@hconnect/common/services'
import {
  EquipmentData,
  RunningTimeDto as RunningTime,
  StoppageDto as Stoppage
} from '@hconnect/common/types'
import CloseIcon from '@mui/icons-material/Close'
import {Box, IconButton, Stack, Typography} from '@mui/material'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useQueryClient} from 'react-query'
import {useParams} from 'react-router'

import {useApi} from '../../../shared/hooks/useApi'
import {
  generateStoppageDefaultData,
  DowntimeAnalyticsEventName,
  useTrackDowntimeAnalyticsEvent
} from '../../helpers'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {RunningTimesOffPeriodsWithoutDetail} from '../../types'
import {StoppageCreate} from '../StoppageCreate'

import {AmendStoppagesVariantSwitch, StoppageSwitchVariant} from './AmendStoppagesVariantSwitch'
import {NoMoreStoppages} from './NoMoreStoppages'
import {StoppageListNavigatorTable} from './StoppageListNavigatorTable'

interface StoppagesListNavigatorProps {
  stoppages: RunningTimesOffPeriodsWithoutDetail[]
  onViewChange: (view: StoppageSwitchVariant) => void
  removeStoppage: (stoppage: RunningTimesOffPeriodsWithoutDetail) => void
  isLoading: boolean
}

type PathParameter = {
  plantId: string
}

export const StoppagesListNavigator: React.FC<StoppagesListNavigatorProps> = ({
  stoppages,
  onViewChange,
  removeStoppage,
  isLoading
}) => {
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('Missing plantId prop')

  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const queryClient = useQueryClient()
  const {axiosInstance} = useApi()
  const trackAnalyticsEvent = useTrackDowntimeAnalyticsEvent()

  const [selectedStoppageState, setSelectedStoppageState] =
    useState<RunningTimesOffPeriodsWithoutDetail | null>(null)
  const [stoppageInitialData, setStoppageInitialData] = useState<Partial<Stoppage> | undefined>(
    undefined
  )

  const setInitialData = useCallback(
    async (stoppage: RunningTimesOffPeriodsWithoutDetail) => {
      let suggestedMainEquipment: EquipmentData | undefined = undefined
      try {
        suggestedMainEquipment = await queryClient.fetchQuery({
          queryKey: getQueryKeyMainEquipmentByPxTrendTagSuggestion(plantId, stoppage.equipment.id),
          queryFn: async () =>
            fetchMainEquipmentByPxTrendTagSuggestion({
              plantId,
              pxTrendTag: stoppage.equipment.id,
              axiosInstance
            }),
          staleTime: 5000
        })
      } catch (error) {
        console.error('Error fetching main equipment by px trend tag suggestion', error)
      }

      const runningTime: RunningTime = stoppage.runningTime
      const val = generateStoppageDefaultData({
        runningTime,
        equipment: stoppage.equipment,
        suggestedMainEquipment
      })
      setStoppageInitialData(val)
    },
    [plantId, queryClient, axiosInstance]
  )

  const setSelectedStoppage = useCallback(
    (stoppage: RunningTimesOffPeriodsWithoutDetail | null) => {
      // on selection of new stoppage its necessary to clear init data otherwise previous will linger
      // causing wrong initialization of stoppage form
      setStoppageInitialData(undefined)
      if (stoppage) {
        trackAnalyticsEvent(DowntimeAnalyticsEventName.UserOpenStoppageToAmendForm, {
          equipmentId: stoppage.equipment.id
        })
        void setInitialData(stoppage)
      }
      setSelectedStoppageState(stoppage)
    },
    [setInitialData, trackAnalyticsEvent]
  )

  if (!isLoading && !stoppages.length) {
    return <NoMoreStoppages onClose={() => onViewChange(null)} />
  }

  if (selectedStoppageState && stoppageInitialData) {
    return (
      <Box height="100%" overflow="hidden">
        <StoppageCreate
          key={`${selectedStoppageState.equipment.id}-${selectedStoppageState.runningTime.beginTechnical}`}
          plantId={plantId}
          data-test-id="stoppages-list-navigator"
          doClose={() => setSelectedStoppage(null)}
          initialData={stoppageInitialData}
          onSuccessSubmit={() => {
            trackAnalyticsEvent(DowntimeAnalyticsEventName.UserAmendStoppage, {
              equipmentId: selectedStoppageState.equipment.id
            })
            setSelectedStoppage(null)
            removeStoppage(selectedStoppageState)
          }}
          bodyWithPadding={false}
          onError={(error) => {
            // If stoppage already exists, remove it from the list
            // 409 is the status code for conflict
            if (error.response?.status === 409) {
              removeStoppage(selectedStoppageState)
            }
          }}
          renderFormHeaderActions={({doClose}) => (
            <IconButton
              onClick={() => doClose()}
              color="primary"
              data-test-id="stoppage-form-close"
              size="medium"
            >
              <CloseIcon />
            </IconButton>
          )}
          withSalesImpact={false}
          withPriority
          withCategory
          eventTypeLabelPrefix={selectedStoppageState.equipment.text}
        />
      </Box>
    )
  }

  return (
    <Stack height="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">
          {t(`${performancePrefix}.newStoppagesBanner.label.stoppageList`)}
        </Typography>
        <AmendStoppagesVariantSwitch value="list" onChange={onViewChange} />
      </Stack>
      <Box my={2} height="100%" sx={{overflowY: 'auto'}}>
        <StoppageListNavigatorTable
          stoppages={stoppages}
          setSelectedStoppage={setSelectedStoppage}
        />
      </Box>
    </Stack>
  )
}
