import {useMemo} from 'react'
import {matchPath, useLocation} from 'react-router-dom'

import {FiltersWithDateRangeKeys} from '../components/downtime/filters/filters.models'
import {
  PLANT_DOWNTIME,
  PLANT_DOWNTIME_STOPPAGES,
  PLANT_DOWNTIME_STOPPAGES_DETAILS,
  PLANT_DOWNTIME_STOPPAGES_DETAILS_FOR_CODE
} from '../routing'
import {IssueGroupingKey, IssueSortingKey} from '../types'

export enum DowntimeAnalyticsEventName {
  UserEntersDowntimeDashboard = 'userEntersDowntimeDashboard',
  UserClicksOnRcfa = 'userClicksOnRcfa',
  UserSet1LevelGrouping = 'userSet1LevelGrouping',
  UserSet1LevelSorting = 'userSet1LevelSorting',
  UserClicksOn1LevelStoppagesGraph = 'userClicksOn1LevelStoppagesGraph',
  UserOpenStoppagesToAmendList = 'userOpenStoppagesToAmendList',
  UserOpenStoppageToAmendForm = 'userOpenStoppageToAmendForm',
  UserSkipStoppageWithMissingData = 'userSkipStoppageWithMissingData',
  UserAmendStoppage = 'userAmendStoppage',
  UserSet2LevelGrouping = 'userSet2LevelGrouping',
  UserSet2LevelSorting = 'userSet2LevelSorting',
  UserAdjusts2LevelFilter = 'userAdjusts2LevelFilter',
  UserClicksOn2LevelStoppagesGraphBar = 'userClicksOn2LevelStoppagesGraphBar',
  UserSet3LevelSorting = 'userSet3LevelSorting',
  UserAdjusts3LevelFilter = 'userAdjusts3LevelFilter',
  UserAdjusts4LevelFilter = 'userAdjusts4LevelFilter',
  UserAdjustDescriptionColumn = 'userAdjustDescriptionColumn',
  UserClicksOn3LevelStoppagesGraphBar = 'userClicksOn3LevelStoppagesGraphBar',
  UserGoesBackTo3Level = 'userGoesBackTo3Level',
  UserGoesBackTo2Level = 'userGoesBackTo2Level',
  UserGoesBackTo1Level = 'userGoesBackTo1Level'
}

export type GroupingNames =
  | DowntimeAnalyticsEventName.UserSet2LevelGrouping
  | DowntimeAnalyticsEventName.UserSet1LevelGrouping
export type SortingNames =
  | DowntimeAnalyticsEventName.UserSet3LevelSorting
  | DowntimeAnalyticsEventName.UserSet2LevelSorting
  | DowntimeAnalyticsEventName.UserSet1LevelSorting
export type FilteringNames =
  | DowntimeAnalyticsEventName.UserAdjusts4LevelFilter
  | DowntimeAnalyticsEventName.UserAdjusts3LevelFilter
  | DowntimeAnalyticsEventName.UserAdjusts2LevelFilter
export type NavigateBackNames =
  | DowntimeAnalyticsEventName.UserGoesBackTo3Level
  | DowntimeAnalyticsEventName.UserGoesBackTo2Level
  | DowntimeAnalyticsEventName.UserGoesBackTo1Level
export const SORT_ANALYTICS_EVENT_NAME_MAP: {
  path: string
  sortingEventName: SortingNames
  groupingEventName?: GroupingNames
  filteringEventName?: FilteringNames
  navigateBackEventName?: NavigateBackNames
}[] = [
  {
    path: PLANT_DOWNTIME_STOPPAGES_DETAILS_FOR_CODE,
    sortingEventName: DowntimeAnalyticsEventName.UserSet3LevelSorting,
    filteringEventName: DowntimeAnalyticsEventName.UserAdjusts4LevelFilter,
    navigateBackEventName: DowntimeAnalyticsEventName.UserGoesBackTo3Level
  },
  {
    path: PLANT_DOWNTIME_STOPPAGES_DETAILS,
    sortingEventName: DowntimeAnalyticsEventName.UserSet3LevelSorting,
    filteringEventName: DowntimeAnalyticsEventName.UserAdjusts3LevelFilter,
    navigateBackEventName: DowntimeAnalyticsEventName.UserGoesBackTo2Level
  },
  {
    path: PLANT_DOWNTIME_STOPPAGES,
    sortingEventName: DowntimeAnalyticsEventName.UserSet2LevelSorting,
    groupingEventName: DowntimeAnalyticsEventName.UserSet2LevelGrouping,
    filteringEventName: DowntimeAnalyticsEventName.UserAdjusts2LevelFilter,
    navigateBackEventName: DowntimeAnalyticsEventName.UserGoesBackTo1Level
  },
  {
    path: PLANT_DOWNTIME,
    sortingEventName: DowntimeAnalyticsEventName.UserSet1LevelSorting,
    groupingEventName: DowntimeAnalyticsEventName.UserSet1LevelGrouping
  }
]
export const useGetEventNamesByRoutePath = () => {
  const location = useLocation()
  return useMemo(
    () => SORT_ANALYTICS_EVENT_NAME_MAP.find(({path}) => matchPath(path, location.pathname)),
    [location.pathname]
  )
}

export type DowntimeAnalyticsEvent =
  | {
      name: DowntimeAnalyticsEventName.UserEntersDowntimeDashboard
    }
  | {
      name: DowntimeAnalyticsEventName.UserClicksOnRcfa
    }
  | {
      name: DowntimeAnalyticsEventName.UserSet1LevelGrouping
      data: {group: IssueGroupingKey}
    }
  | {
      name: DowntimeAnalyticsEventName.UserSet1LevelSorting
      data: {sorting: IssueSortingKey}
    }
  | {
      name: DowntimeAnalyticsEventName.UserClicksOn1LevelStoppagesGraph
    }
  | {
      name: DowntimeAnalyticsEventName.UserOpenStoppagesToAmendList
    }
  | {
      name: DowntimeAnalyticsEventName.UserOpenStoppageToAmendForm
      data: {equipmentId: string}
    }
  | {
      name: DowntimeAnalyticsEventName.UserSkipStoppageWithMissingData
      data: {equipmentId: string}
    }
  | {
      name: DowntimeAnalyticsEventName.UserAmendStoppage
      data: {equipmentId: string}
    }
  | {
      name: DowntimeAnalyticsEventName.UserSet2LevelGrouping
      data: {group: IssueGroupingKey}
    }
  | {
      name: DowntimeAnalyticsEventName.UserSet2LevelSorting
      data: {sorting: IssueSortingKey}
    }
  | {
      name: DowntimeAnalyticsEventName.UserAdjusts2LevelFilter
      data: {filterName: FiltersWithDateRangeKeys}
    }
  | {
      name: DowntimeAnalyticsEventName.UserClicksOn2LevelStoppagesGraphBar
      data: {group: IssueGroupingKey; groupId: string}
    }
  | {
      name: DowntimeAnalyticsEventName.UserSet3LevelSorting
      data: {sorting: IssueSortingKey}
    }
  | {
      name: DowntimeAnalyticsEventName.UserAdjusts3LevelFilter
      data: {filterName: FiltersWithDateRangeKeys}
    }
  | {
      name: DowntimeAnalyticsEventName.UserClicksOn3LevelStoppagesGraphBar
      data: {group: IssueGroupingKey; groupId: string}
    }
  | {
      name: DowntimeAnalyticsEventName.UserAdjusts4LevelFilter
      data: {filterName: FiltersWithDateRangeKeys}
    }
  | {
      name: DowntimeAnalyticsEventName.UserAdjustDescriptionColumn
    }
  | {
      name: DowntimeAnalyticsEventName.UserGoesBackTo3Level
    }
  | {
      name: DowntimeAnalyticsEventName.UserGoesBackTo2Level
    }
  | {
      name: DowntimeAnalyticsEventName.UserGoesBackTo1Level
    }
