import {Button, Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'

export const NoMoreStoppages: React.FC<{onClose: () => void}> = ({onClose}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()

  return (
    <Stack
      height="100%"
      spacing={2}
      alignItems="center"
      justifyContent="center"
      data-test-id="thank-you-message"
    >
      <Typography variant="h3">{t(`${performancePrefix}.newStoppagesBanner.thankYou`)}</Typography>
      <Typography>
        {t(`${performancePrefix}.newStoppagesBanner.noMoreStoppagesAtThisTime`)}
      </Typography>
      <div>
        <Button variant="text" onClick={onClose}>
          {t(`${performancePrefix}.button.close`)}
        </Button>
      </div>
    </Stack>
  )
}
