import {Iso8601, ManualKpi, ManualKpiResolution} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {useAxios} from '../../../shared/hooks/useApi'

const QueryKey = 'manual-kpis'

export const useManualKpis = (
  resolution: ManualKpiResolution,
  timestamp: Iso8601,
  plantId: string,
  options?: UseQueryOptions<ManualKpi[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const path = `/shifthandover/${plantId}/kpi/${resolution}/${timestamp}`

  return useQuery<ManualKpi[], AxiosError>(
    [QueryKey, path],
    async () => {
      const response = await axiosInstance.get<ManualKpi[]>(path)
      return response.data
    },
    options
  )
}
