import {
  InvertColors,
  ParkOutlined,
  SpaceBar,
  FeedOutlined,
  LocalFireDepartmentOutlined
} from '@mui/icons-material'
import React, {ReactNode} from 'react'

import {KPIsWithProduction} from '../types'

export const KPI_ICONS_MAP: Record<keyof KPIsWithProduction, ReactNode> = {
  alternativeFuelRate: <InvertColors sx={{fontSize: 'inherit'}} />,
  cementProduction: <InvertColors sx={{fontSize: 'inherit'}} />,
  clinkerProduction: <InvertColors sx={{fontSize: 'inherit'}} />,
  kilnHeatConsumption: <LocalFireDepartmentOutlined sx={{fontSize: 'inherit'}} />,
  kilnReliabilityCoefficient: <FeedOutlined sx={{fontSize: 'inherit'}} />,
  meanTimeBetweenFailure: <SpaceBar sx={{fontSize: 'inherit'}} />,
  kilnOperatingCoefficient: <ParkOutlined sx={{fontSize: 'inherit'}} />,
  technicalCementPowerConsumption: <ParkOutlined sx={{fontSize: 'inherit'}} />
}
