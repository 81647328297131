import {appUrl} from '@hconnect/common/hproduce/config'
import {MAINTAIN_HOST} from '@hconnect/common/hproduce/config/maintain'
import {CardBox, customThemeConstants} from '@hconnect/uikit/src/lib2'
import {ChevronRight} from '@mui/icons-material'
import {Box, Grid, Typography, useTheme, Link, Stack} from '@mui/material'
import {ChartOptions} from 'chart.js'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath} from 'react-router-dom'

import {DowntimeAnalyticsEventName, useTrackDowntimeAnalyticsEvent} from '../helpers'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {AggregatedStoppageRcfaAvailability} from '../types'

import {DoughnutChart} from './charts/DoughnutChart'

const RCFA_EXISTING_COLOR = '#0080D6'
const RCFA_NEEDED_COLOR = customThemeConstants().palette.buttonFillColor

const chartOptions: ChartOptions<'doughnut'> = {
  cutout: '78%',
  events: [],
  responsive: true
}

export interface StoppageListProps {
  rcfaData: AggregatedStoppageRcfaAvailability
  plantId: string
}

export const StoppageList: React.FC<StoppageListProps> = ({rcfaData, plantId}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const theme = useTheme()
  const trackAnalyticsEvent = useTrackDowntimeAnalyticsEvent()

  const chartCenterText = useMemo(() => {
    return {
      id: 'chart_center_text',
      afterDatasetsDraw(chart) {
        const {ctx, data} = chart
        const xBase = chart.getDatasetMeta(0).data[0].x
        const yBase = chart.getDatasetMeta(0).data[0].y
        ctx.save()

        ctx.font = 'normal 300 28px/34 Inter'
        ctx.textAlign = 'center'
        ctx.fillStyle = theme.palette.text.primary
        ctx.textBaseLine = 'middle'
        ctx.fillText(`${data.datasets[0].data[0]}/${data.datasets[0].data[1]}`, xBase, yBase - 12)

        ctx.font = 'normal 400 12px/16 Inter'
        ctx.textAlign = 'center'
        ctx.fillStyle = theme.palette.secondary.main
        ctx.textBaseLine = 'middle'
        ctx.fillText(t(`${performancePrefix}.downtime.label.mandatoryRCFAs`), xBase, yBase + 8)
      }
    }
  }, [t, theme.palette.secondary.main, theme.palette.text.primary, performancePrefix])

  const linkPath = `${appUrl(MAINTAIN_HOST)}${generatePath('/plant/:plantId/rcfa/stoppages', {
    plantId
  })}`

  return (
    <Link
      href={linkPath}
      onClick={() => {
        trackAnalyticsEvent(DowntimeAnalyticsEventName.UserClicksOnRcfa, {})
      }}
      data-test-id="rcfa-card-link-to-maintain"
      sx={(theme) => ({
        textDecoration: 'none',
        '&:hover': {
          '& .card-chevron, .card-name': {
            color: theme.palette.primary.main
          }
        }
      })}
    >
      <CardBox>
        <Box mb={2} display="flex" flexDirection="row">
          <Box flex={1} mt={-0.5}>
            <Typography variant="h3" mb={5}>
              {t(`${performancePrefix}.downtime.label.rcfaCompletion`)}
            </Typography>
          </Box>
          <Box mr={-1} className={'card-chevron'}>
            <ChevronRight />
          </Box>
        </Box>
        <Box height={190} display="flex" justifyContent="center" alignItems="center">
          {rcfaData && (
            <DoughnutChart
              data={[rcfaData.longKilnStoppageRcfaCount, rcfaData.longKilnStoppageCount]}
              backgroundList={[RCFA_NEEDED_COLOR, RCFA_EXISTING_COLOR]}
              pluginsList={[chartCenterText]}
              options={chartOptions}
            />
          )}
        </Box>
        <Grid display="flex" justifyContent="space-between" flexWrap="wrap" marginTop={2}>
          <Grid item flex={1}>
            <RcfaStatisticBlock
              title={t(`${performancePrefix}.downtime.label.missingRCFAs`)}
              value={rcfaData.longKilnStoppageCount - rcfaData.longKilnStoppageRcfaCount}
            />
          </Grid>
          <Grid item flex={1}>
            <RcfaStatisticBlock
              title={t(`${performancePrefix}.downtime.label.optionalRCFAs`)}
              value={rcfaData.stoppageRcfaCount - rcfaData.longKilnStoppageRcfaCount}
            />
          </Grid>
          <Grid item flex={1}>
            <RcfaStatisticBlock
              title={t(`${performancePrefix}.downtime.label.kilnStoppages`)}
              value={rcfaData.kilnStoppageCount}
            />
          </Grid>
        </Grid>
      </CardBox>
    </Link>
  )
}

interface RcfaListItemProps {
  title: string
  value: string | number
}

const RcfaStatisticBlock: React.FC<RcfaListItemProps> = ({title, value}) => {
  const theme = useTheme()
  return (
    <Stack
      direction="column"
      borderRadius="8px"
      border={`1px solid ${theme.palette.grey[200]}`}
      margin={theme.spacing(1)}
      padding={theme.spacing(2)}
      boxShadow={theme.shadows[4]}
    >
      <Typography textAlign="center" variant="h1" mb={1}>
        {value}
      </Typography>
      <Typography textAlign="center" variant="caption">
        {title}
      </Typography>
    </Stack>
  )
}
