import {Box, Typography, useTheme} from '@mui/material'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  ScriptableContext,
  Tooltip
} from 'chart.js'
import {first, last} from 'lodash'
import React, {useMemo, useRef} from 'react'
import {Chart, getElementAtEvent} from 'react-chartjs-2'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  Filler,
  BarController
)

export type ComboChartData<T> = {
  barData: number[]
  lineData: number[]
  barLabel: string
  lineLabel: string
  columns: string[] | string[][]
  dataTestId?: string
  additionalData?: T[]
}

type ComboChartProps<T> = {
  data: ComboChartData<T>
  onClick?: () => void
  onBarClick?: (data?: T) => void
  showPointerBarHover?: (data: T) => boolean
  getAfterTitle?: (column: string) => string
  getTitle?: (column: string) => string
  getStoppageLabel?: (dataIndex: number, rawValue: number) => string
}

const Y_AXIS_TICS = 5
const X_AXIS_OFFSET = 1
const BAR_CHART_COLOR = '#0080D6'

const closestHigherNumberDivisibleByYAxisTics = (x: number) => {
  const remainder = x % Y_AXIS_TICS
  return remainder === 0 ? x + Y_AXIS_TICS : x + (Y_AXIS_TICS - remainder)
}

export const ComboChart = <T extends string>({
  data,
  onClick,
  onBarClick,
  getAfterTitle,
  getTitle,
  getStoppageLabel,
  showPointerBarHover
}: ComboChartProps<T>) => {
  const theme = useTheme()
  const chartRef = useRef()

  const handleBarClick = (event?: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (chartRef?.current) {
      const element = event && getElementAtEvent(chartRef.current, event)
      if (element && element[0]) {
        if (data.additionalData && onBarClick) {
          onBarClick(data.additionalData[element[0].index - 1])
        }
      } else {
        onClick && onClick()
      }
    }
  }
  const comboData = useMemo(() => {
    return {
      labels: ['beforeBar', ...data.columns, 'afterBar'],
      datasets: [
        {
          type: 'line' as const,
          label: data.lineLabel,
          borderColor: theme.palette.secondary.main,
          borderWidth: 2,
          data: [first(data.lineData), ...data.lineData, last(data.lineData)],
          yAxisID: 'yAxis1',
          pointBackgroundColor: theme.palette.common.white,
          pointRadius: 4,
          fill: 'start',
          backgroundColor: (context: ScriptableContext<'line'>) => {
            const ctx = context.chart.ctx
            const gradient = ctx.createLinearGradient(0, 0, 0, 260)
            gradient.addColorStop(0, `${theme.palette.secondary.main}80`)
            gradient.addColorStop(1, `${theme.palette.common.white}00`)
            return gradient
          }
        },
        {
          type: 'bar' as const,
          label: data.barLabel,
          yAxisID: 'yAxis2',
          backgroundColor: BAR_CHART_COLOR,
          data: [0, ...data.barData, 0],
          barThickness: 32
        }
      ]
    }
  }, [
    data.barData,
    data.barLabel,
    data.columns,
    data.lineData,
    data.lineLabel,
    theme.palette.common.white,
    theme.palette.secondary.main
  ])

  const options = useMemo<ChartOptions>(() => {
    const paddingX = parseInt(/\d+/.exec(theme.spacing(3))?.[0] || '0', 10)
    const maxLineItem = Math.max(...data.lineData)
    const maxBarItem = Math.max(...data.barData)
    const ticsCount = Y_AXIS_TICS + 1

    return {
      plugins: {
        title: {
          display: false
        },
        legend: {
          display: false
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            afterTitle: (context) => {
              const column = context[0].label
              return getAfterTitle ? getAfterTitle(column) : ''
            },
            title: (context) => {
              const column = context[0].label
              return getTitle ? getTitle(column) : ''
            },
            ...(getStoppageLabel && {
              label: (context) => getStoppageLabel(context.datasetIndex, Number(context.raw))
            })
          }
        }
      },
      layout: {
        padding: {left: paddingX, right: paddingX}
      },
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: 'index' as const,
        intersect: false
      },
      scales: {
        x: {
          beginAtZero: true,
          min: X_AXIS_OFFSET,
          max: data.columns.length,
          grid: {
            display: false
          }
        },
        yAxis1: {
          beginAtZero: true,
          type: 'linear' as const,
          position: 'right' as const,
          max: closestHigherNumberDivisibleByYAxisTics(maxLineItem),
          ticks: {
            count: ticsCount
          },
          grid: {
            drawOnChartArea: false,
            tickColor: theme.palette.common.white
          }
        },
        yAxis2: {
          beginAtZero: true,
          type: 'linear' as const,
          ticks: {
            count: ticsCount
          },
          position: 'left' as const,
          max: closestHigherNumberDivisibleByYAxisTics(maxBarItem),
          grid: {
            tickColor: theme.palette.common.white
          }
        }
      }
    }
  }, [theme, data.lineData, data.barData, data.columns, getAfterTitle, getTitle, getStoppageLabel])

  return (
    <Box
      display="flex"
      alignItems="center"
      height="100%"
      gap={2}
      position="relative"
      sx={{cursor: onClick ? 'pointer' : 'default'}}
      data-test-id="chart-container"
    >
      <Typography
        variant="caption"
        sx={{
          position: 'absolute',
          pt: 3,
          writingMode: 'vertical-lr',
          whiteSpace: 'nowrap',
          transform: 'rotate(180deg)'
        }}
      >
        <Box
          width={12}
          height={12}
          mb={1}
          borderRadius={50}
          display="inline-flex"
          sx={{
            backgroundColor: BAR_CHART_COLOR
          }}
        />
        {data.barLabel}
      </Typography>
      <Chart
        key={JSON.stringify(data)} // need to rerender the chart to update plugins
        onClick={handleBarClick}
        data-test-id={data.dataTestId}
        type="bar"
        data={comboData}
        options={options}
        plugins={[
          {
            id: 'onBarHover',
            beforeEvent(chart, args) {
              const event = args.event
              if (event.type === 'mousemove') {
                const hoveredElementDimensions = chart.getElementsAtEventForMode(
                  event.native as Event,
                  'nearest',
                  {intersect: true},
                  true
                )
                const columnId =
                  hoveredElementDimensions.length &&
                  data.additionalData?.[hoveredElementDimensions[0].index - 1]
                if (columnId && showPointerBarHover?.(columnId)) {
                  chart.canvas.style.cursor = 'pointer'
                } else {
                  chart.canvas.style.cursor = 'auto'
                }
              } else if (event.type === 'mouseout') {
                chart.canvas.style.cursor = 'auto'
              }
            }
          }
        ]}
        ref={chartRef}
      />
      <Typography
        variant="caption"
        sx={{
          position: 'absolute',
          right: 0,
          pt: 3,
          writingMode: 'vertical-lr',
          whiteSpace: 'nowrap',
          transform: 'rotate(180deg)'
        }}
      >
        <Box width={12} height={12} borderRadius={50} border={1.5} display="inline-flex" mb={1} />
        {data.lineLabel}
      </Typography>
    </Box>
  )
}
