import type {TimeZone} from '@hconnect/uikit'

import {useConfigData} from './useConfig'

export const getSystemTimezone = (): TimeZone => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const usePlantTimezone = (): TimeZone => {
  const config = useConfigData()
  return config?.timezone || getSystemTimezone()
}
