import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../hooks/useTranslationPrefix'

export const DataInfo = () => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  return (
    <Box display="flex" alignItems="center" sx={{opacity: 0.7, p: 1}}>
      <InfoOutlinedIcon sx={{mr: 1}} />
      <Typography variant="body2">{t(`${performancePrefix}.dataInfo.note`)}</Typography>
    </Box>
  )
}
