import {AxiosError} from 'axios'
import React from 'react'

import {NavAccessContext} from '../context/navAccessContext'
import {NavigationAccess} from '../types/navigationAccess.types'

interface State {
  navigationAccess: NavigationAccess
  isLoading: boolean
  error: AxiosError<unknown, any> | null
}

export interface ProviderProps {
  initialState: State
  children?: React.ReactNode
}

// creating the context
const context = React.createContext<NavAccessContext | undefined>(undefined)

// can be used to wrap the <App /> but also to wrap containers within tests
export const NavAccessibilityContextProvider: React.FC<ProviderProps> = ({
  initialState,
  children
}) => {
  const {navigationAccess} = initialState
  const navigationAccessContext = React.useMemo(
    () => new NavAccessContext(navigationAccess),
    [navigationAccess]
  )

  return <context.Provider value={navigationAccessContext}>{children}</context.Provider>
}

// used by consumers
export function useNavAccessContext(): NavAccessContext {
  const ctx = React.useContext(context)
  if (ctx === undefined) {
    throw new Error(
      'useNavAccessContext was used outside the scope of a NavAccessibilityContextProvider!'
    )
  } else {
    return ctx
  }
}
