import {mapRunningTimes} from '@hconnect/common/mappers'
import {EquipmentRunningTimes, EquipmentRunningTimesDto} from '@hconnect/common/types'

import {getSystemTimezone} from '../hooks/useTimezone'

export const mapEquipmentRunningTimes = (
  equipmentRunningTime: EquipmentRunningTimesDto[],
  timezone = getSystemTimezone()
): EquipmentRunningTimes[] =>
  equipmentRunningTime.map((data) => ({
    ...data,
    runningTimes: mapRunningTimes(data.runningTimes, timezone)
  }))
