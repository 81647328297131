import {AxiosError} from 'axios'
import {useMemo} from 'react'
import {UseQueryResult} from 'react-query'

import {KpiQuarterlyData, KpisList} from '../../types'
import {useTimeRange} from '../useTimeRange'

import {useAlternativeFuelRatesQuarterly} from './useAlternativeFuelRates'
import {useCementProductionQuarterly} from './useCementProductionQuarterly'
import {useClinkerProductionQuarterly} from './useClinkerProductionQuarterly'
import {useKilnHeatConsumptionQuarterly} from './useKilnHeatConsumption'
import {useTechnicalCementPowerConsumptionsQuarterly} from './useTechnicalCementPowerConsumption'

const TAB_QUARTERLY_API_MAPPING = {
  [KpisList.CementProduction]: useCementProductionQuarterly,
  [KpisList.ClinkerProduction]: useClinkerProductionQuarterly,
  [KpisList.KilnHeatConsumption]: useKilnHeatConsumptionQuarterly,
  [KpisList.AlternativeFuelRate]: useAlternativeFuelRatesQuarterly,
  [KpisList.TechnicalCementPowerCons]: useTechnicalCementPowerConsumptionsQuarterly
}

type ReturnType = {
  data: KpiQuarterlyData | null
  isLoading: boolean
  error: AxiosError<unknown> | null
  refetch: () => Promise<UseQueryResult<KpiQuarterlyData | null, AxiosError> | null>
}

export const useKpiQuarterlyValue = (
  currentTab: KpisList,
  timezone: string,
  locationId?: string
): ReturnType => {
  const {to} = useTimeRange()
  const useQuarterlyApiHook = useMemo(
    () => TAB_QUARTERLY_API_MAPPING[currentTab] || useCementProductionQuarterly,
    [currentTab]
  )

  const {data, isLoading, error, refetch} = useQuarterlyApiHook(to.toJSON(), timezone, locationId)

  return useMemo(() => ({data, isLoading, error, refetch}), [data, isLoading, error, refetch])
}
