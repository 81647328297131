import {FC} from 'react'

import {ErrorPlaceholder as ErrorPlaceholderBase} from '../../shared/components/ErrorPlaceholder'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'

type ErrorPlaceholderProps = {
  retryFunction?: () => void
  isRefetching?: boolean
}

export const ErrorPlaceholder: FC<ErrorPlaceholderProps> = ({retryFunction, isRefetching}) => {
  const {performancePrefix} = useTranslationPrefix()

  return (
    <ErrorPlaceholderBase
      retryFunction={retryFunction}
      isRefetching={isRefetching}
      translationPrefix={performancePrefix}
    />
  )
}
