export function mergedList<A, B>(key: string, arr1: A[], arr2: B[]) {
  // Create a map to store objects by their keys
  const map = new Map()

  // Add objects from the first array to the map
  arr1.forEach((obj) => map.set(obj[key], obj))

  // Add or merge objects from the second array to the map
  arr2.forEach((obj) => {
    const existingObj = map.get(obj[key])
    if (existingObj) {
      // Merge the objects if the key exists
      Object.assign(existingObj, obj)
    } else {
      // Add the object to the map if the key doesn't exist
      map.set(obj[key], obj)
    }
  })

  // Convert the map values back to an array
  return Array.from(map.values())
}
