import {AxiosError, AxiosInstance} from 'axios'
import moment, {MomentInput} from 'moment-timezone'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {mapRunningTimesOffPeriodsWithoutDetailFlatList} from '../mappers'
import {RunningTimesOffPeriodsWithoutDetail, ThinEquipmentRunningTimes} from '../types'

type Params = {
  from: string
  to: string
}

export const RunningTimesOffPeriodsQueryKey = 'running-times-off-periods-without-detail'

const getBasePath = (plantId: string) =>
  `/downtime-dashboard/${plantId}/running-times/off-periods-without-detail`

export const fetchRunningTimesOffPeriodsWithoutDetail = async ({
  plantId,
  axiosInstance,
  params
}: {
  plantId: string
  axiosInstance: AxiosInstance
  params: Params
}) => {
  const path = getBasePath(plantId)
  const response = await axiosInstance.get<ThinEquipmentRunningTimes[]>(path, {
    params
  })

  return response.data
}

export const useRunningTimesOffPeriodsWithoutDetail = (
  plantId: string,
  params: Params,
  setData?: (data: RunningTimesOffPeriodsWithoutDetail[]) => void,
  options?: UseQueryOptions<
    ThinEquipmentRunningTimes[],
    AxiosError,
    RunningTimesOffPeriodsWithoutDetail[]
  >
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<ThinEquipmentRunningTimes[], AxiosError, RunningTimesOffPeriodsWithoutDetail[]>(
    [RunningTimesOffPeriodsQueryKey, plantId, params.from, params.to],
    async () => fetchRunningTimesOffPeriodsWithoutDetail({plantId, axiosInstance, params}),
    {
      ...options,
      staleTime: 1000 * 60 * 15,
      onError: (err) => handleError(err, navigate),
      select: (data) => {
        const transformedData: RunningTimesOffPeriodsWithoutDetail[] =
          mapRunningTimesOffPeriodsWithoutDetailFlatList(data).sort(
            ({runningTime: {beginTechnical: a}}, {runningTime: {beginTechnical: b}}) =>
              moment(b as MomentInput).diff(a as MomentInput)
          )
        setData?.(transformedData)
        return transformedData
      }
    }
  )
}

export const useRunningTimesOffPeriodsWithoutDetailCount = (
  plantId: string,
  params: Params,
  options?: UseQueryOptions<number, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<number, AxiosError>(
    [`${RunningTimesOffPeriodsQueryKey}/count`, plantId, params.from, params.to],
    async () => {
      const path = `${getBasePath(plantId)}/count`
      const response = await axiosInstance.get<number>(path, {
        params
      })

      return response.data
    },
    {
      staleTime: 1000 * 60 * 15,
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
