import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {createPlantIdMap} from '../mappers'
import {PlantEquipmentProductionVolumes, TimeRange} from '../types'

const QueryKey = 'equipment-production-volumes'
const INTERVAL_PERIOD_IN_MS = 300000

export const useEquipmentProductionVolumes = (
  timeRange: TimeRange,
  hierarchyId?: string,
  options?: UseQueryOptions<
    PlantEquipmentProductionVolumes[],
    AxiosError,
    Record<string, PlantEquipmentProductionVolumes>
  >
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<
    PlantEquipmentProductionVolumes[],
    AxiosError,
    Record<string, PlantEquipmentProductionVolumes>
  >(
    [QueryKey, timeRange.startDate, timeRange.endDate, hierarchyId],
    async () => {
      const response = await axiosInstance.get<PlantEquipmentProductionVolumes[]>(
        '/kpi/dashboard/plants/equipment-production-volumes',
        {
          params: {
            from: timeRange.startDate,
            to: timeRange.endDate,
            ...(hierarchyId && {hierarchyId})
          }
        }
      )

      return response.data
    },
    {
      refetchInterval: INTERVAL_PERIOD_IN_MS,
      refetchOnMount: false,
      onError: (err) => handleError(err, navigate),
      ...options,
      onSuccess: (data) => {
        options?.onSuccess?.(data)
      },
      select: (data) => createPlantIdMap<PlantEquipmentProductionVolumes>(data)
    }
  )
}
