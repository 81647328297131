import {DateRange, useTranslation} from '@hconnect/uikit/src/common'
import {Stack} from '@mui/material'
import React, {useCallback, useMemo} from 'react'
import {generatePath, useNavigate, useLocation, useParams} from 'react-router-dom'

import {generateColumns} from '../helpers'
import {useHierarchyData} from '../hooks/useHierarchy'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {AREA_KPI, LOCATION_KPI, PLANT_PERFORMANCE} from '../routing'
import {
  AggregatedKpiData,
  Comment,
  CommentLocationData,
  HierarchyNodeType,
  KpisList,
  PerformanceDescendantWithId
} from '../types'

import {AcVsPlMobileGrid} from './AcVsPlMobileGrid'

const getPath = (path: string, params: Record<string, string | undefined>, search) =>
  generatePath(path, params) + search

type PathParameter = {
  locationId?: string
}

type MobileLocationCardsProps = {
  data: AggregatedKpiData
  hasQuarterlyData: boolean
  currentTab: KpisList
  dateRange: DateRange<Date>
  setCommentLocationData: (id: CommentLocationData) => void
  comments: Comment[]
}

export const MobileLocationCards = ({
  data,
  hasQuarterlyData,
  currentTab,
  dateRange,
  setCommentLocationData,
  comments
}: MobileLocationCardsProps) => {
  const {t, i18n} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const navigate = useNavigate()
  const {search} = useLocation()
  const {locationId} = useParams<PathParameter>()
  const hierarchy = useHierarchyData()

  const onClick = useCallback(
    (item: PerformanceDescendantWithId<AggregatedKpiData>) => {
      if (
        item.locationData?.type === HierarchyNodeType.Region ||
        item.locationData?.type === HierarchyNodeType.Country
      ) {
        navigate(getPath(LOCATION_KPI, {locationId: item.locationData?.id}, search))
      }
      if (item.locationData?.type === HierarchyNodeType.Area) {
        navigate(getPath(AREA_KPI, {locationId: item.locationData?.id}, search))
      }
      if (
        (!item.locationData?.type && locationId) ||
        item.locationData?.type === HierarchyNodeType.Plant
      ) {
        navigate(getPath(PLANT_PERFORMANCE, {plantId: item.locationData?.id}, search))
      }
    },
    [locationId, navigate, search]
  )

  const columns = useMemo(() => {
    const {historicalData} = data
    return generateColumns({
      translationFn: (key, args = {}) => t(`${performancePrefix}.${key}`, args),
      currentTab,
      offsetYearsAmount: historicalData?.length,
      dateRange,
      locale: i18n.language,
      setCommentLocationData,
      comments,
      hierarchy,
      showQuarterlyData: hasQuarterlyData
    })
  }, [
    t,
    currentTab,
    data,
    dateRange,
    i18n.language,
    setCommentLocationData,
    comments,
    hierarchy,
    performancePrefix,
    hasQuarterlyData
  ])

  return (
    <Stack gap={2}>
      {data.descendants?.map((item: AggregatedKpiData) => (
        <AcVsPlMobileGrid
          data-test-id={`ac-vs-pl-table-row-${item.id}`}
          key={item.id}
          columns={columns}
          item={item}
          onClick={() => onClick(item)}
        />
      ))}
    </Stack>
  )
}
