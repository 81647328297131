import {AxiosError, AxiosInstance} from 'axios'
import {useCallback, useMemo} from 'react'
import {useQueries, UseQueryResult} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {PERFORMANCE_KPI_REQUEST_STALE_TIME} from '../../consts'
import {mapKilnHeatConsumptionsDto} from '../../mappers'
import {
  KilnHeatConsumptionsResponse,
  KpiPfyPayload,
  MtmYtmRequestPayload,
  PlannedYearlyKilnHeatConsumptionsHierarchy
} from '../../types'

import {KpiValue} from './commonKpiApiTypes'
import {
  getKilnHeatConsumption,
  getKilnHeatConsumptionMtmYtmQueryKeys
} from './useKilnHeatConsumption'

const getKilnHeatConsumptionPfy = async (axiosInstance: AxiosInstance, payload?: KpiPfyPayload) => {
  const url = '/kpi/dashboard/kiln-heat-consumptions-pfy'
  const response = await axiosInstance.get<PlannedYearlyKilnHeatConsumptionsHierarchy>(url, {
    params: {...payload}
  })

  return response.data
}

export const useKilnHeatConsumptionData = (
  payload: MtmYtmRequestPayload,
  enabled = true
): KpiValue => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  const queries = useQueries<
    [
      UseQueryResult<PlannedYearlyKilnHeatConsumptionsHierarchy, AxiosError>,
      UseQueryResult<KilnHeatConsumptionsResponse, AxiosError>
    ]
  >([
    {
      queryKey: [
        'kiln-heat-consumptions-pfy',
        {locationId: payload?.locationId, year: payload?.year}
      ],
      queryFn: () =>
        getKilnHeatConsumptionPfy(axiosInstance, {
          locationId: payload?.locationId,
          year: payload?.year
        }),
      enabled,
      staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
      onError: (err: AxiosError) => handleError(err, navigate)
    },
    {
      queryKey: getKilnHeatConsumptionMtmYtmQueryKeys(payload),
      queryFn: () => getKilnHeatConsumption(axiosInstance, payload),
      enabled,
      staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
      onError: (err: AxiosError) => handleError(err, navigate)
    }
  ])

  const refetch = useCallback(() => {
    queries.forEach(({error, refetch: queryRefetch}) => {
      if (error) {
        void queryRefetch()
      }
    })
  }, [queries])

  const [pfyQuery, dataQuery] = queries

  const kilnHeatConsumption = useMemo(() => {
    const pfyData = pfyQuery.data
    const data = dataQuery.data
    return pfyData && data ? mapKilnHeatConsumptionsDto(data, pfyData) : undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(pfyQuery.data), JSON.stringify(dataQuery.data)])

  return {
    data: kilnHeatConsumption,
    isLoading: pfyQuery.isLoading || dataQuery.isLoading,
    error: pfyQuery.error || dataQuery.error,
    refetch
  }
}
