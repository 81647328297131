import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {mapPlantStatusFromDto} from '../mappers'
import {PlantStatus, PlantStatusResponse, TimeRange} from '../types'

const QueryKey = 'plant-status'
const INTERVAL_PERIOD_IN_MS = 300000

export const usePlantStatus = (
  plantId: string,
  dateRange: TimeRange,
  options?: UseQueryOptions<PlantStatusResponse, AxiosError, PlantStatus>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<PlantStatusResponse, AxiosError, PlantStatus>(
    [QueryKey, plantId, dateRange],
    async () => {
      const response = await axiosInstance.post<PlantStatusResponse>(
        `/kpi/dashboard/plants/${plantId}`,
        dateRange
      )

      return response.data
    },
    {
      refetchInterval: INTERVAL_PERIOD_IN_MS,
      onError: (err) => handleError(err, navigate),
      select: (data) => mapPlantStatusFromDto(data),
      ...options
    }
  )
}
