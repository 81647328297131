import plants from '@hconnect/common/hproduce/plants.json'
import {AppName, Env} from '@hconnect/common/hproduce/types'
import {
  generateNotificationChannels,
  Notifications,
  NotificationsOptions
} from '@hconnect/common/notifications'
import {Shell as ShellWrapper, CommonHeader, NavItem} from '@hconnect/uikit/src/lib2'
import {CommonHeaderGuidedTourProps} from '@hconnect/uikit/src/lib2/shell/header/CommonHeader'
import {Plant} from '@hconnect/uikit/src/lib2/shell/PlantSelect'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useLocation, useParams} from 'react-router-dom'

import {NOTIFICATIONS_TRANSLATION_KEYS} from '../consts'
import {useSupportedLanguages} from '../hooks/useSupportedLanguages'
import {getSystemTimezone} from '../hooks/useTimezone'
import {Context} from '../types/temp.types'

import {Footer} from './Footer'

type Props = {
  context: Context
  appName?: AppName
  enableNotifications?: boolean
  children: React.ReactNode
  guidedTours?: CommonHeaderGuidedTourProps
  navItems?: NavItem[]
}

type PathParameter = {
  plantId?: string
}

export const Shell: React.FC<Props> = ({
  appName = 'HProduce',
  enableNotifications,
  context,
  children,
  guidedTours,
  navItems
}) => {
  const {
    i18n: {language}
  } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const supportedLanguages = useSupportedLanguages()
  const {plantId} = useParams<PathParameter>()

  const timeZone = getSystemTimezone()

  const options = useMemo<NotificationsOptions | undefined>(() => {
    if (!enableNotifications) return undefined

    const channels = generateNotificationChannels(context.whiteList)
    return channels ? {channels, countryCode: language.toUpperCase().split('-')[0]} : undefined
  }, [language, context.whiteList, enableNotifications])

  const plant: Plant | undefined = plantId
    ? {
        plantId,
        plantName: plants[plantId]?.name || plantId
      }
    : undefined

  return (
    <ShellWrapper
      isResponsive
      boxed={false}
      header={
        <CommonHeader
          appName={appName}
          onLogout={context.onLogout}
          additionalActions={
            options
              ? [
                  <Notifications
                    key="notification-provider"
                    options={options}
                    translations={NOTIFICATIONS_TRANSLATION_KEYS}
                    timezone={timeZone}
                  />
                ]
              : undefined
          }
          supportedLanguages={supportedLanguages}
          user={context.user}
          navItems={navItems}
          env={process.env.REACT_APP_STAGE as Env}
          whiteList={context.whiteList}
          navigate={(url) => navigate(url)}
          locationPath={location.pathname}
          enableMyTasks
          plant={plant}
          guidedTours={guidedTours}
          closeDropdownOnLanguageSwitch
        />
      }
      footer={<Footer />}
    >
      {children}
    </ShellWrapper>
  )
}
