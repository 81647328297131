import {AppName} from '@hconnect/common/hproduce'

import {TaskSource} from '../types'

const SERVER_APP_NAME_MAP: Record<TaskSource, AppName> = {
  [TaskSource.COCKPIT]: 'Cockpit',
  [TaskSource.EASY_POM]: 'easyPOM'
}

export const mapToAppName = (source: TaskSource): AppName =>
  SERVER_APP_NAME_MAP[source] ?? 'HProduce'
