import {PxTrendMetaData} from './kilnStatus.types'

export enum HierarchyNodeType {
  Global = 'global',
  Area = 'area',
  Region = 'region',
  Country = 'country',
  Plant = 'plant'
}

export type HierarchyNodeMetadata = {
  id: string
  name: string
  countryCode?: string
  path: string // eg global.regionId.countryId
  type: HierarchyNodeType
  children: HierarchyNodeMetadata[]
  pxTrendMetaData?: PxTrendMetaData // Note: present only for type == Plant
}

export type HierarchyNode = Omit<HierarchyNodeMetadata, 'children'> & {
  isAllowed: boolean
  children: HierarchyNode[]
}
