import {EquipmentData} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'

const QueryKey = 'stoppage-main-equipment'

export const useMainEquipments = (
  plantId: string,
  searchQuery?: string,
  options?: UseQueryOptions<EquipmentData[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<EquipmentData[], AxiosError>(
    [QueryKey, plantId, searchQuery],
    async () => {
      const response = await axiosInstance.get<EquipmentData[]>(
        `/downtime-dashboard/${plantId}/stoppages/main-equipments`,
        {
          params: searchQuery
            ? {
                query: searchQuery
              }
            : {}
        }
      )

      return response.data
    },
    {
      staleTime: 1000 * 60 * 15, // 15 mins
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
