export enum PlantStatusAnalyticsEventName {
  UserGoesBackToCountryOverview = 'userGoesBackToCountryOverview',
  UserGoesToDowntimeDashboard = 'userGoesToDowntimeDashboard',
  UserAdjustsDateRangeFilter = 'userAdjustsDateRangeFilter',
  UserGoesToShiftSummary = 'userGoesToShiftSummary',
  UserTogglesAllGeneralKpis = 'userTogglesAllGeneralKpis',
  UserTogglesGeneralKpi = 'userTogglesGeneralKpi',
  UserViewsStoppage = 'userViewsStoppage',
  UserCreatesStoppage = 'userCreatesStoppage',
  userEditsStoppage = 'userEditsStoppage',
  userSplitsStoppage = 'userSplitsStoppage',
  userDeletesStoppage = 'userDeletesStoppage',
  userClicksOnRCFAStatus = 'userClicksOnRCFAStatus',
  userClicksOnEasyPomOverview = 'userClicksOnEasyPomOverview',
  userGoesToStoppageAnalysisPage = 'userGoesToStoppageAnalysisPage',
  userTogglesAllPerformanceKpis = 'userTogglesAllPerformanceKpis',
  userTogglesEquipmentKpi = 'userTogglesEquipmentKpi'
}

export type PlantStatusAnalyticsEvent =
  | {
      name: PlantStatusAnalyticsEventName.UserGoesBackToCountryOverview
    }
  | {
      name: PlantStatusAnalyticsEventName.UserGoesToDowntimeDashboard
    }
  | {
      name: PlantStatusAnalyticsEventName.UserAdjustsDateRangeFilter
    }
  | {
      name: PlantStatusAnalyticsEventName.UserGoesToShiftSummary
    }
  | {
      name: PlantStatusAnalyticsEventName.UserTogglesAllGeneralKpis
    }
  | {
      name: PlantStatusAnalyticsEventName.UserTogglesGeneralKpi
      data: {equipmentId: string}
    }
  | {
      name: PlantStatusAnalyticsEventName.UserViewsStoppage
      data: {stoppageId: string}
    }
  | {
      name: PlantStatusAnalyticsEventName.UserCreatesStoppage
      data: {stoppageId: string}
    }
  | {
      name: PlantStatusAnalyticsEventName.userEditsStoppage
      data: {stoppageId: string}
    }
  | {
      name: PlantStatusAnalyticsEventName.userSplitsStoppage
      data: {stoppageId: string}
    }
  | {
      name: PlantStatusAnalyticsEventName.userDeletesStoppage
      data: {stoppageId: string}
    }
  | {
      name: PlantStatusAnalyticsEventName.userClicksOnRCFAStatus
    }
  | {
      name: PlantStatusAnalyticsEventName.userClicksOnEasyPomOverview
    }
  | {
      name: PlantStatusAnalyticsEventName.userGoesToStoppageAnalysisPage
      data: {equipmentId: string}
    }
  | {
      name: PlantStatusAnalyticsEventName.userTogglesAllPerformanceKpis
    }
  | {
      name: PlantStatusAnalyticsEventName.userTogglesEquipmentKpi
      data: {equipmentId: string}
    }
