import {ConfigContext} from '@hconnect/common/hooks/ConfigContext'
import {Config} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {FeatureFlag} from '../helpers/featureFlagHelpers'

import {useAxios} from './useApi'

const QueryKey = 'config-status'

export const useConfig = (
  plantId?: string,
  language?: string,
  options?: UseQueryOptions<ConfigContext<FeatureFlag> | undefined, AxiosError>
) => {
  const axiosInstance = useAxios()

  return useQuery<ConfigContext<FeatureFlag> | undefined, AxiosError>(
    [QueryKey, plantId, language],
    async () => {
      if (!plantId) {
        return Promise.resolve(undefined)
      }

      const configRequest = await axiosInstance.get<Config<FeatureFlag>>(
        `/shifthandover/${plantId}/config`,
        {
          params: {
            language: language || 'en-US'
          }
        }
      )

      return new ConfigContext(configRequest.data)
    },
    options
  )
}
