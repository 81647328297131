import {DateRangePicker} from '@hconnect/uikit/src/lib2'
import {Theme, useMediaQuery} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getSystemTimezone} from '../../shared/hooks/useTimezone'
import {getQuickSelectionItems} from '../hooks/useTimeRange'
import {useTimeRangeParam} from '../hooks/useTimeRangeParam'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'

type DateTimeRangePickerProps = {
  type: 'performance' | 'status' | 'all'
  disableCustomSelect?: boolean
  onDateRangeChange?: () => void
  timezone?: string
}

export const DateTimeRangePicker: React.FC<DateTimeRangePickerProps> = ({
  type,
  disableCustomSelect,
  onDateRangeChange,
  timezone = getSystemTimezone()
}) => {
  const {t} = useTranslation()
  const [timeRange, setTimeRange] = useTimeRangeParam()
  const {performancePrefix} = useTranslationPrefix()

  const onTimeRangeParamChange = (param: string) => {
    setTimeRange(param)
    onDateRangeChange?.()
  }

  const selectionItems = getQuickSelectionItems(
    (key) => t(`${performancePrefix}.${key}`),
    timezone,
    new Date(),
    type
  )

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <DateRangePicker
      onTimeRangeParamChange={onTimeRangeParamChange}
      timeRangeParam={timeRange}
      singleDateSwitchLabel={t(`${performancePrefix}.quickSelection.pickSingleDate`)}
      selectionItems={selectionItems}
      timezone={timezone}
      disableCustomSelect={disableCustomSelect}
      fullWidth={isMobile}
    />
  )
}
