import React from 'react'
import {useTranslation} from 'react-i18next'

import {PlaceholderWrapper} from '../../shared/components/PlaceholderWrapper'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'

export const NotAvailablePage: React.FC = () => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()

  return (
    <PlaceholderWrapper>
      <span>{t(`${performancePrefix}.unauthorized`)}</span>
    </PlaceholderWrapper>
  )
}
