import {AxiosError} from 'axios'
import qs from 'qs'
import {useQuery, UseQueryOptions} from 'react-query'

import {ApiPath} from '../../shared/consts'
import {useAxios} from '../../shared/hooks/useApi'
import {PaginationResponse, TaskSource, HProduceTask, TaskFilterDto} from '../types'

const TasksQueryKey = 'tasks'

export type TasksParams = {
  limit: number
  pageNumber: number
  assignedToMe: boolean
  sources?: TaskSource[]
  filters?: TaskFilterDto
}

export const useTasks = (
  params: TasksParams,
  options?: UseQueryOptions<
    PaginationResponse<HProduceTask>,
    AxiosError,
    PaginationResponse<HProduceTask>
  >
) => {
  const axiosInstance = useAxios()
  const {limit, pageNumber, sources, assignedToMe, filters} = params
  const offset = pageNumber * limit

  return useQuery<PaginationResponse<HProduceTask>, AxiosError, PaginationResponse<HProduceTask>>({
    queryKey: [TasksQueryKey, limit, offset, sources, assignedToMe, filters],
    queryFn: async () => {
      const response = await axiosInstance.post<PaginationResponse<HProduceTask>>(
        ApiPath.TASKS,
        filters,
        {
          params: {
            offset,
            limit,
            sources,
            includeOnlyAssignedToUser: assignedToMe
          },
          paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})
        }
      )

      return response.data ?? []
    },
    ...options
  })
}
