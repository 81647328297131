import {
  getArrayUrlParam,
  getBooleanUrlParam,
  getUrlParam,
  setOrDeleteArrayParam,
  setOrDeleteBooleanParam,
  setOrDeleteStringParam
} from '../../shared/helpers'
import {TasksPageParamKey} from '../consts'
import {CockpitCategory, TaskSource, TasksPageQueryParams, TaskStatus} from '../types'

import {isCockpitCategory, isTaskSource, isTaskStatus} from './tasks.utils'

export function urlParamsToTasksPageQueryParams(params: URLSearchParams): TasksPageQueryParams {
  const tasksQuickFilters = getTasksQuickFilters(params)
  const tasksPageFilters = getTasksPageFilters(params)

  return {
    ...tasksQuickFilters,
    ...tasksPageFilters
  }
}

function getTasksQuickFilters(params: URLSearchParams): TasksPageQueryParams {
  const assignedToMe = getBooleanUrlParam(params, TasksPageParamKey.ASSIGNED_TO_ME)
  const sources = getArrayUrlParam<TaskSource>(params, TasksPageParamKey.SOURCES)
  const sanitizedSources = sources?.filter(isTaskSource)

  return {
    assignedToMe,
    sources: sanitizedSources
  }
}

function getTasksPageFilters(params: URLSearchParams): TasksPageQueryParams {
  const title = getUrlParam(params, TasksPageParamKey.TITLE)
  const statuses = getArrayUrlParam<TaskStatus>(params, TasksPageParamKey.STATUSES)
  const statusesSanitized = statuses?.filter(isTaskStatus)
  const categories = getArrayUrlParam<CockpitCategory>(params, TasksPageParamKey.CATEGORIES)
  const categoriesSanitized = categories?.filter(isCockpitCategory)
  const priorities = getArrayUrlParam<number>(params, TasksPageParamKey.PRIORITIES, Number)

  return {
    title,
    statuses: statusesSanitized,
    categories: categoriesSanitized,
    priorities
  }
}

export function tasksPageQueryParamsToUrlParams(
  searchParams: TasksPageQueryParams
): URLSearchParams {
  const params = new URLSearchParams()

  setOrDeleteBooleanParam(params, TasksPageParamKey.ASSIGNED_TO_ME, searchParams.assignedToMe)
  setOrDeleteArrayParam(params, TasksPageParamKey.SOURCES, searchParams.sources)
  setOrDeleteStringParam(params, TasksPageParamKey.TITLE, searchParams.title)
  setOrDeleteArrayParam(params, TasksPageParamKey.CATEGORIES, searchParams.categories)
  setOrDeleteArrayParam(params, TasksPageParamKey.STATUSES, searchParams.statuses)
  setOrDeleteArrayParam(params, TasksPageParamKey.PRIORITIES, searchParams.priorities)

  return params
}
