import {useGuidedTours} from '@hconnect/uikit/src/lib2'
import React from 'react'

import {Shell} from '../../shared/components/Shell'
import {useGetLinks} from '../../shared/routes/navLinks'
import {Context} from '../../shared/types/temp.types'
import {useGlobalContext} from '../hooks/useGlobalContext'

type Props = {
  context: Context
  children: React.ReactNode
}

export const DefaultShell: React.FC<Props> = ({context, children}) => {
  const {permissions} = useGlobalContext()
  const navItems = useGetLinks({permissions})
  const {tours, tourStatuses} = useGuidedTours()

  return (
    <Shell context={context} guidedTours={{tours, statuses: tourStatuses}} navItems={navItems}>
      {children}
    </Shell>
  )
}
