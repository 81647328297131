import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {AggregatedStoppageRcfaAvailability, TimeRange} from '../types'

const QueryKey = 'rcfa-availability'

export const useRcfaAvailability = (
  plantId: string,
  dateRange: TimeRange,
  options?: UseQueryOptions<AggregatedStoppageRcfaAvailability, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<AggregatedStoppageRcfaAvailability, AxiosError>(
    [QueryKey, plantId, dateRange],
    async () => {
      const response = await axiosInstance.get<AggregatedStoppageRcfaAvailability>(
        `/downtime-dashboard/${plantId}/stoppages/rcfa-availability`,
        {params: {from: dateRange.startDate, to: dateRange.endDate}}
      )

      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
