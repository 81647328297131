import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {getSystemTimezone} from '../../../shared/hooks/useTimezone'
import {PERFORMANCE_KPI_REQUEST_STALE_TIME} from '../../consts'
import {formatDateUtcToTimezoneDateOnly} from '../../helpers/dateFormat.helpers'
import {mapProductionPerformanceValuesDto} from '../../mappers'
import {
  ProductionPerformanceValues,
  ProductionPerformanceValuesResponse,
  DateRangeWithOffset,
  ProductionRequestPayload
} from '../../types'

const QueryKey = 'production-performance-values'

export const useProductionPerformanceValues = (
  {timeRange, locationId, yearOffset = 3}: ProductionRequestPayload,
  options?: UseQueryOptions<
    ProductionPerformanceValuesResponse,
    AxiosError,
    ProductionPerformanceValues
  >
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  const localDateOnlyRange = {
    startDate: formatDateUtcToTimezoneDateOnly(timeRange.startDate, getSystemTimezone()),
    endDate: formatDateUtcToTimezoneDateOnly(timeRange.endDate, getSystemTimezone())
  }

  return useQuery<ProductionPerformanceValuesResponse, AxiosError, ProductionPerformanceValues>(
    [QueryKey, localDateOnlyRange.startDate, localDateOnlyRange.endDate, locationId],
    async () => {
      const payload: DateRangeWithOffset = {
        dateRange: localDateOnlyRange,
        yearOffset
      }

      const response = await axiosInstance.post<ProductionPerformanceValuesResponse>(
        '/kpi/dashboard/production-performance-values/',
        payload,
        {params: {locationId}}
      )

      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      select: (data) => mapProductionPerformanceValuesDto(data),
      staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
      ...options
    }
  )
}
