import {TextField} from '@mui/material'
import {debounce, noop} from 'lodash'
import React, {useState, useEffect} from 'react'
import {usePrevious} from 'react-use'

import {SEARCH_DELAY_IN_MS} from '../../consts'

import {textFieldOnBlueSx} from './filterStyles'

type FilterTextFieldProps<TName extends string> = {
  name: TName
  label: string
  value?: string
  onChange?: (name: TName, value?: string) => void
  'data-test-id'?: string
}

export const FilterTextField = <TName extends string>({
  name,
  value,
  onChange = noop,
  label,
  'data-test-id': dataTestId
}: FilterTextFieldProps<TName>) => {
  const [text, setText] = useState(value)
  const prevText = usePrevious(text)

  useEffect(() => {
    if (text !== prevText) {
      const onDebounced = debounce(onChange, SEARCH_DELAY_IN_MS)
      onDebounced(name, text)
      return () => onDebounced.cancel()
    }
  }, [onChange, prevText, text, name])

  return (
    <TextField
      name={name}
      label={label}
      value={text}
      onChange={(event) => setText(event.target.value)}
      variant="filled"
      sx={textFieldOnBlueSx}
      data-test-id={dataTestId ?? `${name}-filter`}
    />
  )
}
