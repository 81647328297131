import {KpiPerformanceDto, KpiPerformance, KpiAssignmentDto} from '@hconnect/common/types'
import moment from 'moment-timezone'

import {getSystemTimezone} from '../../shared/hooks/useTimezone'

export const mapKpiPerformance = (
  kpiPerformance: KpiPerformanceDto,
  timezone = getSystemTimezone()
): KpiPerformance => ({
  kpiAssignments: kpiPerformance.kpiAssignments.map((kpiAssignment: KpiAssignmentDto) => ({
    ...kpiAssignment,
    result: {
      ...kpiAssignment.result,
      startTime: moment.utc(kpiAssignment.result.startTime).tz(timezone),
      endTime: moment.utc(kpiAssignment.result.endTime).tz(timezone)
    }
  }))
})
