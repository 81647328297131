import {ManualKpiPreview} from '@hconnect/common/components/kpiPerformance/ManualKpiPreview'
import {ExpandedGroupIds} from '@hconnect/common/hooks/useExpandGroups'
import {
  ManualKpiResolution,
  ManualKpisProcessStage,
  ManualKpiToUpdate
} from '@hconnect/common/types'
import {generateKpi, generateKpisToUpdate} from '@hconnect/common/utils'
import {isEmpty} from 'lodash'
import React, {FC, useState} from 'react'

import {useKpiSuggestions} from '../../hooks/kpi/useKpiSuggestions'
import {useManualKpis} from '../../hooks/kpi/useManualKpis'
import {TimeRange} from '../../types'

type ManualKpisViewProps = {
  plantId: string
  timeRange: TimeRange
  performancePrefix: string
  openProcessStages: string[]
  setOpenProcessStages: (id: ExpandedGroupIds) => void
  processStages: ManualKpisProcessStage[]
  isProcessStagesLoading: boolean
}

export const ManualKpisView: FC<ManualKpisViewProps> = ({
  plantId,
  isProcessStagesLoading,
  openProcessStages,
  setOpenProcessStages,
  processStages,
  timeRange,
  performancePrefix
}) => {
  const [manualKpiList, setManualKpiList] = useState<ManualKpiToUpdate[]>([])

  const {data: kpis, isLoading: isKpisLoading} = useManualKpis(
    ManualKpiResolution.Daily,
    timeRange.endDate,
    plantId,
    {
      onSuccess: (data) => {
        if (!isEmpty(data)) {
          setManualKpiList(generateKpisToUpdate(data))
        }
      }
    }
  )
  useKpiSuggestions(plantId, {
    enabled: !isKpisLoading && isEmpty(kpis),
    onSuccess: (data) => {
      if (!isKpisLoading && isEmpty(kpis)) {
        const kpiSuggestions = data.map((kpi) => generateKpi(kpi))
        setManualKpiList(kpiSuggestions)
      }
    }
  })

  return (
    <ManualKpiPreview
      manualKpiList={manualKpiList}
      isProcessStagesLoading={isProcessStagesLoading}
      openProcessStages={openProcessStages}
      setOpenProcessStages={setOpenProcessStages}
      processStages={processStages}
      translationPrefix={performancePrefix}
    />
  )
}
