import {ContentBox} from '@hconnect/common/components/ContentBox'
import {DateTimeFormat} from '@hconnect/common/components/dateTime/DateTimeFormat'
import {EventStatusLabel} from '@hconnect/common/components/eventProperties/EventStatusLabel'
import {Tag} from '@hconnect/common/components/Tag'
import {EventAppInfo} from '@hconnect/common/types'
import {
  generateMarkdownOptions,
  getHighlightedTitle,
  getHighlightedTitleWithLink
} from '@hconnect/common/utils/highlightHelpers'
import {getEventRoute} from '@hconnect/common/utils/route.utils'
import {getTranslationKey} from '@hconnect/common/utils/translation.utils'
import {AppLogo, DateFormat, GridSetting} from '@hconnect/uikit'
import {MarkdownText} from '@hconnect/uikit/src/lib2'
import CloseIcon from '@mui/icons-material/Close'
import {Typography, Grid, Box, Paper, Link} from '@mui/material'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {mapToAppName} from '../mappers'
import {HProduceTask} from '../types'

export type Props = {
  item: HProduceTask
  doClose: () => void
  freeSearchText?: string
  timezone: string
  translationPrefix?: string
  children?: React.ReactNode
}

const regularSize: GridSetting = {
  xs: 12,
  sm: 4,
  md: 6
}

const DueDateLabel: React.FC<{item: HProduceTask; timezone: string}> = ({item, timezone}) => {
  return <DateFormat date={item.dueDate} timezone={timezone} />
}

const getEventTile = (title: string, freeSearchText?: string) =>
  freeSearchText ? getHighlightedTitle(title, freeSearchText) : title

const Info = ({
  label,
  date,
  timezone,
  'data-test-id': dataTestId,
  translationFunction: translationFuncProps
}) => {
  const {t} = useTranslation()

  const translationFunction = translationFuncProps || t

  return (
    <Typography component={'p'} gutterBottom variant="caption" data-test-id={dataTestId}>
      {label} {translationFunction('eventsTable.happenOn')}{' '}
      <DateTimeFormat date={date} timezone={timezone} />
    </Typography>
  )
}

const AppInfo = ({
  app,
  externalId,
  plantId,
  translationPrefix
}: EventAppInfo & {translationPrefix?: string}) => {
  const {t} = useTranslation()

  const url = useMemo(() => getEventRoute({app, externalId, plantId}), [app, externalId, plantId])

  return (
    <Paper
      elevation={2}
      component="div"
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 2,
        borderLeft: 6,
        borderLeftColor: theme.palette.secondary.main
      })}
    >
      <Typography component={'span'} variant="body1">
        {t(getTranslationKey('createdInApp', translationPrefix), {appName: app})}
      </Typography>

      <Link
        underline="hover"
        onClick={() => {
          window.location.replace(url)
        }}
      >
        <Typography sx={{display: 'flex', alignItems: 'center', gap: 1}}>
          <AppLogo name={app} />
          {t(getTranslationKey('openInApp', translationPrefix), {appName: app})}
        </Typography>
      </Link>
    </Paper>
  )
}

export const TaskDetails: React.FC<Props> = (props) => {
  const {t} = useTranslation()
  const {item, doClose, freeSearchText, timezone, translationPrefix, children} = props

  const translationFunction = useCallback(
    (key: string) => t(getTranslationKey(key, translationPrefix)),
    [t, translationPrefix]
  )

  return (
    <ContentBox
      data-test-id="task-details"
      mode="max100PercentOfParentHeight"
      bodyWithPadding
      aboveTitle={
        <AppInfo
          app={mapToAppName(item.source)}
          externalId={item.externalId}
          plantId={item.plantId}
          translationPrefix={translationPrefix}
        />
      }
      title={
        <Typography
          component={'span'}
          variant={'h2'}
          sx={{textTransform: 'none'}}
          data-test-id="event-detail-title"
          display="flex"
          alignItems="center"
        >
          {getEventTile(item.title, freeSearchText)}
        </Typography>
      }
      afterTitle={
        <Box display="flex" alignItems="center">
          <Typography
            sx={{
              p: 1.75,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
          >
            <CloseIcon
              onClick={() => doClose()}
              color="primary"
              data-test-id="events-details-close"
            />
          </Typography>
        </Box>
      }
      headerSx={{paddingBottom: 0}}
    >
      <Info
        label={translationFunction('eventsTable.created')}
        date={item.created}
        timezone={timezone}
        data-test-id="created-on"
        translationFunction={translationFunction}
      />

      <Grid
        container
        sx={{
          my: 1.5
        }}
        spacing={2}
      >
        <Grid item {...regularSize}>
          <Tag
            labelKey="shiftEvent.label.status"
            translationPrefix={translationPrefix}
            data-test-id="event-detail-status"
          >
            <EventStatusLabel status={item.status} t={translationFunction} />
          </Tag>
        </Grid>
        <Grid item {...regularSize}>
          <Tag
            labelKey="shiftEvent.label.dueDate"
            data-test-id="event-detail-dueDate"
            translationPrefix={translationPrefix}
          >
            <DueDateLabel item={item} timezone={timezone} />
          </Tag>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          my: 1.5
        }}
        spacing={2}
      >
        <Grid item xs={12}>
          <Tag
            labelKey="shiftEvent.label.description"
            translationPrefix={translationPrefix}
            descriptionSx={{fontWeight: 400, fontSize: 14}}
            data-test-id="event-detail-description"
          >
            {item.description && (
              <MarkdownText options={generateMarkdownOptions(freeSearchText)}>
                {freeSearchText
                  ? getHighlightedTitleWithLink(item.description, freeSearchText, true)
                  : item.description}
              </MarkdownText>
            )}
          </Tag>
        </Grid>
      </Grid>
      {children}
    </ContentBox>
  )
}
