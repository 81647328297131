import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box, Typography, useTheme} from '@mui/material'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartData,
  Legend,
  ChartOptions,
  ChartDataset
} from 'chart.js'
import {isNumber, orderBy} from 'lodash'
import {FC, useMemo} from 'react'
import {Bar} from 'react-chartjs-2'
import {useTranslation} from 'react-i18next'

import {useFailureTypes} from '../../hooks/useFailureTypes'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {TimeRange} from '../../types'
import {DataContentWrapper} from '../DataContentWrapper'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

type StoppagesFailureChartProps = {
  plantId: string
  from: Date
  to: Date
}

export const StoppagesFailureChart: FC<StoppagesFailureChartProps> = ({plantId, from, to}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const {performancePrefix} = useTranslationPrefix()

  const timeRange: TimeRange = {startDate: from.toJSON(), endDate: to.toJSON()}
  const {data: serverData, error, isLoading, refetch} = useFailureTypes(plantId, timeRange)

  const data = useMemo<ChartData<'bar'>>(() => {
    const datasets: ChartDataset<'bar'>[] = [
      {
        label: 'Circumstance',
        data: [],
        backgroundColor: '#8C3CC9',
        barThickness: 32
      },
      {
        label: 'Incident',
        data: [],
        backgroundColor: '#009D93',
        barThickness: 32
      },
      {
        label: 'Scheduled',
        data: [],
        backgroundColor: '#D15C02',
        barThickness: 32
      }
    ]
    const columns: number[] = []
    orderBy(serverData?.failureTypes, 'year').forEach((failureType) => {
      columns.push(failureType.year)
      datasets[0].data.push(failureType.failureTypeCircumstanceNumber)
      datasets[1].data.push(failureType.failureTypeIncidentNumber)
      datasets[2].data.push(failureType.failureTypePlannedNumber)
    })

    return {
      labels: columns,
      datasets
    }
  }, [serverData?.failureTypes])

  const options = useMemo<ChartOptions<'bar'>>(
    () => ({
      plugins: {
        title: {
          display: false
        },
        legend: {
          position: 'bottom',
          labels: {
            padding: 21,
            usePointStyle: true,
            boxHeight: 10
          }
        },
        tooltip: {
          displayColors: false
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false
          }
        },
        y: {
          stacked: true,
          grid: {
            tickColor: theme.palette.common.white
          },
          ticks: {
            callback: (value) => {
              if (!isNumber(value) || value === 0) {
                return value
              }
              if (value >= 1000000) {
                return value / 1000000 + 'M'
              }
              if (value >= 1000) {
                return value / 1000 + 'K'
              }
              return value
            }
          }
        }
      }
    }),
    [theme.palette.common.white]
  )

  const renderContent = () =>
    data && <Bar options={options} data={data} data-test-id={'stoppages-failure-chart'} />

  return (
    <CardBox height="100%" display="flex" flexDirection="column">
      <Typography variant="h3" mb={5}>
        {t(`${performancePrefix}.downtime.label.stoppagesFailureHeader`)}
      </Typography>
      <Box flex={1}>
        <DataContentWrapper
          isLoading={isLoading}
          data={serverData?.failureTypes}
          retryFunction={() => void refetch()}
          error={error}
          renderContent={renderContent}
          progressSx={{color: 'primary.main'}}
        />
      </Box>
    </CardBox>
  )
}
