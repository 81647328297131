import moment from 'moment-timezone'

import {DATE_ONLY_REQUEST_FORMAT} from '../consts'

/**
 * @description Formats a UTC timestamp to a date in the given timezone.
 * @param timestamp in UTC, eg: 2021-09-01T00:00:00Z
 * @param timezone eg: 'Europe/Berlin'
 * @returns formatted date in timezone, eg: 2021-09-01
 */
export const formatDateUtcToTimezoneDateOnly = (timestamp: string, timezone: string) => {
  return moment.utc(timestamp).tz(timezone).format(DATE_ONLY_REQUEST_FORMAT)
}
