import {Tab, Tabs, Typography} from '@mui/material'
import {camelCase} from 'lodash'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {KpisList} from '../types'

export const KpiTabs: React.FC<{
  currentTab: KpisList
  setCurrentTab: (index: KpisList) => void
}> = ({currentTab, setCurrentTab}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const tabList = useMemo<KpisList[]>(() => Object.values(KpisList), [])

  return (
    <Tabs
      value={currentTab}
      aria-label=""
      data-test-id="ac-vs-pl-tabs"
      variant="scrollable"
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          paddingBottom: 1,
          '& .MuiTabs-indicator': {
            color: theme.palette.common.white,
            borderBottom: `2px solid ${theme.palette.common.white}`
          },
          '& .MuiTab-root.Mui-selected': {
            color: theme.palette.common.white
          },
          '& .MuiTypography-root': {
            color: theme.palette.common.white
          }
        }
      })}
    >
      {tabList.map((item) => (
        <Tab
          label={
            <Typography>
              {t(`${performancePrefix}.actualVsPlanned.label.${camelCase(String(item))}`)}
            </Typography>
          }
          key={item}
          value={item}
          onClick={() => setCurrentTab(item)}
          data-test-id={`ac-vs-pl-${item}`}
        />
      ))}
    </Tabs>
  )
}
