import {formatNumber} from '@hconnect/common/utils'
import {DateRange} from '@hconnect/uikit'
import {Column, Flag} from '@hconnect/uikit/src/lib2'
import ModeCommentIcon from '@mui/icons-material/ModeComment'
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined'
import {Box, IconButton, Typography} from '@mui/material'
import {capitalize} from 'lodash'
import moment from 'moment-timezone'
import React from 'react'

import {BarChartData} from '../components/charts'
import {DeltaLabel, getDeltaBarColor, kpiDeltaReversedColorMap} from '../components/common'
import {ActualKpiTemplates} from '../components/common/ActualPlannedDelta'
import {
  PlantKPIs,
  ActualAndPlannedData,
  AlternativeFuelRates,
  MeanTimeBetweenFailures,
  KilnCoefficients,
  KilnHeatConsumptions,
  TechnicalCementPowerConsumption,
  KpiDataGeneratorData,
  KpiDataGeneratorError,
  KpisList,
  Comment,
  CommentLocationData,
  HierarchyNode,
  AggregatedKpiData,
  KpiQuarterlyData,
  HierarchyNodeType
} from '../types'

import {filterCommentsByLocation} from './comments.helpers'

const FRACTION_DIGITS = 0

export const tabIndexFiltered = [KpisList.ClinkerProduction, KpisList.CementProduction]

export const TabIndexDataMap: Record<
  KpisList,
  {
    id: KpisList
    planned: string
    actual: string
    delta: string
    plannedYearly: string
    quarterlyForecast: string
  }
> = {
  [KpisList.ClinkerProduction]: {
    id: KpisList.ClinkerProduction,
    planned: 'plannedClinkerProduction',
    actual: 'actualClinkerProduction',
    delta: 'clinkerDelta',
    plannedYearly: 'plannedYearlyClinkerProductionVolume',
    quarterlyForecast: 'quarterlyForecastClinkerProduction'
  },
  [KpisList.CementProduction]: {
    id: KpisList.CementProduction,
    planned: 'plannedCementProduction',
    actual: 'actualCementProduction',
    delta: 'cementDelta',
    plannedYearly: 'plannedYearlyCementProductionVolume',
    quarterlyForecast: 'quarterlyForecastCementProduction'
  },
  [KpisList.KilnReliabilityCoefficient]: {
    id: KpisList.KilnReliabilityCoefficient,
    planned: 'plannedReliabilityCoefficient',
    actual: 'actualReliabilityCoefficient',
    delta: 'reliabilityDelta',
    plannedYearly: 'plannedYearlyReliabilityCoefficient',
    quarterlyForecast: 'quarterlyForecastReliabilityCoefficient'
  },
  [KpisList.KilnOperatingCoefficient]: {
    id: KpisList.KilnOperatingCoefficient,
    planned: 'plannedOperatingCoefficient',
    actual: 'actualOperatingCoefficient',
    delta: 'operatingDelta',
    plannedYearly: 'plannedYearlyOperatingCoefficient',
    quarterlyForecast: 'quarterlyForecastOperatingCoefficient'
  },
  [KpisList.KilnHeatConsumption]: {
    id: KpisList.KilnHeatConsumption,
    planned: 'plannedKilnHeatConsumptions',
    actual: 'actualKilnHeatConsumptions',
    delta: 'delta',
    plannedYearly: 'plannedYearlyKilnHeatConsumptions',
    quarterlyForecast: 'quarterlyForecastKilnHeatConsumptions'
  },
  [KpisList.AlternativeFuelRate]: {
    id: KpisList.AlternativeFuelRate,
    planned: 'plannedAlternativeFuelRates',
    actual: 'actualAlternativeFuelRates',
    delta: 'delta',
    plannedYearly: 'plannedYearlyProductionVolume',
    quarterlyForecast: 'quarterlyForecastAlternativeFuelRates'
  },
  [KpisList.MTBF]: {
    id: KpisList.MTBF,
    planned: 'plannedMeanTimeBetweenFailures',
    actual: 'actualMeanTimeBetweenFailures',
    delta: 'delta',
    plannedYearly: 'plannedYearlyProductionVolume',
    quarterlyForecast: 'quarterlyForecastMeanTimeBetweenFailures'
  },
  [KpisList.TechnicalCementPowerCons]: {
    id: KpisList.TechnicalCementPowerCons,
    planned: 'plannedTechnicalCementPowerConsumptions',
    actual: 'actualTechnicalCementPowerConsumptions',
    delta: 'delta',
    plannedYearly: 'plannedYearlyTechnicalCementPowerConsumptions',
    quarterlyForecast: 'quarterlyForecastTechnicalCementPowerConsumptions'
  }
}

export const getComparisonDate = (
  {from, to}: DateRange<Date>,
  locale: string,
  offsetYear?: number
) => {
  const year = offsetYear
    ? moment(to).subtract(offsetYear, 'year').format("'YY")
    : moment(to).format("'YY")

  const fromMonth = moment(from).locale(locale).format('MMM')
  const toMonth = moment(to).locale(locale).format('MMM')
  const month =
    fromMonth === toMonth
      ? capitalize(toMonth)
      : `${capitalize(fromMonth)} - ${capitalize(toMonth)}`

  return `${month} ${year}`
}

export const getQuarterlyLabel = (month: number) => {
  const quarterLabelMapping = {
    0: null,
    1: 'FA',
    2: 'FJ',
    3: 'FO'
  }
  const quarter = Math.floor(month / 3)
  return quarterLabelMapping[quarter]
}

export const generateColumns = ({
  translationFn,
  currentTab,
  offsetYearsAmount,
  dateRange,
  locale,
  setCommentLocationData,
  comments,
  hierarchy,
  showQuarterlyData
}: {
  translationFn: (key: string, inputParams?: {[key: string]: string}) => string
  currentTab: KpisList
  offsetYearsAmount: number
  dateRange: DateRange<Date>
  locale: string
  setCommentLocationData: (id: CommentLocationData) => void
  comments: Comment[]
  hierarchy?: HierarchyNode
  showQuarterlyData: boolean
}): Column<AggregatedKpiData>[] => {
  const baseColumns: Column<AggregatedKpiData>[] = [
    {
      key: 'plant',
      label: translationFn('actualVsPlanned.label.plant'),
      customTemplate: ({locationData}) => (
        <>
          {locationData?.countryCode && (
            <Flag
              countryCode={locationData.countryCode}
              alt={locationData.countryCode}
              height={14}
              sx={{
                verticalAlign: 'inherit',
                mr: 1
              }}
            />
          )}
          {locationData?.name}
        </>
      )
    }
  ]

  Array.from({length: offsetYearsAmount}, (_, i) => offsetYearsAmount - i).forEach((index) => {
    baseColumns.push({
      key: `planned${index}`,
      label: translationFn('actualVsPlanned.label.actualDate', {
        date: getComparisonDate(dateRange, locale, index)
      }),
      customTemplate: ({historicalData, unit}) => {
        const actual: number = historicalData.find(
          (historicalItem) => historicalItem.yearOffset && historicalItem.yearOffset === index
        )?.[TabIndexDataMap[currentTab].actual]
        return (
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.text.disabled
            }}
          >
            {actual ? `${formatNumber(actual, locale)} ${unit}` : '-'}
          </Typography>
        )
      }
    })
  })

  baseColumns.push({
    key: 'plannedCurrentValue',
    label: (
      <Typography variant="caption" sx={{fontWeight: 'bold'}}>
        {translationFn('actualVsPlanned.label.plannedDate', {
          date: getComparisonDate(dateRange, locale)
        })}
      </Typography>
    ),
    customTemplate: (data) => {
      const planned = data[TabIndexDataMap[currentTab].planned] as number
      return planned === 0 ? '-' : `${formatNumber(planned, locale)} ${data.unit}`
    }
  })

  baseColumns.push({
    key: 'actualCurrentValue',
    label: (
      <Typography variant="caption" sx={{fontWeight: 'bold'}}>
        {translationFn('actualVsPlanned.label.actualDate', {
          date: getComparisonDate(dateRange, locale)
        })}
      </Typography>
    ),
    customTemplate: (data) => {
      const actual = data[TabIndexDataMap[currentTab].actual] as number
      const planned = data[TabIndexDataMap[currentTab].planned] as number
      const id = TabIndexDataMap[currentTab].id

      const result = actual === 0 ? '-' : `${formatNumber(actual, locale)} ${data.unit}`
      const Template = ActualKpiTemplates[id]
      return <Template actual={result} diff={actual - planned} />
    }
  })
  baseColumns.push({
    key: 'delta',
    label: 'Delta',
    customTemplate: (data) => {
      const id = TabIndexDataMap[currentTab].id
      const reverseColor = kpiDeltaReversedColorMap[id]
      return (
        <DeltaLabel value={data[TabIndexDataMap[currentTab].delta]} reverseColor={reverseColor} />
      )
    }
  })

  baseColumns.push({
    key: 'plannedYearly',
    label: translationFn('actualVsPlanned.label.plannedDate', {
      date: capitalize(moment().month(11).locale(locale).format("MMM 'YY"))
    }),
    customTemplate: (data) => {
      const planned = (data[TabIndexDataMap[currentTab].plannedYearly] || 0) as number
      return planned === 0 ? '-' : `${formatNumber(planned, locale)} ${data.unit}`
    }
  })

  if (showQuarterlyData) {
    const quarterlyLabel = getQuarterlyLabel(moment(dateRange.to).month())
    if (quarterlyLabel) {
      const year = moment(dateRange.to).locale(locale).format('YYYY')
      baseColumns.push({
        key: 'quarterlyForecast',
        label: `${quarterlyLabel} ${year}`,
        customTemplate: (data) => {
          const quarterlyForecastValue = data.quarterlyForecast.quarterlyForecastValue || 0
          return quarterlyForecastValue === 0
            ? '-'
            : `${formatNumber(quarterlyForecastValue, locale)} ${data.unit}`
        }
      })

      baseColumns.push({
        key: 'quarterlyForecastDelta',
        label: translationFn('actualVsPlanned.label.quarterlyDelta', {
          quarter: quarterlyLabel,
          year
        }),
        customTemplate: (data) => {
          const reverseColor = kpiDeltaReversedColorMap[TabIndexDataMap[currentTab].id]
          const quarterlyDelta = data.quarterlyForecast.delta || 0
          return <DeltaLabel value={quarterlyDelta} reverseColor={reverseColor} />
        }
      })
    }
  }

  baseColumns.push({
    key: 'comments',
    customTemplate: (data) => {
      const commentsCount =
        hierarchy &&
        filterCommentsByLocation(
          comments,
          hierarchy,
          data.locationData?.id || HierarchyNodeType.Global
        ).length
      const delta = data[TabIndexDataMap[currentTab].delta]
      if (!delta) return <></>
      return (
        <Box display="flex" alignItems="center">
          <IconButton
            color="primary"
            onClick={
              data.locationData
                ? (event) => {
                    event.stopPropagation()
                    if (data.locationData) {
                      setCommentLocationData({
                        delta,
                        locationData: data.locationData,
                        unit: data.unit,
                        actualCurrentValue: data[TabIndexDataMap[currentTab].actual],
                        plannedCurrentValue: data[TabIndexDataMap[currentTab].planned]
                      })
                    }
                  }
                : undefined
            }
            data-test-id={`comments-button-${data.locationData?.id}`}
          >
            {commentsCount ? <ModeCommentIcon /> : <ModeCommentOutlinedIcon />}
          </IconButton>

          {!!commentsCount && (
            <Typography
              variant="h5"
              sx={{color: 'primary.main'}}
              data-test-id={`comments-counter-${data.locationData?.id}`}
            >
              {commentsCount}
            </Typography>
          )}
        </Box>
      )
    }
  })

  return baseColumns
}

export const generateBarChartData = ({
  data,
  dateRange,
  currentTab,
  translationFn,
  locale
}: {
  data: AggregatedKpiData
  dateRange: DateRange<Date>
  currentTab: KpisList
  translationFn: (key: string, params?: {[key: string]: string}) => string
  locale: string
}): BarChartData => {
  const barData = data.historicalData.map((historicalItem) => ({
    name: translationFn('actualVsPlanned.label.actualDate', {
      date: getComparisonDate(dateRange, locale, historicalItem.yearOffset)
    }),
    label: (
      <Typography
        component="span"
        sx={{
          color: (theme) => theme.palette.text.disabled
        }}
      >{`${formatNumber(
        historicalItem[TabIndexDataMap[currentTab].actual] as number,
        locale,
        FRACTION_DIGITS
      )} ${data.unit}`}</Typography>
    ),
    value: historicalItem[TabIndexDataMap[currentTab].actual]
  }))
  const currentData = [
    {
      name: translationFn('actualVsPlanned.label.plannedDate', {
        date: getComparisonDate(dateRange, locale)
      }),
      label: `${formatNumber(
        data[TabIndexDataMap[currentTab].planned] as number,
        locale,
        FRACTION_DIGITS
      )} ${data.unit}`,
      value: data[TabIndexDataMap[currentTab].planned],
      backgroundColor: 'background.default',
      color: 'common.white'
    },
    {
      name: translationFn('actualVsPlanned.label.actualDate', {
        date: getComparisonDate(dateRange, locale)
      }),
      label: (
        <>
          <Box component="span" mr={2}>
            {formatNumber(
              data[TabIndexDataMap[currentTab].actual] as number,
              locale,
              FRACTION_DIGITS
            )}{' '}
            {data.unit}
          </Box>
          <DeltaLabel value={data[TabIndexDataMap[currentTab].delta]} />
        </>
      ),
      value: data[TabIndexDataMap[currentTab].actual],
      backgroundColor: getDeltaBarColor(data[TabIndexDataMap[currentTab].delta] as number),
      color: 'common.white'
    }
  ]
  return [...barData, ...currentData]
}

const generatePlantMeanTimeBetweenFailuresData = (
  plantId: string,
  meanTimeBetweenFailuresData?: MeanTimeBetweenFailures
): {meanTimeBetweenFailure: ActualAndPlannedData} | undefined => {
  const plantMeanTimeBetweenFailures = meanTimeBetweenFailuresData?.descendants?.find(
    (item) => item.plantId === plantId
  )
  return (
    plantMeanTimeBetweenFailures && {
      meanTimeBetweenFailure: {
        unit: plantMeanTimeBetweenFailures.unit,
        planned: plantMeanTimeBetweenFailures.plannedMeanTimeBetweenFailures,
        actual: plantMeanTimeBetweenFailures.actualMeanTimeBetweenFailures,
        delta: plantMeanTimeBetweenFailures.delta
      }
    }
  )
}

const generatePlantAlternativeFuelRates = (
  plantId: string,
  alternativeFuelRatesData?: AlternativeFuelRates
): {alternativeFuelRate: ActualAndPlannedData} | undefined => {
  const plantAlternativeFuel = alternativeFuelRatesData?.descendants?.find(
    (item) => item.plantId === plantId
  )
  return (
    plantAlternativeFuel && {
      alternativeFuelRate: {
        unit: plantAlternativeFuel.unit,
        planned: plantAlternativeFuel.plannedAlternativeFuelRates,
        actual: plantAlternativeFuel.actualAlternativeFuelRates,
        delta: plantAlternativeFuel.delta
      }
    }
  )
}

const generatePlantKilnCoefficient = (
  plantId: string,
  kilnCoefficientData?: KilnCoefficients
):
  | {
      kilnReliabilityCoefficient: ActualAndPlannedData
      kilnOperatingCoefficient: ActualAndPlannedData
    }
  | undefined => {
  const plantCoefficientData = kilnCoefficientData?.descendants?.find(
    (item) => item.plantId === plantId
  )
  return (
    plantCoefficientData && {
      kilnReliabilityCoefficient: {
        unit: plantCoefficientData.unit,
        planned: plantCoefficientData.plannedReliabilityCoefficient,
        actual: plantCoefficientData.actualReliabilityCoefficient,
        delta: plantCoefficientData.reliabilityDelta
      },
      kilnOperatingCoefficient: {
        unit: plantCoefficientData.unit,
        planned: plantCoefficientData.plannedOperatingCoefficient,
        actual: plantCoefficientData.actualOperatingCoefficient,
        delta: plantCoefficientData.operatingDelta
      }
    }
  )
}

const generatePlantKilnHeatConsumptions = (
  plantId: string,
  kilnHeatConsumptionsData?: KilnHeatConsumptions
): {kilnHeatConsumption: ActualAndPlannedData} | undefined => {
  const plantKilnHeatConsumption = kilnHeatConsumptionsData?.descendants?.find(
    (item) => item.plantId === plantId
  )
  return (
    plantKilnHeatConsumption && {
      kilnHeatConsumption: {
        unit: plantKilnHeatConsumption.unit,
        planned: plantKilnHeatConsumption.plannedKilnHeatConsumptions,
        actual: plantKilnHeatConsumption.actualKilnHeatConsumptions,
        delta: plantKilnHeatConsumption.delta
      }
    }
  )
}

const generatePlantTechnicalCementPowerConsumption = (
  plantId: string,
  technicalCementPowerConsumptionData?: TechnicalCementPowerConsumption
): {technicalCementPowerCons: ActualAndPlannedData} | undefined => {
  const plantTechnicalCementPowerCons = technicalCementPowerConsumptionData?.descendants?.find(
    (item) => item.plantId === plantId
  )
  return (
    plantTechnicalCementPowerCons && {
      technicalCementPowerCons: {
        unit: plantTechnicalCementPowerCons.unit,
        planned: plantTechnicalCementPowerCons.plannedTechnicalCementPowerConsumptions,
        actual: plantTechnicalCementPowerCons.actualTechnicalCementPowerConsumptions,
        delta: plantTechnicalCementPowerCons.delta
      }
    }
  )
}

export const generateKpiData = (
  plantId: string,
  {
    alternativeFuelRatesError,
    meanTimeBetweenFailuresError,
    kilnCoefficientError,
    kilnHeatConsumptionsError,
    technicalCementPowerConsumptionError
  }: KpiDataGeneratorError,
  {
    alternativeFuelRatesData,
    meanTimeBetweenFailuresData,
    kilnCoefficientData,
    kilnHeatConsumptionsData,
    technicalCementPowerConsumptionData
  }: KpiDataGeneratorData
): PlantKPIs => {
  const plantAlternativeFuelRates = !alternativeFuelRatesError
    ? generatePlantAlternativeFuelRates(plantId, alternativeFuelRatesData)
    : undefined
  const plantMeanTimeBetweenFailures = !meanTimeBetweenFailuresError
    ? generatePlantMeanTimeBetweenFailuresData(plantId, meanTimeBetweenFailuresData)
    : undefined
  const plantKilnCoefficient = !kilnCoefficientError
    ? generatePlantKilnCoefficient(plantId, kilnCoefficientData)
    : undefined
  const plantKilnHeatConsumptions = !kilnHeatConsumptionsError
    ? generatePlantKilnHeatConsumptions(plantId, kilnHeatConsumptionsData)
    : undefined
  const plantTechnicalCementPowerConsumption = !technicalCementPowerConsumptionError
    ? generatePlantTechnicalCementPowerConsumption(plantId, technicalCementPowerConsumptionData)
    : undefined

  return {
    alternativeFuelRate: {
      data: plantAlternativeFuelRates && {...plantAlternativeFuelRates.alternativeFuelRate},
      isError: alternativeFuelRatesError
    },
    meanTimeBetweenFailure: {
      data: plantMeanTimeBetweenFailures && {
        ...plantMeanTimeBetweenFailures.meanTimeBetweenFailure
      },
      isError: meanTimeBetweenFailuresError
    },
    kilnReliabilityCoefficient: {
      data: plantKilnCoefficient && {...plantKilnCoefficient.kilnReliabilityCoefficient},
      isError: kilnCoefficientError
    },
    kilnOperatingCoefficient: {
      data: plantKilnCoefficient && {...plantKilnCoefficient.kilnOperatingCoefficient},
      isError: kilnCoefficientError
    },
    technicalCementPowerConsumption: {
      data: plantTechnicalCementPowerConsumption && {
        ...plantTechnicalCementPowerConsumption.technicalCementPowerCons
      },
      isError: technicalCementPowerConsumptionError
    },
    kilnHeatConsumption: {
      data: plantKilnHeatConsumptions && {...plantKilnHeatConsumptions.kilnHeatConsumption},
      isError: kilnHeatConsumptionsError
    }
  }
}

export const checkIfQuarterlyDataExists = (
  currentTab: KpisList,
  kpiQuarterlyData: KpiQuarterlyData,
  locationId: string = HierarchyNodeType.Global
): boolean => {
  if (kpiQuarterlyData.locationId === locationId) {
    let result = false
    for (const descendant of kpiQuarterlyData.descendants) {
      const quarterlyForecast = descendant?.[TabIndexDataMap[currentTab].quarterlyForecast]
      result = result || !!quarterlyForecast
    }
    return result
  }

  let finalResult = false

  kpiQuarterlyData.descendants?.forEach((descendant: KpiQuarterlyData) => {
    finalResult = finalResult || checkIfQuarterlyDataExists(currentTab, descendant, locationId)
  })
  return finalResult
}
