import {Iso8601, User} from '@hconnect/common/types'

import {TasksFilterKey, TasksPageParamKey} from '../consts'

export enum TaskSource {
  EASY_POM = 'easyPom',
  COCKPIT = 'cockpit'
}

export enum TaskStatus {
  Pending = 'pending',
  Ongoing = 'ongoing',
  Done = 'done',
  Cancelled = 'cancelled',
  None = 'none',
  WaitingForApproval = 'waitingForApproval',
  Approved = 'approved',
  Rejected = 'rejected',
  WorkOrderCreated = 'workOrderCreated'
}

export type PaginationResponse<T> = {
  items: T[]
  totalItemCount: number
}

export type HProduceTask = {
  status: TaskStatus
  created: Iso8601
  description: string
  dueDate: Iso8601
  externalId: string
  id: string
  plantId: string
  source: TaskSource
  title: string
  assignees: User[]
}

export enum TasksTableSourceFilterValue {
  SHIFT = 'shift',
  POM = 'pom'
}

export enum CockpitCategory {
  Mechanical = 'mechanical',
  Electrical = 'electrical',
  Quality = 'quality',
  Process = 'process',
  Environment = 'environment',
  HealthAndSafety = 'healthAndSafety',
  Production = 'production',
  Cleaning = 'cleaning',
  Cost = 'cost',
  Other = 'other'
}

export type TaskFilterDto = {
  title?: string
  statuses?: TaskStatus[]
  categories?: CockpitCategory[]
  assigneeIds?: string[]
  plantIds?: string[]
  processStages?: string[]
  mainEquipmentId?: string
  equipmentId?: string
}

export type TasksFiltersState = {
  [TasksFilterKey.TITLE]?: string
  [TasksFilterKey.STATUSES]?: TaskStatus[]
  [TasksFilterKey.CATEGORIES]?: CockpitCategory[]
  [TasksFilterKey.PRIORITIES]?: number[]
}

export type TasksPageQueryParams = {
  [TasksPageParamKey.ASSIGNED_TO_ME]?: boolean
  [TasksPageParamKey.SOURCES]?: TaskSource[]
  [TasksPageParamKey.TITLE]?: string
  [TasksPageParamKey.STATUSES]?: TaskStatus[]
  [TasksPageParamKey.CATEGORIES]?: CockpitCategory[]
  [TasksPageParamKey.PRIORITIES]?: number[]
}
