import {MenuItem, TextField} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {TOP_ISSUES_SORT_BY} from '../../consts'
import {useQueryParamValue} from '../../hooks/useQueryParamValue'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {IssueSortingKey} from '../../types'

type SortBySelectProps = {
  value: IssueSortingKey
  onChange: (value: IssueSortingKey) => void
  'data-test-id': string
}

export const SortBySelect: React.FC<SortBySelectProps> = ({
  value,
  onChange,
  'data-test-id': dataTestId
}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const [, setSortingKey] = useQueryParamValue<IssueSortingKey>(TOP_ISSUES_SORT_BY, {
    keepInLocalStorage: true
  })

  return (
    <TextField
      variant="outlined"
      fullWidth
      select
      id="sort-by-drop-down"
      name="sortBy"
      label={t(`${performancePrefix}.downtime.actions.sortBy`)}
      value={value}
      onChange={(e) => {
        setSortingKey(e.target.value as IssueSortingKey)
        onChange(e.target.value as IssueSortingKey)
      }}
      data-test-id={`${dataTestId}-select`}
    >
      {Object.values(IssueSortingKey).map((key) => (
        <MenuItem key={key} value={key} data-test-id={`${dataTestId}-${key}`}>
          {t(`${performancePrefix}.downtime.actions.${key}`)}
        </MenuItem>
      ))}
    </TextField>
  )
}
