import {getTranslationKey} from '@hconnect/common/utils/translation.utils'
import {IconWithTooltipButton} from '@hconnect/uikit/src/lib2'
import {List as ListIcon} from '@mui/icons-material'
import {Box, Chip, Divider, FormControl, FormControlLabel, Switch} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {TaskSource, ChipsFilterOption} from '../types'

import {ChipsFilter} from './ChipsFilter'

type Props = {
  isSmall: boolean
  showDescriptions: boolean
  toggleShowDescriptions: () => void
  translationPrefix?: string
  sourceFilters: TaskSource[]
  sourceFilterOptions: ChipsFilterOption<TaskSource>[]
  onSourceFilterChange: (selected: TaskSource[]) => void
  assignedToMe: boolean
  toggleAssignedToMe: () => void
}

export const TasksTableFilters: React.FC<Props> = ({
  isSmall,
  translationPrefix,
  showDescriptions,
  toggleShowDescriptions,
  sourceFilters,
  sourceFilterOptions,
  onSourceFilterChange,
  assignedToMe,
  toggleAssignedToMe
}) => {
  const {t} = useTranslation()

  return (
    <Box
      sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2}}
    >
      <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1.5}}>
        <ChipsFilter
          options={sourceFilterOptions}
          selectedOptions={sourceFilters}
          onChange={onSourceFilterChange}
          translationPrefix={translationPrefix}
        />
        <Divider orientation="vertical" flexItem />
        <Chip
          label={t(`${translationPrefix}.tasksTable.filters.label.assignedToMe`)}
          clickable
          color={assignedToMe ? 'primary' : 'default'}
          onClick={toggleAssignedToMe}
        />
      </Box>
      {isSmall ? (
        <FormControl>
          <IconWithTooltipButton
            data-test-id="events-show-descriptions-toggle"
            onClick={toggleShowDescriptions}
            icon={<ListIcon />}
            title={t(getTranslationKey('eventsTable.action.toggleDescriptions', translationPrefix))}
            checked={showDescriptions}
          />
        </FormControl>
      ) : (
        <FormControlLabel
          control={
            <Switch
              checked={showDescriptions}
              data-test-id="events-show-descriptions-toggle"
              onChange={toggleShowDescriptions}
              color="primary"
            />
          }
          label={t(getTranslationKey('eventsTable.action.toggleDescriptions', translationPrefix))}
        />
      )}
    </Box>
  )
}
