import {Comment, HierarchyNodeMetadata} from '../types'

import {findLocationEntry} from './location.helpers'

export const filterCommentsByLocation = (
  comments: Comment[],
  hierarchy: HierarchyNodeMetadata,
  locationId: string
): Comment[] => {
  return comments.filter((comment) => {
    const commentLocation = findLocationEntry(hierarchy, comment.locationId)
    return commentLocation?.path.includes(locationId)
  })
}
