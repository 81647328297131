import {StoppageKind, shifthandoverStoppagesList, FilterTimeRange} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {useAxios} from '../../shared/hooks/useApi'
import {StoppageEvent} from '../types'

type UseStoppageOptions = Omit<
  UseQueryOptions<StoppageEvent | undefined, AxiosError>,
  'initialData'
>

type EventSearchCriteria = {
  eventType: readonly StoppageKind[]
  timeRange: FilterTimeRange
  itemsPerPage: number
  pageNumber: number
  processStage: string[]
}

const QueryKey = 'events'

export type EventSearchResult = {
  currentPage: number
  events: StoppageEvent[]
  totalItemCount: number
  totalPages: number
  processStageId: string
}

export const useStoppage = (plantId: string, stoppageId?: string, options?: UseStoppageOptions) => {
  const axiosInstance = useAxios()
  const path = `/shifthandover/${plantId}/events/${stoppageId}`

  return useQuery<StoppageEvent | undefined, AxiosError>(
    [QueryKey, stoppageId, plantId],
    async () => {
      if (stoppageId === undefined) return undefined
      const response = await axiosInstance.get<StoppageEvent>(path)
      return response.data
    },
    {
      ...options
    }
  )
}

export const useStoppages = (
  plantId: string,
  timeRange: FilterTimeRange,
  pageNumber = 0,
  itemsPerPage = 50,
  processStageId,
  options?: UseQueryOptions<EventSearchResult, AxiosError>
) => {
  const axiosInstance = useAxios()

  return useQuery<EventSearchResult, AxiosError>(
    [QueryKey, itemsPerPage, pageNumber, plantId, processStageId, timeRange],
    async () => {
      const settings: EventSearchCriteria = {
        timeRange,
        pageNumber,
        itemsPerPage,
        eventType: shifthandoverStoppagesList,
        processStage: [processStageId]
      }

      const response = await axiosInstance.post<EventSearchResult>(
        `/shifthandover/${plantId}/search/events`,
        settings
      )
      return response.data
    },
    options
  )
}
