import {formatNumber} from '@hconnect/common/utils'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Grid, Stack, Typography} from '@mui/material'
import {camelCase} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {KpisList, ProductionActualAndPlannedData} from '../types'

import {StyledKpiColumn, StyledKpiTitle} from './AcVsPlMobileGrid'
import {DeltaLabel, kpiDeltaReversedColorMap} from './common'
import {KPI_ICONS_MAP} from './KpiIcons'

export type KpiTableData = {
  name: KpisList
} & ProductionActualAndPlannedData

type PlantKpiMobileGridsProps = {
  kpiTableData: KpiTableData[]
  formattedDate: string
}

export const KpiGrid: React.FC<{kpiData: KpiTableData; formattedDate: string}> = ({
  kpiData,
  formattedDate
}) => {
  const {t, i18n} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      data-test-id="plant-kpi-mobile-grid"
    >
      <StyledKpiColumn>
        <StyledKpiTitle variant="caption">
          {t(`${performancePrefix}.plantStatus.label.planned`, {date: formattedDate})}
        </StyledKpiTitle>
        <Typography variant="h4">
          {formatNumber(kpiData.data?.planned ?? 0, i18n.language)}
          {kpiData.data?.unit && ` ${kpiData.data.unit}`}
        </Typography>
      </StyledKpiColumn>
      <StyledKpiColumn>
        <StyledKpiTitle variant="caption">
          {t(`${performancePrefix}.plantStatus.label.actual`, {date: formattedDate})}
        </StyledKpiTitle>
        <Typography variant="h4">
          {formatNumber(kpiData.data?.actual ?? 0, i18n.language)}
          {kpiData.data?.unit && ` ${kpiData.data.unit}`}
        </Typography>
      </StyledKpiColumn>
      <StyledKpiColumn>
        <StyledKpiTitle variant="caption">
          {t(`${performancePrefix}.plantStatus.label.delta`)}
        </StyledKpiTitle>
        <Typography variant="h4">
          <DeltaLabel
            value={kpiData.data?.delta || 0}
            reverseColor={kpiDeltaReversedColorMap[kpiData.name]}
          />
        </Typography>
      </StyledKpiColumn>
    </Stack>
  )
}

export const PlantKpiMobileGrids: React.FC<PlantKpiMobileGridsProps> = ({
  kpiTableData,
  formattedDate
}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" display="flex" alignItems="center" m={1.5}>
          {t(`${performancePrefix}.plantStatus.label.janusMonthlyKPIs`)}
        </Typography>
        <Grid container spacing={2}>
          {kpiTableData.map((kpiData) => (
            <Grid item xs={12} key={kpiData.name}>
              <CardBox px={1.5}>
                <Typography variant="h3" display="flex" alignItems="center" mb={2} gap={1}>
                  {KPI_ICONS_MAP[kpiData.name]}
                  {t(`${performancePrefix}.actualVsPlanned.plantKpis.${camelCase(kpiData.name)}`)}
                </Typography>
                <KpiGrid kpiData={kpiData} formattedDate={formattedDate} />
              </CardBox>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
