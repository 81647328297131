import {TasksFilterKey} from '../consts'
import {TasksFiltersState} from '../types'
import {filterOutUndefinedParams} from '../utils'

import {useTasksPageQueryParams} from './useTasksPageQueryParams'

type UseTasksPageFiltersReturn = {
  filters: TasksFiltersState
  updateFilters: (newFilters: TasksFiltersState) => void
}

const tasksFiltersKeys = Object.values(TasksFilterKey)

export const useTasksPageFilters = (): UseTasksPageFiltersReturn => {
  const [params, {updateParams}] = useTasksPageQueryParams()
  const filters = filterOutUndefinedParams(params, tasksFiltersKeys)

  return {
    filters,
    updateFilters: updateParams
  }
}
