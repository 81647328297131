import {ManualKpiSuggestion} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {useAxios} from '../../../shared/hooks/useApi'

const QueryKey = 'kpi-suggestions'

export const useKpiSuggestions = (
  plantId: string,
  options?: UseQueryOptions<ManualKpiSuggestion[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const path = `/shifthandover/${plantId}/kpi-suggestions`

  return useQuery<ManualKpiSuggestion[], AxiosError>(
    [QueryKey, plantId],
    async () => {
      const response = await axiosInstance.get<ManualKpiSuggestion[]>(path)
      return response.data
    },
    options
  )
}
