import {AddFilterButton} from '@hconnect/common/components'
import {getTranslationKey} from '@hconnect/common/utils'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {FilterTextField} from '../../../shared/components/filters'
import {TasksFilterKey, TasksFilterKeyName} from '../../consts'
import {type TasksFiltersState} from '../../types'
import {isTasksFilterKey} from '../../utils'

import {TaskCategoryFilter} from './TaskCategoryFilter'
import {TaskPriorityFilter} from './TaskPriorityFilter'
import {TaskStatusFilter} from './TaskStatusFilter'

type Props = {
  translationPrefix: string
  filters: TasksFiltersState
  onFiltersChange: (filters: TasksFiltersState) => void
}

type FilterOption = {
  value: TasksFilterKeyName
  label: string
}

export const TasksFilters: React.FC<Props> = ({translationPrefix, filters, onFiltersChange}) => {
  const [visibleFilters, setVisibleFilters] = React.useState<TasksFilterKeyName[]>(
    getInitialVisibleFilters(filters)
  )
  const {t} = useTranslation()

  const filtersOptions: FilterOption[] = [
    {
      value: TasksFilterKey.TITLE,
      label: t(getTranslationKey('shiftEvent.label.title', translationPrefix))
    },
    {
      value: TasksFilterKey.CATEGORIES,
      label: t(getTranslationKey('shiftEvent.label.category', translationPrefix))
    },
    {
      value: TasksFilterKey.STATUSES,
      label: t(getTranslationKey('shiftEvent.label.status', translationPrefix))
    },
    {
      value: TasksFilterKey.PRIORITIES,
      label: t(getTranslationKey('shiftEvent.label.priority', translationPrefix))
    }
  ]

  const handleFilterChange = (
    key: TasksFilterKeyName,
    value?: string | number | (string | number)[]
  ) => {
    onFiltersChange({...filters, [key]: value})
  }

  const clearFilter = (key: TasksFilterKeyName) => {
    onFiltersChange({...filters, [key]: undefined})
    setVisibleFilters(visibleFilters.filter((filter) => filter !== key))
  }

  const handleVisibleFiltersChange = (newVisibleFilters: TasksFilterKeyName[]) => {
    setVisibleFilters((prevFilters) => {
      const filtersToClear = prevFilters.filter((filter) => !newVisibleFilters.includes(filter))
      const newFilters = {...filters}

      filtersToClear.forEach((filter) => {
        newFilters[filter] = undefined
      })
      onFiltersChange(newFilters)

      return newVisibleFilters
    })
  }

  return (
    <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
      {visibleFilters.includes(TasksFilterKey.TITLE) && (
        <FilterTextField
          name={TasksFilterKey.TITLE}
          label={t(getTranslationKey('shiftEvent.label.title', translationPrefix))}
          value={filters[TasksFilterKey.TITLE]}
          onChange={handleFilterChange}
        />
      )}
      {visibleFilters.includes(TasksFilterKey.CATEGORIES) && (
        <TaskCategoryFilter<TasksFilterKeyName>
          name={TasksFilterKey.CATEGORIES}
          label={t(getTranslationKey('shiftEvent.label.category', translationPrefix))}
          value={filters[TasksFilterKey.CATEGORIES]}
          onChange={handleFilterChange}
          clearFilter={clearFilter}
          translationPrefix={translationPrefix}
        />
      )}
      {visibleFilters.includes(TasksFilterKey.STATUSES) && (
        <TaskStatusFilter<TasksFilterKeyName>
          name={TasksFilterKey.STATUSES}
          label={t(getTranslationKey('shiftEvent.label.status', translationPrefix))}
          value={filters[TasksFilterKey.STATUSES]}
          onChange={handleFilterChange}
          clearFilter={clearFilter}
          translationPrefix={translationPrefix}
        />
      )}
      {visibleFilters.includes(TasksFilterKey.PRIORITIES) && (
        <TaskPriorityFilter<TasksFilterKeyName>
          name={TasksFilterKey.PRIORITIES}
          label={t(getTranslationKey('shiftEvent.label.priority', translationPrefix))}
          value={filters[TasksFilterKey.PRIORITIES]}
          onChange={handleFilterChange}
          clearFilter={clearFilter}
          translationPrefix={translationPrefix}
        />
      )}
      <Box sx={{textWrap: 'nowrap'}}>
        <AddFilterButton
          data-test-id={'tasks-add-filter'}
          options={filtersOptions}
          onChange={handleVisibleFiltersChange}
          values={visibleFilters}
          buttonLabel={t(getTranslationKey('shiftEvent.label.addFilter', translationPrefix))}
        />
      </Box>
    </Box>
  )
}

const getInitialVisibleFilters = (filters: TasksFiltersState): TasksFilterKeyName[] => {
  return Object.keys(filters).filter(isTasksFilterKey)
}
