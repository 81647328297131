import {useQueryParamValue} from './useQueryParamValue'

export const KEY_TIME_RANGE = 'timeRange'
export const DEFAULT_TIME_RANGE = 'today'

export const useTimeRangeParam = (): [string, (value: string) => void] => {
  const [timeRange, setTimeRange] = useQueryParamValue<string>(KEY_TIME_RANGE, {
    keepInLocalStorage: true
  })
  return [timeRange || DEFAULT_TIME_RANGE, setTimeRange]
}
