import {HierarchyNode, HierarchyNodeMetadata, HierarchyNodeType} from '../types'

export const checkIsArea = (location: string) => location.startsWith('a_')

export const checkIsCountry = (location: string) => location.startsWith('c_')

export const getCountries = (list: string[]) => {
  const countries = list.filter(checkIsCountry)
  return countries
}

export const getAllowedAreas = (list: string[]) => {
  const areas = list.filter(checkIsArea)
  return areas
}

export const getLocationParent = (locations: HierarchyNodeMetadata, locationId: string) => {
  const locationTree = findLocationEntryWithParents(locations, locationId)
  let parentPosition: number | undefined = -1

  if (locationTree && locationTree.length > 1) {
    const elementPosition = locationTree.findIndex((location) => location.id === locationId)
    parentPosition = elementPosition - 1

    if (locationTree[parentPosition].type === HierarchyNodeType.Region)
      parentPosition = elementPosition - 2
  }

  return parentPosition > -1 ? locationTree?.[parentPosition] : null
}

export const findLocationEntryWithParents = (
  locations: HierarchyNodeMetadata,
  locationId: string,
  parents: HierarchyNodeMetadata[] = []
): HierarchyNodeMetadata[] | undefined => {
  if (locations.id === locationId) {
    return [...parents, locations]
  }

  for (const child of locations.children) {
    const result = findLocationEntryWithParents(child, locationId, [...parents, locations])
    if (result) {
      return result
    }
  }
}

export const findLocationEntry = <T extends {id: string; children: T[]}>(
  locations: T,
  locationId: string
): T | undefined => {
  if (locations.id === locationId) {
    return locations
  }
  for (const child of locations.children) {
    const result = findLocationEntry(child, locationId)
    if (result) {
      return result
    }
  }
}

export const getLocationEntryWithParentsLabel = (
  scopeLocationId: string,
  commentLocationId: string,
  hierarchy: HierarchyNodeMetadata
) =>
  findLocationEntryWithParents(hierarchy, commentLocationId)
    ?.filter(({path, id}) => path.includes(scopeLocationId) && id !== scopeLocationId)
    .map(({name}) => name)
    .join(' > ')

export const hasRegionTypeWithChildren = (node: HierarchyNode) => {
  if (node.type === HierarchyNodeType.Region && node.children?.length) {
    return true
  }
  if (Array.isArray(node.children)) {
    return node.children.some((child) => hasRegionTypeWithChildren(child))
  }
  return false
}

export const getRegionIdList = (locationId: string, hierarchy?: HierarchyNode): string[] =>
  hierarchy
    ? findLocationEntry(hierarchy, locationId)
        ?.children.filter(({isAllowed}) => isAllowed)
        .map(({id}) => id) || []
    : []
