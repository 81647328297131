import {Permission, PermissionType} from '@hconnect/apiclient'
import {plantIdCheckSucceed} from '@hconnect/common/utils'
import {useMemo} from 'react'

import {useGlobalContext} from './useGlobalContext'

export function useHasPermission(plantId: string) {
  const {permissions: dfPermissions} = useGlobalContext()

  return useMemo(() => {
    // filter out all permissions that are set for a different plantId
    const permissionTypes = dfPermissions.reduce<PermissionType[]>((list, dfPermission) => {
      if (plantIdCheckSucceed(dfPermission.dataScope, plantId)) {
        list.push(dfPermission.permissionType)
      }
      return list
    }, [])

    // helper function
    return (permission: PermissionType) => {
      return permissionTypes.includes(permission)
    }
  }, [dfPermissions, plantId])
}

// TODO: Deprecated, it's need to be checked if can be removed
export function hasPermission(permissions: Permission[], permission: PermissionType) {
  // todo remove when role would be added
  if (permission === 'BOARD_MEMBER') {
    return true
  }
  return !!permissions.find(({permissionType}) => permissionType === permission)
}
