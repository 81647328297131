import {
  BrowserBackendSelector,
  BrowserLoginFlow,
  BrowserLoginStorage,
  ClientConfig,
  createHttpClient,
  EnvAuthRequestProvider,
  getDefaultDFBackends,
  LoginFlow,
  LoginStorage
} from '@hconnect/apiclient'
import {AxiosInstance} from 'axios'
import MockAdapter from 'axios-mock-adapter'

import {enableExistingApiMocks, enableNotYetExistingApiMocks} from '../../__mock__/mock'
import {mockStore} from '../../__mock__/mockStoreSingleton'

const STORAGE_KEY = `HC-${process.env.REACT_APP_CLIENT_NAME}-${process.env.REACT_APP_STAGE}`

export type ApiConfig = ClientConfig & {
  axiosInstance: AxiosInstance
  loginFlow: LoginFlow
  loginStorage: LoginStorage
}

export const createApi = (options: {
  throwOnNoMatch: boolean
  mockEverything: boolean
}): ApiConfig => {
  const loginStorage = new BrowserLoginStorage(STORAGE_KEY)
  const backendSelector = new BrowserBackendSelector(getDefaultDFBackends())
  const authRequestProvider = new EnvAuthRequestProvider(backendSelector)
  const loginFlow = new BrowserLoginFlow(loginStorage, authRequestProvider, backendSelector)

  const clientConfig: ClientConfig = {
    authRequestProvider,
    backendSelector,
    loginFlow,
    loginStorage
  }

  const axiosInstance = createHttpClient(clientConfig)

  const apiMockAdapter = new MockAdapter(axiosInstance, {
    onNoMatch: options.throwOnNoMatch ? 'throwException' : 'passthrough'
  })

  // to start development of features that are not implemented on the backend yet
  enableNotYetExistingApiMocks()

  // Mocks for already implemented APIs (i.e. for Cypress tests)
  if (options.mockEverything) {
    mockStore.enableDfApiMockForAxios(apiMockAdapter)
    enableExistingApiMocks(apiMockAdapter)
  }
  return {
    ...clientConfig,
    axiosInstance
  }
}
