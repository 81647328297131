import {formatNumber} from '@hconnect/common/utils'
import {getTranslationKey} from '@hconnect/common/utils/translation.utils'
import {dateFormatter} from '@hconnect/uikit'
import {Column, DataTable} from '@hconnect/uikit/src/lib2'
import {Typography} from '@mui/material'
import moment from 'moment-timezone'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {DowntimeStoppage} from '../../types'
import {DataContentWrapper} from '../DataContentWrapper'

const generateColumns = (
  t: (key: string) => string,
  showDescriptions: boolean,
  language: string
): Column<DowntimeStoppage>[] => {
  const columns: Column<DowntimeStoppage>[] = [
    {
      key: 'stoppage-title',
      customTemplate: ({title}) => <Typography variant="subtitle1">{title}</Typography>,
      label: t('downtime.label.stoppageTitle')
    },
    {
      key: 'main-equipment',
      customTemplate: ({mainEquipment}) => (
        <Typography variant="subtitle1">{mainEquipment?.text ?? '-'}</Typography>
      ),
      label: t('downtime.label.mainEquipment')
    },
    {
      key: 'functionalLocation',
      customTemplate: ({equipment}) => (
        <Typography variant="subtitle1">{equipment?.text ?? '-'}</Typography>
      ),
      label: t('downtime.label.functionalLocation')
    },
    {
      key: 'stoppage-time',
      customTemplate: ({start, end}) => (
        <Typography variant="subtitle1">{`${dateFormatter(moment(start))} - ${dateFormatter(
          moment(end)
        )}`}</Typography>
      ),
      label: t('downtime.label.stoppageTime')
    },
    {
      key: 'stoppage-duration',
      customTemplate: ({start, end}) => (
        <Typography variant="subtitle1">
          {formatNumber(moment(end).diff(moment(start), 'hours', true), language)}
        </Typography>
      ),
      label: t('downtime.label.stoppageDuration')
    },
    {
      key: 'stoppage-rcfa',
      //   todo: this column will be available in future stories
      customTemplate: () => <Typography variant="subtitle1">-</Typography>,
      label: t('downtime.label.stoppageRcfa')
    }
  ]
  if (showDescriptions) {
    columns.push({
      key: 'stoppage-description',
      customTemplate: ({description}) => <Typography variant="subtitle1">{description}</Typography>,
      label: t('downtime.label.stoppageDescription')
    })
  }
  return columns
}

type Props = {
  stoppagesDetails: DowntimeStoppage[]
  isLoading: boolean
  refetch?: () => void
  error: Error | null
  showDescriptions: boolean
}

export const StoppageDetailsTable: React.FC<Props> = ({
  stoppagesDetails,
  error,
  refetch,
  isLoading,
  showDescriptions
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {performancePrefix} = useTranslationPrefix()

  const columns = useMemo(
    () =>
      generateColumns(
        (key: string) => t(getTranslationKey(key, performancePrefix)),
        showDescriptions,
        language
      ),
    [t, performancePrefix, showDescriptions, language]
  )

  const renderContent = () => {
    return (
      <DataTable<DowntimeStoppage>
        columns={columns}
        data={stoppagesDetails ?? []}
        data-test-id="details-table"
      />
    )
  }

  return (
    <DataContentWrapper<DowntimeStoppage>
      isLoading={isLoading}
      data={stoppagesDetails}
      retryFunction={refetch}
      error={error}
      renderContent={renderContent}
      progressSx={{color: 'primary.main'}}
    />
  )
}
