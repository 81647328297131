import React, {useState} from 'react'

type StoppagesStatsTimeRange = {
  stoppagesStatsTimeRange?: string
  setStoppagesStatsTimeRange: (timeRange?: string) => void
}

const StoppagesStatsTimeRangeContext = React.createContext<StoppagesStatsTimeRange | undefined>(
  undefined
)

export const StoppagesStatsTimeRangeProvider = ({children}: {children: React.ReactNode}) => {
  const [stoppagesStatsTimeRange, setStoppagesStatsTimeRange] = useState<string>()
  return (
    <StoppagesStatsTimeRangeContext.Provider
      value={{stoppagesStatsTimeRange, setStoppagesStatsTimeRange}}
    >
      {children}
    </StoppagesStatsTimeRangeContext.Provider>
  )
}

// used by consumers
export function useStoppagesStatsTimeRange() {
  const ctx = React.useContext(StoppagesStatsTimeRangeContext)
  if (ctx === undefined) {
    throw new Error('useStoppagesStatsTimeRange used outside of StoppagesStatsTimeRangeProvider')
  } else {
    return ctx
  }
}
