import {DateRange} from '@hconnect/uikit/src/common'
import {Box, Table, TableBody, TableHead, TableRow, TableCell} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {generateColumns} from '../helpers'
import {useHierarchyData} from '../hooks/useHierarchy'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {Comment, CommentLocationData, AggregatedKpiData, KpisList} from '../types'

import {AcVsPlTableRow} from './AcVsPlTableRow'

type Props = {
  data: AggregatedKpiData
  hasQuarterlyData: boolean
  currentTab: KpisList
  dateRange: DateRange<Date>
  setCommentLocationData: (id: CommentLocationData) => void
  comments: Comment[]
}

export const AcVsPlTable: React.FC<Props> = ({
  data,
  hasQuarterlyData,
  currentTab,
  dateRange,
  setCommentLocationData,
  comments
}) => {
  const {t, i18n} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()

  const hierarchy = useHierarchyData()

  const columns = useMemo(() => {
    const {historicalData} = data
    return generateColumns({
      translationFn: (key: string, inputParams: {[key: string]: string} = {}) =>
        t(`${performancePrefix}.${key}`, inputParams),
      currentTab,
      offsetYearsAmount: historicalData?.length,
      dateRange,
      locale: i18n.language,
      setCommentLocationData,
      comments,
      hierarchy,
      showQuarterlyData: hasQuarterlyData
    })
  }, [
    data,
    t,
    currentTab,
    dateRange,
    i18n.language,
    setCommentLocationData,
    comments,
    hierarchy,
    performancePrefix,
    hasQuarterlyData
  ])

  return (
    <Box px={1} py={3} data-test-id="ac-vs-pl-table-container">
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.key}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.descendants?.map((item) => (
            <AcVsPlTableRow<AggregatedKpiData>
              item={item}
              columns={columns}
              data-test-id={`ac-vs-pl-table-row-${item.id}`}
              key={item.id}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}
