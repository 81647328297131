import {EventStatusLabel} from '@hconnect/common/components'
import {getTranslationKey} from '@hconnect/common/utils'
import {useTranslation} from 'react-i18next'

import {FilterMultiSelect, FilterMultiSelectProps} from '../../../shared/components'
import {FILTER_VALUE_SEPARATOR} from '../../../shared/consts'
import {TaskStatus} from '../../types'

export const TaskStatusFilter = <TName extends string>({
  translationPrefix,
  ...props
}: Omit<FilterMultiSelectProps<TaskStatus, TName>, 'options'>) => {
  const {t} = useTranslation()
  const options = Object.values(TaskStatus).map((status) => ({
    value: status,
    label: t(getTranslationKey(`shiftEvent.status.${status}`, translationPrefix))
  }))

  return (
    <FilterMultiSelect
      {...props}
      translationPrefix={translationPrefix}
      options={options}
      getOptionLabel={({value}) => (
        <EventStatusLabel status={value} translationPrefix={translationPrefix} />
      )}
      renderValue={(value) =>
        value
          .map((status) => t(getTranslationKey(`shiftEvent.status.${status}`, translationPrefix)))
          .join(FILTER_VALUE_SEPARATOR)
      }
    />
  )
}
