import {ErrorDialog, ConfirmationDialog} from '@hconnect/common/components/dialogs'
import {useEventDelete} from '@hconnect/common/hooks/useEventDelete'
import React from 'react'

import {StoppageEvent} from '../types'

type StoppageDeleteProps = {
  showDialog: boolean
  plantId: string
  stoppage: StoppageEvent
  setDialogOpen: (state: boolean) => void
  onSuccess: () => void
  onlyDeleteSeries?: boolean
  translationPrefix?: string
}

export const StoppageDelete: React.FC<StoppageDeleteProps> = ({
  stoppage,
  onSuccess,
  showDialog,
  setDialogOpen,
  plantId,
  translationPrefix
}) => {
  const eventDeleteAction = useEventDelete(plantId, {
    onSuccess
  })

  return (
    <>
      {eventDeleteAction.error ? (
        <ErrorDialog
          httpCode={eventDeleteAction.error.code}
          onClose={() => {
            setDialogOpen(false)
            eventDeleteAction.reset()
          }}
          errorText={eventDeleteAction.error.message}
          translationPrefix={translationPrefix}
        />
      ) : (
        showDialog && (
          <ConfirmationDialog
            onDeleteSingle={() => {
              eventDeleteAction.mutate(stoppage.id)
            }}
            onClose={() => setDialogOpen(false)}
            isSingleLoading={eventDeleteAction.isLoading}
            title={stoppage.title ?? ''}
            isSeriesItem={false}
            translationPrefix={translationPrefix}
          />
        )
      )}
    </>
  )
}
