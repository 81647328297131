import type {Permission, PermissionType} from '@hconnect/apiclient'
import {plantIdCheckSucceed} from '@hconnect/common/utils'
import React, {useMemo} from 'react'

type UsePermissionsContextReturn = Permission[]

type PermissionsContextProviderProps = {
  initialState: Permission[]
  children?: React.ReactNode
}

const PermissionsContext = React.createContext<UsePermissionsContextReturn | undefined>(undefined)

export const PermissionsContextProvider: React.FC<PermissionsContextProviderProps> = ({
  initialState,
  children
}) => {
  return <PermissionsContext.Provider value={initialState}>{children}</PermissionsContext.Provider>
}

export function usePermissionsContext(): UsePermissionsContextReturn {
  const ctx = React.useContext(PermissionsContext)
  if (ctx === undefined) {
    throw new Error(
      'usePermissionsContext was used outside the scope of a PermissionsContextProvider!'
    )
  } else {
    return ctx
  }
}

type UseHasPermissionReturn = (permission: PermissionType) => boolean

export function useHasPermission(plantId: string): UseHasPermissionReturn {
  const permissions = usePermissionsContext()

  return useMemo(() => {
    // filter out all permissions that are set for a different plantId
    const permissionTypes = permissions.reduce<PermissionType[]>((list, permissionDef) => {
      if (plantIdCheckSucceed(permissionDef.dataScope, plantId)) {
        list.push(permissionDef.permissionType)
      }
      return list
    }, [])

    // helper function
    return (permission: PermissionType) => {
      return permissionTypes.includes(permission)
    }
  }, [permissions, plantId])
}
