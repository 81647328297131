import {SplitStoppageDialog} from '@hconnect/common/components/splitStoppage'
import {StoppageDto as Stoppage} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit'
import React, {FC, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useConfigData} from '../../../shared/hooks/useConfig'
import {usePlantTimezone} from '../../../shared/hooks/useTimezone'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {StoppageEvent} from '../../types'
import {StoppageAnalysisSideCard} from '../stoppageAnalysisSideCard'
import {StoppageCreate} from '../StoppageCreate'
import {StoppageDelete} from '../StoppageDelete'

type StoppageViewProps = {
  stoppageToPreview: StoppageEvent | undefined
  setStoppageToPreview: (stoppage: StoppageEvent | undefined) => void
  stoppageIdToPreview: string | undefined
  setStoppageIdToPreview: (id: string | undefined) => void
  setShowCreateForm: (show: boolean) => void
  plantId: string
  showCreateForm: boolean
  stoppageData: Partial<StoppageEvent> | undefined
  handleStoppageSplit: (stoppage: Partial<Stoppage>) => void
  onCreate?: (stoppageId: string) => void
  onEdit?: (stoppageId: string) => void
  onDelete?: (stoppageId: string) => void
}

export const StoppageView: FC<StoppageViewProps> = ({
  plantId,
  showCreateForm,
  stoppageData,
  stoppageToPreview,
  setStoppageToPreview,
  setShowCreateForm,
  stoppageIdToPreview,
  setStoppageIdToPreview,
  handleStoppageSplit,
  onCreate,
  onEdit,
  onDelete
}) => {
  const {notify} = useNotification()
  const {t} = useTranslation()
  const config = useConfigData()
  const {performancePrefix} = useTranslationPrefix()
  const timezone = usePlantTimezone()

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [isSplitMode, _setIsSplitMode] = useState<boolean>(false)
  const [showDeleteDialog, setDeleteDialog] = useState<boolean>(false)

  const onEditSuccess = useCallback(
    (stoppage: StoppageEvent) => {
      setIsEditMode(false)
      setStoppageToPreview(stoppage)
      notify('success', t(`${performancePrefix}.stoppageAnalysis.action.editionSuccess`))
      onEdit?.(stoppage.id)
    },
    [setStoppageToPreview, notify, t, performancePrefix, onEdit]
  )
  const handleClose = useCallback(() => {
    setShowCreateForm(false)
  }, [setShowCreateForm])
  const onSubmit = useCallback(
    ({id}: Stoppage) => {
      notify('success', t(`${performancePrefix}.stoppageAnalysis.action.creationSuccess`))
      handleClose()
      onCreate?.(id)
    },
    [notify, t, performancePrefix, handleClose, onCreate]
  )
  const handlePreviewClose = () => {
    setStoppageIdToPreview(undefined)
  }
  const setSplitMode = (stoppage: StoppageEvent) => {
    _setIsSplitMode(true)
    setStoppageToPreview(stoppage)
  }
  const handleDelete = (stoppage: StoppageEvent) => {
    setDeleteDialog(true)
    setStoppageToPreview(stoppage)
    onDelete?.(stoppage.id)
  }

  const onDeleteSuccess = useCallback(() => {
    notify('success', t(`${performancePrefix}.stoppageAnalysis.action.deleteSuccess`))
    setStoppageToPreview(undefined)
    setStoppageIdToPreview(undefined)
    setDeleteDialog(false)
  }, [setStoppageToPreview, setStoppageIdToPreview, notify, t, performancePrefix])

  return (
    <>
      {stoppageIdToPreview ? (
        <StoppageAnalysisSideCard
          isEditMode={isEditMode}
          onEdit={setIsEditMode}
          onSplit={setSplitMode}
          onDelete={handleDelete}
          stoppageId={stoppageIdToPreview}
          onEditSuccess={onEditSuccess}
          plantId={plantId}
          onClose={handlePreviewClose}
        />
      ) : showCreateForm ? (
        <StoppageCreate
          plantId={plantId}
          data-test-id="stoppage-analysis-graph-stoppage-create"
          doClose={handleClose}
          initialData={stoppageData}
          onSuccessSubmit={onSubmit}
        />
      ) : (
        <></>
      )}

      {isSplitMode && stoppageToPreview && (
        <SplitStoppageDialog
          timezone={timezone}
          open={isSplitMode}
          onClose={() => _setIsSplitMode(false)}
          stoppage={stoppageToPreview}
          onSplitSubmit={handleStoppageSplit}
          translationPrefix={performancePrefix}
          pxTrendAvailable={config?.pxTrendAvailable}
        />
      )}

      {!!(showDeleteDialog && stoppageToPreview) && (
        <StoppageDelete
          plantId={plantId}
          onSuccess={onDeleteSuccess}
          setDialogOpen={setDeleteDialog}
          stoppage={stoppageToPreview}
          showDialog={true}
          translationPrefix={performancePrefix}
        />
      )}
    </>
  )
}
