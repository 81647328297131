export function getUrlParam<T = string>(
  params: URLSearchParams,
  paramName: string,
  parser?: (value: string) => T
): T | undefined {
  if (!params.has(paramName)) return undefined
  const value = params.get(paramName)!

  return parser ? parser(value) : (value as unknown as T)
}

export function setBooleanUrlParam(
  params: URLSearchParams,
  paramName: string,
  value: boolean
): void {
  params.set(paramName, value.toString())
}

export function getBooleanUrlParam(params: URLSearchParams, paramName: string): boolean {
  const param = getUrlParam(params, paramName, (value) => value === 'true')

  return param ?? false
}

const URL_PARAM_ARRAY_SEPARATOR = ','

export function setArrayUrlParam<T = string>(
  params: URLSearchParams,
  paramName: string,
  values: T[]
): void {
  params.set(paramName, values.join(URL_PARAM_ARRAY_SEPARATOR))
}

export function getArrayUrlParam<T extends string | number = string>(
  params: URLSearchParams,
  paramName: string,
  parser?: (value: string) => T
): T[] | undefined {
  const param = getUrlParam(params, paramName)
  if (!param) return undefined

  const array = param.split(URL_PARAM_ARRAY_SEPARATOR)

  return parser ? array.map(parser) : (array as unknown as T[])
}

export function setOrDeleteArrayParam<T>(
  params: URLSearchParams,
  key: string,
  value: T[] | undefined
): void {
  if (value?.length) {
    setArrayUrlParam(params, key, value)
  } else {
    params.delete(key)
  }
}

export function setOrDeleteBooleanParam(
  params: URLSearchParams,
  key: string,
  value: boolean | undefined
): void {
  if (value !== undefined) {
    setBooleanUrlParam(params, key, value)
  } else {
    params.delete(key)
  }
}

export function setOrDeleteStringParam(
  params: URLSearchParams,
  key: string,
  value: string | undefined
): void {
  if (value) {
    params.set(key, value)
  } else {
    params.delete(key)
  }
}
