type WithChildren<T, K extends keyof T = keyof T> = {[Property in K]: T[]}

export const traverseWithKey = <K extends keyof T, T extends WithChildren<T, K>>(
  childrenKey: K,
  tree: T[],
  op: (elem: T) => void
): void => {
  tree.forEach((elem) => {
    op(elem)
    traverseWithKey(childrenKey, elem[childrenKey], op)
  })
}

export const mapToFlatListWithKey = <K extends keyof T, T extends WithChildren<T, K>>(
  childrenKey: K,
  tree: T[]
): T[] => {
  const result: T[] = []
  traverseWithKey(childrenKey, tree, (elem) => result.push({...elem, [childrenKey]: []}))
  return result
}
