import {AxiosError} from 'axios'
import {useMutation, UseMutationOptions, useQueryClient} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {NewComment} from '../types'

import {QueryKey} from './useComments'

export const useCreateComment = (
  locationId: string,
  options?: UseMutationOptions<never, AxiosError, NewComment>
) => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation<never, AxiosError, NewComment>(
    async (newCommentData) => {
      const path = `/kpi/dashboard/comments/${locationId}`

      const response = await axiosInstance.post<never>(path, newCommentData)
      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(QueryKey)
        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}
