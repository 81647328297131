import moment from 'moment-timezone'

import {isJanusQuickSelectionId, useTimeRange} from './useTimeRange'

export type ProductionValueFlags = {
  showActualValueOnly: boolean
  showActualOrTotalValue: boolean
  showTotalValueOnly: boolean
}

export const useActualOrTotalValue = (): ProductionValueFlags => {
  const {from, to, quickSelectionId} = useTimeRange()

  if (quickSelectionId) {
    // show Janus/Denodo for last7days and long term
    const showActualValue = !!(
      quickSelectionId &&
      (quickSelectionId === 'last7days' || isJanusQuickSelectionId(quickSelectionId))
    )

    // show JANUS/Denodo for yesterday, if not yet available show fall back of raw PxTrend prod volumes
    const isYesterday = !!(quickSelectionId === 'yesterday')

    // show PxTrend: ON/OFF and prod volumes for today
    const isToday = !!(quickSelectionId === 'today')

    return {
      showActualValueOnly: showActualValue,
      showActualOrTotalValue: isYesterday,
      showTotalValueOnly: isToday
    }
  }

  /**
   * Note:
   * Show actual value for any date or range before yesterday if not present show '-'
   * if the range includes today or yesterday (we pick actual and fallback to pxtrends data)
   */
  // to before yesterday started -> show actual only
  // to after yesterday -> show actual or total

  const startDate = moment(from)
  const endDate = moment(to)

  const today = moment().startOf('day')
  const yesterday = moment().subtract(1, 'days').startOf('day')

  return {
    showActualValueOnly: endDate.isBefore(yesterday),
    showActualOrTotalValue: startDate.isSameOrBefore(yesterday) && endDate.isAfter(yesterday),
    showTotalValueOnly: startDate.isSameOrAfter(today)
  }
}
