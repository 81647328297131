import {getPlantName} from '@hconnect/common/hproduce/whiteListCheck'
import {trackEvent, TrackEventData} from '@hconnect/common/logging/Analytics'
import {isObject} from 'lodash'
import {useCallback} from 'react'
import {useParams} from 'react-router'

import {useGlobalContext} from '../hooks/useGlobalContext'

import {DowntimeAnalyticsEvent} from './trackDowntimeAnalyticEvents'
import {PlantStatusAnalyticsEvent} from './trackPlantStatusAnalyticEvents'

export enum Feature {
  DowntimeDashboard = 'Downtime Dashboard',
  PlantEntryPoint = 'Plant Entry Point'
}

interface CommonAnalyticsData {
  data: {
    timestamp: string
    userId: string
    plantId: string
    plantName: string
    country: string
  }
}

type AnalyticsEvent = (DowntimeAnalyticsEvent | PlantStatusAnalyticsEvent) & {
  data: CommonAnalyticsData['data']
}

export const trackAnalyticsEvent = <T extends AnalyticsEvent['name']>(
  eventName: T,
  data: TrackEventData
) => trackEvent(eventName, {product: 'hproduce', ...data})

type PathParameter = {
  plantId: string
}

export const useTrackAnalyticsEvent = <V>(feature: Feature) => {
  const {plantId} = useParams<PathParameter>()
  const {user} = useGlobalContext()

  return useCallback(
    <T extends AnalyticsEvent['name']>(
      eventName: T,
      data: Extract<V, {name: T}> extends {data: infer D} ? D : Record<string, never>
    ) => {
      if (plantId) {
        const plantName = getPlantName(plantId)
        const {userId, country} = user
        trackAnalyticsEvent(eventName, {
          timestamp: new Date().toISOString(),
          userId,
          plantId,
          plantName,
          country,
          feature,
          ...(isObject(data) ? data : {})
        })
      }
    },
    [feature, plantId, user]
  )
}

export const useTrackDowntimeAnalyticsEvent = () =>
  useTrackAnalyticsEvent<DowntimeAnalyticsEvent>(Feature.DowntimeDashboard)

export const useTrackPlantStatusAnalyticsEvent = () =>
  useTrackAnalyticsEvent<PlantStatusAnalyticsEvent>(Feature.PlantEntryPoint)
