import {Permission} from '@hconnect/apiclient'
import React from 'react'

import {Context} from '../../shared/types/temp.types'

type State = Context & {
  permissions: Permission[]
}

export type GlobalContext = State

export interface ProviderProps {
  initialState: State
  children?: React.ReactNode
}

// creating the context
const context = React.createContext<GlobalContext | undefined>(undefined)

// can be used to wrap the <App /> but also to wrap containers within tests
export const GlobalContextProvider: React.FC<ProviderProps> = ({initialState, children}) => {
  return <context.Provider value={initialState}>{children}</context.Provider>
}

// used by consumers
export function useGlobalContext(): GlobalContext {
  const ctx = React.useContext(context)
  if (ctx === undefined) {
    throw new Error('useGlobalContext was used outside the scope of a GlobalContextProvider!')
  } else {
    return ctx
  }
}
