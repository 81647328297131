import {EquipmentRunningTimesDto as EquipmentRunningTimes} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {useAxios} from '../../shared/hooks/useApi'
import {TimeRange} from '../types'

const QueryKey = 'running-times'

export const useRunningTimes = (
  timeRange: TimeRange,
  plantId: string,
  options?: UseQueryOptions<EquipmentRunningTimes[], AxiosError>
) => {
  const axiosInstance = useAxios()

  return useQuery<EquipmentRunningTimes[], AxiosError>(
    [QueryKey, timeRange, plantId],
    async () => {
      const response = await axiosInstance.get<EquipmentRunningTimes[]>(
        `/shifthandover/${plantId}/running-times`,
        {params: {from: timeRange.startDate, to: timeRange.endDate}}
      )
      return response.data || []
    },
    options
  )
}
