import {NavItem} from '@hconnect/uikit/src/lib2'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'

import {TASKS} from './routes'

export const useTasksLinks = (): NavItem[] => {
  const {t} = useTranslation()
  const {tasksPrefix} = useTranslationPrefix()

  const navItems: NavItem[] = [
    {
      url: TASKS,
      label: t(`${tasksPrefix}.pageTitle`)
    }
  ]

  return navItems
}
