import {Box, Typography, useTheme} from '@mui/material'
import {FC, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {getComparisonDate} from '../helpers'
import {useTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {AlternativeFuelRates} from '../types'

import {PieChart} from './charts'
import {DeltaLabel} from './common'

type AcVsPlChartPieProps = {
  data: AlternativeFuelRates
}

export const AcVsPlChartPie: FC<AcVsPlChartPieProps> = ({data}) => {
  const theme = useTheme()
  const {t, i18n} = useTranslation()
  const dateRange = useTimeRange()
  const {performancePrefix} = useTranslationPrefix()

  const mainData = useMemo(
    () => [
      {
        id: 'rest',
        value: data.actualAlternativeFuelRates < 100 ? 100 - data.actualAlternativeFuelRates : 0
      },
      {
        id: 'actual',
        value: data.actualAlternativeFuelRates < 100 ? data.actualAlternativeFuelRates : 100
      }
    ],
    [data.actualAlternativeFuelRates]
  )

  const historicalData = useMemo(() => {
    return data.historicalData.map((item) => {
      const descendantData = [
        {
          id: 'rest',
          value: item.actualAlternativeFuelRates < 100 ? 100 - item.actualAlternativeFuelRates : 0
        },
        {
          id: 'actual',
          value: item.actualAlternativeFuelRates < 100 ? item.actualAlternativeFuelRates : 100
        }
      ]

      return (
        <PieChart
          key={item.yearOffset}
          data={descendantData}
          cornerRadius={2}
          legend={`${item.actualAlternativeFuelRates}${data.unit}`}
          subLegend={
            <Typography variant="caption">
              {t(`${performancePrefix}.actualVsPlanned.label.actualDate`, {
                date: getComparisonDate(dateRange, i18n.language, item.yearOffset)
              })}
            </Typography>
          }
          small={true}
          colors={[theme.palette.grey['200'], theme.palette.secondary.main]}
        />
      )
    })
  }, [
    performancePrefix,
    data.historicalData,
    data.unit,
    dateRange,
    i18n.language,
    t,
    theme.palette.grey,
    theme.palette.secondary.main
  ])

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      mt={3}
      mb={1}
      gap={4}
    >
      <PieChart
        data={mainData}
        legend={`${data.actualAlternativeFuelRates || '-'} / ${
          data.plannedAlternativeFuelRates || '-'
        }${data.unit}`}
        subLegend={
          <>
            <Typography variant="caption" mb={1}>
              {t(`${performancePrefix}.actualVsPlanned.label.actualPlannedDate`, {
                date: getComparisonDate(dateRange, i18n.language)
              })}
            </Typography>
            <DeltaLabel value={data.delta} />
          </>
        }
        colors={[theme.palette.grey['200'], theme.palette.background.default]}
      />

      <Box display="flex" flexDirection="column" gap={1.5}>
        {historicalData}
      </Box>
    </Box>
  )
}
