import React from 'react'

import {KpisList} from '../../types'

import {DeltaCellNegative, DeltaCellPositive} from './Delta'

type Props = {
  actual: number | string
  diff: number
}

export const PositiveActualDelta: React.FC<Props> = ({actual, diff}) => {
  if (diff > 0) {
    return <DeltaCellPositive>{actual}</DeltaCellPositive>
  } else if (diff < 0) {
    return <DeltaCellNegative>{actual}</DeltaCellNegative>
  }

  return <>{actual}</>
}

export const NegativeActualDelta: React.FC<Props> = ({actual, diff}) => {
  if (diff > 0) {
    return <DeltaCellNegative>{actual}</DeltaCellNegative>
  } else if (diff < 0) {
    return <DeltaCellPositive>{actual}</DeltaCellPositive>
  }

  return <>{actual}</>
}

export const ActualKpiTemplates = {
  [KpisList.ClinkerProduction]: PositiveActualDelta,
  [KpisList.CementProduction]: PositiveActualDelta,
  [KpisList.KilnReliabilityCoefficient]: PositiveActualDelta,
  [KpisList.KilnOperatingCoefficient]: PositiveActualDelta,
  [KpisList.KilnHeatConsumption]: NegativeActualDelta,
  [KpisList.AlternativeFuelRate]: PositiveActualDelta,
  [KpisList.MTBF]: PositiveActualDelta,
  [KpisList.TechnicalCementPowerCons]: NegativeActualDelta
}
