import {mergeSxs} from '@hconnect/uikit/src/lib2'
import {Box, SxProps, Theme} from '@mui/material'
import React, {FC} from 'react'

type PlaceholderWrapperProps = {
  noBackground?: boolean
  children?: React.ReactNode
  wrapperSx?: SxProps<Theme>
}

export const PlaceholderWrapper: FC<PlaceholderWrapperProps> = ({
  children,
  noBackground,
  wrapperSx
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1
    }}
  >
    <Box
      sx={mergeSxs(
        (theme) => ({
          background: noBackground ? 'unset' : theme.palette.common.white,
          color: theme.palette.common.black,
          borderRadius: '10px',
          height: '200px',
          width: '400px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }),
        wrapperSx
      )}
    >
      {children}
    </Box>
  </Box>
)
