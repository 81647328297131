import {NavItem} from '@hconnect/uikit/src/lib2'
import {QuickSelectionId} from '@hconnect/uikit/src/lib2/components/momentDatePicker/dateRangePickerHelpers'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router'
import {generatePath, matchPath, useParams} from 'react-router-dom'
import {useSessionStorage} from 'react-use'

import {checkFeatureFlag} from '../../shared/helpers/featureFlagHelpers'
import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'
import {KEY_TIME_RANGE} from '../hooks/useTimeRangeParam'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'

import {
  DOWNTIME,
  PERFORMANCE,
  GLOBAL_KILN_STATUS,
  PLANT_PERFORMANCE,
  PLANT_DOWNTIME,
  PLANT_KPI_STATUS
} from './routing'

type PathParameter = {
  plantId?: string
}
export type DowntimeVisited = 'true' | 'false'

const yesterdayFilter: QuickSelectionId = 'yesterday'
const timeQueryParam = `${KEY_TIME_RANGE}=${yesterdayFilter}`
export const usePerformanceUrl = (downtimeVisited: DowntimeVisited) => {
  const {plantId} = useParams<PathParameter>()
  const {allowMultipleLocations, getRedirectURL} = useNavAccessContext()

  const singleLocationPerformance = getRedirectURL(timeQueryParam)

  if (!allowMultipleLocations() && singleLocationPerformance) {
    return generatePath(singleLocationPerformance)
  }
  if (plantId) {
    if (downtimeVisited === 'true') {
      return `${generatePath(PLANT_PERFORMANCE, {plantId})}?${timeQueryParam}`
    }
    return generatePath(PLANT_PERFORMANCE, {plantId})
  }
  return generatePath(PERFORMANCE)
}
export const useKilnMillStatusUrl = (downtimeVisited: DowntimeVisited) => {
  const {plantId} = useParams<PathParameter>()
  const {allowMultipleLocations, getRedirectStatusURL} = useNavAccessContext()

  const singleLocationStatus = getRedirectStatusURL()

  if (!allowMultipleLocations() && singleLocationStatus) {
    return generatePath(singleLocationStatus)
  }
  if (plantId) {
    if (downtimeVisited === 'true') {
      return `${generatePath(PLANT_KPI_STATUS, {plantId})}?${timeQueryParam}`
    }
    return generatePath(PLANT_KPI_STATUS, {plantId})
  }
  return generatePath(GLOBAL_KILN_STATUS)
}

const checkIsPlantStatusPage = (plantId: string | undefined, pathname: string) =>
  !!plantId &&
  (!!matchPath(generatePath(PLANT_PERFORMANCE, {plantId}) + '/*', pathname) ||
    !!matchPath(generatePath(PLANT_KPI_STATUS, {plantId}) + '/*', pathname))

const VISITED_DOWNTIME_KEY = 'visitedNavDowntime'
export const useVisitedDowntime = (): DowntimeVisited => {
  const {plantId} = useParams<PathParameter>()
  const location = useLocation()
  const isPlantDowntime: boolean =
    !!plantId && !!matchPath(generatePath(PLANT_DOWNTIME, {plantId}) + '/*', location.pathname)
  const isPlantStatusPage: boolean = checkIsPlantStatusPage(plantId, location.pathname)
  const [visitedDowntime, setVisitedDowntime] = useSessionStorage<DowntimeVisited>(
    VISITED_DOWNTIME_KEY,
    String(isPlantDowntime) as DowntimeVisited
  )

  useEffect(() => {
    if (isPlantDowntime) {
      setVisitedDowntime('true')
    }
    if (isPlantStatusPage) {
      setVisitedDowntime('false')
    }
  }, [setVisitedDowntime, isPlantDowntime, isPlantStatusPage])

  return visitedDowntime
}

export const useGetPerformanceDashboardLinks = (): NavItem[] => {
  const {plantId} = useParams<PathParameter>()
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const translationFn = (key: string) => t(`${performancePrefix}.${key}`)

  const showGlobalKilnMillStatus = checkFeatureFlag('enableKilnMillGlobalStatus')

  const visitedDowntime = useVisitedDowntime()
  const performanceUrl = usePerformanceUrl(visitedDowntime)
  const kilnMillStatusUrl = useKilnMillStatusUrl(visitedDowntime)

  if (!showGlobalKilnMillStatus) {
    return [
      {
        url: performanceUrl,
        label: translationFn('navPages.performance')
      },
      {
        url: kilnMillStatusUrl,
        label: translationFn('navPages.status')
      },
      {
        url: plantId ? generatePath(PLANT_DOWNTIME, {plantId}) : DOWNTIME,
        label: translationFn('navPages.downtime')
      }
    ]
  }

  return [
    {
      url: kilnMillStatusUrl,
      label: translationFn('navPages.status')
    },
    {
      url: performanceUrl,
      label: translationFn('navPages.performance')
    },
    {
      url: plantId ? generatePath(PLANT_DOWNTIME, {plantId}) : DOWNTIME,
      label: translationFn('navPages.downtime')
    }
  ]
}
