import {useCallback, useEffect, useMemo} from 'react'
import {SetURLSearchParams, useSearchParams} from 'react-router-dom'
import {useLocalStorage} from 'react-use'

type Options = {
  keepInLocalStorage?: boolean
  enabled?: boolean
}

export const useQueryParamValue = <T extends string>(
  key: string,
  options: Options = {}
): [T | undefined, (value: T | undefined) => void] => {
  const [storageTimeRange, setStorageTimeRange, removeStorageTimeRange] = useLocalStorage<T>(key)
  const [params, setParams]: [URLSearchParams, SetURLSearchParams] = useSearchParams()
  const paramValue = params.get(key) as T | null
  const {keepInLocalStorage, enabled = true} = options

  useEffect(() => {
    if (!paramValue && storageTimeRange && enabled) {
      const newParams = new URLSearchParams(params.toString())
      newParams.set(key, storageTimeRange)
      setParams(newParams, {replace: true})
    }
    if (keepInLocalStorage && paramValue && paramValue !== storageTimeRange && enabled) {
      setStorageTimeRange(paramValue)
    }
    if (!enabled && paramValue) {
      const newParams = new URLSearchParams(params.toString())
      newParams.delete(key)
      setParams(newParams, {replace: true})
    }
  }, [
    storageTimeRange,
    setParams,
    setStorageTimeRange,
    params,
    key,
    paramValue,
    enabled,
    keepInLocalStorage
  ])

  const setQueryParam = useCallback(
    (value: T | undefined) => {
      const newParams = new URLSearchParams(params.toString())
      if (value) {
        newParams.set(key, value)
      } else {
        newParams.delete(key)
      }
      if (keepInLocalStorage) {
        if (value) {
          setStorageTimeRange(value)
        } else {
          removeStorageTimeRange()
        }
      }
      setParams(newParams, {replace: true})
    },
    [keepInLocalStorage, key, params, removeStorageTimeRange, setParams, setStorageTimeRange]
  )

  const value = useMemo<T | undefined>(
    () => paramValue || storageTimeRange,
    [paramValue, storageTimeRange]
  )

  return [value, setQueryParam]
}
