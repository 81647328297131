import {EquipmentData, StoppageKind} from '@hconnect/common/types'
import {DateRange, Iso8601} from '@hconnect/uikit'

import {IssueGroupingKey, TimeStep} from './stoppage.types'

type FailureType = {
  plantId: string
  year: number
  failureTypePlannedNumber: number
  failureTypeCircumstanceNumber: number
  failureTypeIncidentNumber: number
}

export type FailureTypesResponse = {
  success: boolean
  errorMessage: string
  failureTypes: FailureType[]
}

export type StoppageCodeStatistic = {
  mainEquipments: EquipmentData[]
  equipments: EquipmentData[]
  hoursStopped: number
  incidentStoppagesCount: number
  incidentHoursStopped: number
  circumstanceStoppagesCount: number
  circumstanceHoursStopped: number
  stoppageCodes: string[]
  stoppageReasons: string[]
}

export type AggregatedStoppageStatisticsResponse = {
  timeStep: TimeStep
  timeStepStart: Iso8601
  stoppagesCount: number
  hoursStopped: number
}[]

export type AggregatedStoppageCodeStatistics = Record<string, StoppageCodeStatistic>

export type AggregatedStoppagesPayload = DateRange<Date> & {
  equipmentNumbers?: string[]
  kinds?: StoppageKind[]
  stoppageCodes?: string[]
  mainEquipmentNumbers?: string[]
  groupBy?: IssueGroupingKey
  timeStep?: TimeStep
}

export type AggregatedStoppage = {
  key: string
  stoppagesCount: number
  hoursStopped: number
}

export type EquipmentProductionTime = {
  description?: string
  equipmentMatchingId?: string
  totalProductionTime: number
  totalDisturbancesTime: number
  totalNumberOfDisturbances: number
  stoppagesTime: number
  numberOfStoppages: number
  unit?: string
}
