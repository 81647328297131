import {CardBox, Column} from '@hconnect/uikit/src/lib2'
import {Grid, Stack, Typography, styled} from '@mui/material'

import {PerformanceDescendantWithId} from '../types'

import {CountryItemHeader} from './CountryItemHeader'

export const StyledKpiTitle = styled(Typography)({
  marginBottom: 2
})

export const StyledKpiColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

type AcVsPlMobileGridProps<T> = {
  columns: Column<T>[]
  item: PerformanceDescendantWithId<T>
  onClick: () => void
  'data-test-id'?: string
}

export const AcVsPlMobileGrid = <T,>({
  columns,
  item,
  onClick,
  'data-test-id': dataTestId
}: AcVsPlMobileGridProps<T>) => {
  return (
    <Stack gap={2}>
      <CardBox px={1.5} onClick={onClick} data-test-id={dataTestId}>
        <Typography variant="h3" display="flex" alignItems="center" mb={2} gap={1}>
          {item.locationData && <CountryItemHeader countryData={item.locationData} />}
        </Typography>
        <Grid container spacing={2}>
          {columns
            .filter((c) => c.key !== 'plant')
            .map((column) => {
              return (
                <Grid key={column.key} item xs={4}>
                  <StyledKpiColumn key={column.key}>
                    <StyledKpiTitle variant="caption">{column.label}</StyledKpiTitle>
                    <Typography key={`${column.key}-value`}>
                      {column?.customTemplate?.(item) || column.key}
                    </Typography>
                  </StyledKpiColumn>
                </Grid>
              )
            })}
        </Grid>
      </CardBox>
    </Stack>
  )
}
