import {Flag} from '@hconnect/uikit/src/lib2'
import {Typography} from '@mui/material'
import React, {FC, useMemo} from 'react'
import {generatePath, useNavigate} from 'react-router-dom'

import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'
import {LOCATION_KPI_STATUS} from '../routing'
import {Location} from '../types'

type CountryItemHeaderProps = {
  countryData: Pick<Location, 'id' | 'countryCode' | 'name'>
}

export const CountryItemHeader: FC<CountryItemHeaderProps> = ({countryData}) => {
  const navigate = useNavigate()
  const {id, countryCode, name} = countryData
  const {isLocationAllowed} = useNavAccessContext()

  const locationIdAllowed = useMemo(() => isLocationAllowed(id), [id, isLocationAllowed])

  return (
    <Typography
      variant="h4"
      sx={[{mb: 2}, {cursor: locationIdAllowed ? 'pointer' : 'default'}]}
      onClick={
        isLocationAllowed(id)
          ? () => {
              const url = generatePath(LOCATION_KPI_STATUS, {locationId: id})
              navigate(url)
            }
          : undefined
      }
    >
      {countryCode && (
        <Flag
          countryCode={countryCode}
          alt={countryCode}
          sx={{
            verticalAlign: 'text-bottom',
            mr: 1,
            backgroundColor: 'common.white'
          }}
        />
      )}
      {name}
    </Typography>
  )
}

export const LocationTitle: FC<{title: string}> = ({title}) => {
  return <Typography variant="h2">{title}</Typography>
}
