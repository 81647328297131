import {MenuItem, TextField} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {TOP_ISSUES_GROUP_BY} from '../../consts'
import {useQueryParamValue} from '../../hooks/useQueryParamValue'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {IssueGroupingKey} from '../../types'

type GroupByDropDownProps = {
  value: IssueGroupingKey
  onChange: (value: IssueGroupingKey) => void
  'data-test-id': string
}

export const GroupByDropDown: React.FC<GroupByDropDownProps> = ({
  value,
  onChange,
  'data-test-id': dataTestId
}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const [, setGroupingKey] = useQueryParamValue<IssueGroupingKey>(TOP_ISSUES_GROUP_BY, {
    keepInLocalStorage: true
  })

  return (
    <TextField
      variant="outlined"
      fullWidth
      id="group-by-drop-down"
      name="groupBy"
      select
      label={t(`${performancePrefix}.downtime.actions.groupBy`)}
      value={value}
      onChange={(e) => {
        setGroupingKey(e.target.value as IssueGroupingKey)
        onChange(e.target.value as IssueGroupingKey)
      }}
      data-test-id={`${dataTestId}-select`}
    >
      {Object.values(IssueGroupingKey).map((key) => (
        <MenuItem key={key} value={key} data-test-id={`${dataTestId}-${key}`}>
          {t(`${performancePrefix}.downtime.actions.${key}`)}
        </MenuItem>
      ))}
    </TextField>
  )
}
