import {TranslationActionKey, TranslationKey, Translations} from '@hconnect/common/notifications'

export const NOTIFICATIONS_TRANSLATION_KEYS: Translations = {
  label: {
    [TranslationKey.TODAY]: 'notifications.label.today',
    [TranslationKey.YESTERDAY]: 'notifications.label.yesterday',
    [TranslationKey.NOTIFICATIONS]: 'notifications.label.notifications',
    [TranslationKey.EMPTY]: 'notifications.label.empty',
    [TranslationKey.ERROR]: 'notifications.label.notificationsNotFetched',
    [TranslationKey.SHOULD_DELETE]: 'notifications.label.shouldDeleteNotification'
  },
  action: {
    [TranslationActionKey.DELETE]: 'notifications.action.delete',
    [TranslationActionKey.CONFIRM_DELETE]: 'notifications.action.confirmDelete',
    [TranslationActionKey.CANCEL]: 'notifications.action.cancel'
  }
}

export const HROC_PREFIX = 'hroc'
export const TASKS_PREFIX = 'tasks'
